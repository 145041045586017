import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../context/auth.context";
import {Overlay, Popover, Tooltip} from "react-bootstrap";
import {useMessage} from "../../hooks/message.hook";
import {useHttp} from "../../hooks/http.hook";


const NudesPhotoReport = ({
    photoDataList,
    setPhotoDataList,
    index
}) => {

    const auth = useContext(AuthContext)

    const [targetPhoto, setTargetPhoto] = useState(photoDataList[index]);

    useEffect(() => {
        setTargetPhoto(photoDataList[index])
    },[index])

    useEffect(() => {
        window.initToolTip()
    },[])

    return (
        <div>
            <div data-toggle={"tooltip"}
                 title={"In Development. For more info contact us kikflirt.verify@gmail.com"}
                 className={'mr-2'}
            >
                <div
                    id="report-photo"
                    className="rounded-3 custom-disabled"
                >
                    <div style={{display: "flow-root"}}>
                        <i className="fas fa-user-slash"/>
                        <span> 0</span>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default NudesPhotoReport