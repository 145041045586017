import React from 'react'
import {redirectToPage} from "../scripts/Utils";

export const FooterWave = () => {

    const redirectOnPostProfile = () => {
        redirectToPage('/post-profile')
    }

    const redirectOnContactUs = (event) => {
        redirectToPage('/contact_us', true)
    }


    const redirectOnContactUsRemoveUserNames = (event) => {
        redirectToPage("/contact_us/?subject=remove_username", true)
    }

    const redirectOnAboutUs = (event) => {
        redirectToPage("/documents/about_us", true)
    }

    const redirectOnFAQ = (event) => {
        redirectToPage("/documents/faq", true)
    }


    const redirectOnPrivacyPolicy = () => {
        redirectToPage("/documents/privacy_policy", true)
    }

    const redirectOnTermsOfUse = () => {
        redirectToPage("/documents/terms_of_use", true)
    }

    const redirectOnBlogList = () => {
        redirectToPage("/blog", true)
    }

    const redirectOnLeakedNudes = () => {
        redirectToPage("/leaked-nudes", true)
    }


    return (
        <div>
            <div style={{position: "relative"}}>

                <div>
                    <svg id={"waive"} className="waves" xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink={"http://www.w3.org/1999/xlink"}
                         viewBox="0 24 100 35" preserveAspectRatio="none" shapeRendering="auto">
                        <defs>
                            <path id="gentle-wave"
                                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
                        </defs>
                        <g className="parallax">
                            <use display="none" xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)"/>
                            <use display="none" xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"/>
                            <use display="none" xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"/>
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff"/>
                        </g>
                    </svg>
                </div>
            </div>

            <footer id="footerpad">

                <div className="container">
                    <div className="row-footer-styled mb-1">
                        <div className="li-ic header">
                            <i className="fas fa-file fa-lg white-text fa-2x"> </i>
                            <span><b>Terms</b></span>
                            <hr/>
                        </div>

                        <div className="ins-ic header">
                            <i className="fas fa-link fa-lg white-text fa-2x"> </i>
                            <span><b>Useful Links</b></span>
                            <hr/>
                        </div>

                        <div className="pin-ic header">
                            <i className="fas fa-info-circle fa-lg white-text fa-2x"> </i>
                            <span><b>Help</b></span>
                            <hr/>
                        </div>
                    </div>

                    <div className="row-footer-styled">
                        <a className="li-ic" onClick={redirectOnPrivacyPolicy}>
                            <span>Privacy Policy</span>
                        </a>

                        <a className="ins-ic" onClick={redirectOnLeakedNudes}>
                            <span>Leaked nudes</span>
                        </a>

                        <a className="pin-ic" onClick={(e) => redirectOnContactUs(e)}>
                            <span>Contact Us</span>
                        </a>
                    </div>

                    <div className="row-footer-styled">
                        <a className="li-ic" onClick={redirectOnTermsOfUse}>
                            <span>Terms of Use</span>
                        </a>

                        <a className="ins-ic" onClick={(e) => redirectOnContactUsRemoveUserNames(e)}>
                            <span>Remove Username</span>
                        </a>

                        <a className="pin-ic" onClick={redirectOnAboutUs}>
                            <span>About Us</span>
                        </a>
                    </div>

                    <div className="row-footer-styled">
                        <a className="li-ic">

                        </a>

                        <a className="ins-ic" onClick={redirectOnPostProfile}>
                            <span>Post Profile</span>
                        </a>

                        <a className="pin-ic" onClick={redirectOnFAQ}>
                            <span>FAQ</span>
                        </a>
                    </div>

                    <div className="row-footer-styled">
                        <a className="li-ic">

                        </a>

                        <a className="ins-ic" onClick={redirectOnBlogList}>
                            <span>Blog</span>
                        </a>

                        <a className="pin-ic" onClick={redirectOnFAQ}>

                        </a>
                    </div>

                </div>

                <div className="footer-copyright text-center pt-3">
                    <p className="text-muted text-center">KikFlirt is not affiliated with, or sponsored or endorsed by, Kik Interactive, Inc or Snap Inc.</p>
                    <p className="copyright text-muted text-center">© 2024 KikFlirt. All rights reserved.</p>
                </div>
            </footer>
        </div>
    )
}