import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {Loader} from "../../components/Loader";
import {appendHtmlContent} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";


export const DocumentsPage = (props) => {

    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()

    const [document, setDocument] = useState(null)
    const [dateFormat, setFormatDate] = useState(null)

    const getDocument = useCallback(async () => {
        if (props.match.path === "/documents/:documentAlias") {
            try {
                const document = await request(
                    `/api/documents/${props.match.params.documentAlias}`,
                    'GET',
                    null
                )
                setDocument(document.document)
                let options = {year: 'numeric', month: 'long', day: 'numeric' };
                let date  = new Date(document.document.publicationDate);
                setFormatDate(date.toLocaleDateString("en-US", options))
                window.scrollTo(0, 0)
            } catch (e) {
            }
        }
    }, [request])

    useEffect(() => {
        getDocument()
    }, [])

    useEffect(() =>{
        if(document) {
            appendHtmlContent("#docName", document.documentName)
            appendHtmlContent("#docContent", document.documentContent)
        }
    },[document])

    useEffect(() => {
        message(error,'error',"/")
        clearError();
    },[error,message,clearError])

    return (
        <section className="py-5" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <div className={"card"} style={{padding: "20px", display: "flex", height: "100%", color: "black",
                    backgroundColor: "white"}}>
                    {document ?
                        <div style={{width: '100%'}}>
                            <div id={"docName"} className=""/>
                            <div className="mb-4">
                                Effective as of {dateFormat}
                            </div>
                            <div id={"docContent"} className=""/>
                        </div>
                        : <Loader/>}
                </div>
            </div>
        </section>
    )
}