import React from 'react'

export const AlertCardComponent = ({textAlert, loading = false, textColor = "#9b9b9b"}) => {
    return (
        <section className="py-5" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <div className={'card'}
                     style={{display: "flex", height: "100px", justifyContent: "center", alignItems: "center"}}>
                    <p style={{margin: "unset", width: '100%', textAlign: 'center', color: textColor}}>{textAlert}</p>
                    {loading &&
                    (<div className="lds-roller mb-2">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>)}
                </div>
            </div>
        </section>
    )
}