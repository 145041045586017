import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

const ColumnSizer = ({ handleSetGridOneColumn, handleSetGridTwoColumns, showColumnSizer }) => {

    return (
        <div id={"main-grid-mobile-navigation"} className={"my-grid-two-column-navigation"} style={showColumnSizer ? {} : {display: "none"}}>
            <div className="btn-tooltip" role="group">
                <button id="one_column" type="button" className="btn btn-false my-grid-two-column-btn-one-col"
                        onClick={handleSetGridOneColumn}
                >
                    <img src={"/assets/img/svg/one_column.svg"} alt={"one_column"}
                         className={"my-grid-one-column-btn-icon"}/>
                </button>
                <button id="two_column" type="button" className="btn btn-active my-grid-two-column-btn-two-col"
                        onClick={handleSetGridTwoColumns}
                >
                    <i className="fas fa-grip-vertical"/>
                </button>
            </div>
        </div>
    );
};

export default ColumnSizer;