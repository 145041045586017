import React from 'react';
import {ImgPreloader} from "./ImgPreloader";

export const LeakedNudesPagePhotoContent = (
    {
        setCurrentTargetNudePhoto,
        gridContentMobileTwoColumn,
        setOpenPhotoLightbox,
        photo,
    }) => {

    return (
        <div>
            <div
                className={!gridContentMobileTwoColumn ? "imgBx" : "imgBx-mobile-two-column"}
                style={{cursor: "pointer"}}
                onClick={() => {
                    setCurrentTargetNudePhoto(photo);
                    setOpenPhotoLightbox(true)
                }}>
                <ImgPreloader
                    src={photo.imgLink}
                    alt={photo.imgAlt}
                />
            </div>
        </div>
    )
}