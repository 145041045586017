import $ from "jquery"

function timeSince(stringDate) {

    let date = new Date(stringDate);
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

function capitalizeFirstLetter(str) {

    // converting first letter to uppercase
    return str.charAt(0).toUpperCase() + str.slice(1);
}


function redirectToPage(pagePath,onNewTab= false) {
    if(onNewTab){
        window.open(pagePath);
        return
    }
    window.location.href = pagePath;
}

function appendHtmlContent(containerElement,content){
    $(containerElement).append(content);
}

export {timeSince,capitalizeFirstLetter,redirectToPage, appendHtmlContent}