import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/auth.context";
import {Button, Modal} from "react-bootstrap";
import FormData from "form-data";
import FileUploadComponent from "../../components/FileUploadComponent";
import useScrollIntoViewById from "../../hooks/scrollIntoView";
import {redirectToPage} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";
import {AlertCardComponent} from "../../components/AlertCardComponent";
import ReactSelect, {createFilter} from "react-select";
import ReactSelectCustomOptions from "../../components/ReactSelectCustomOptions";
import CustomMenuList from "../../components/CustomMenuList";

export const AccountProfileEdit = (props) => {

    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const auth = useContext(AuthContext)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let fullFormDataProfile = {};
    const [haveClientPremiumProfilesData, setHaveClientPremiumProfilesData] = useState(null)
    const [profileNotFound, setProfileNotFound] = useState(false)
    const [countryData, setCountryData] = useState({
        country_short_name: 'us',
        country_full_name: 'United States',
    })
    const [reactSelectLoading, setReactSelectLoading] = useState(false)
    const [city, setCity] = useState({value: null, label: "Select...", latitude: null, longitude: null})
    const [isDefaultProfileCity, setIsDefaultProfileCity] = useState(true)
    const [cityList, setCityList] = useState([])
    const [profileInfo, setProfileInfo] = useState({
        about: "",
        age: "",
        appName: "",
        commentNumber: "",
        disLikes: "",
        gender: "",
        hotPoints: "",
        likes: "",
        nickName: "",
        orientation: "",
        owner: "",
        reports: "",
        type: "",
        views: "",
        photos: [],
        countryFullName: "",
        countryShortName: "",
        id: "",
        city: ""
    })


    const handleClose = () => setShowDeleteModal(false);
    const handleShow = (event) => {
        if (event) {
            event.preventDefault()
        }
        setShowDeleteModal(true);
    }

    const getScEditorValue = () => {
        return window.getScEditor().instance(window.document.getElementById('about_me_info')).val();
    }

    useEffect(() => {
        window.initPostProfileScript()
        haveClientPremiumProfiles()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        switch (error) {
            case "Profile not found":
                setProfileNotFound(true)
                message(error, 'error', "/my-profile-list")
                break;
            default:
                message(error, 'error')
                break;
        }
        clearError();
    }, [error, message, clearError])

    useEffect(() => {
        let content = window.document.getElementById('html')

        if (showDeleteModal) {
            content.style.overflow = "hidden"
        } else {
            content.style.overflow = "unset"
        }

    }, [showDeleteModal])

    const redirectOnHome = event => {
        if (typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage('/')
    }

    const [formDataProfile, setFormDataProfile] = useState({
        profileId: '',
        app: 'kik',
        profile_name: '',
        age: '18',
        sex: 'girl',
        orientation: '',
        profile_type: '',
    })

    const [formDataStatistics, setFormDataStatistics] = useState({
        hot_points: "",
        views: "",
        likes: "",
        dis_likes: "",
        comments: "",
        reports: ""
    })

    const getProfileInfo = useCallback(async () => {
        if (props.match.path === "/edit_profile/:profileId") {
            try {
                const profileData = await request(
                    `/api/profile/get_profile/${props.match.params.profileId}`,
                    'GET'
                );

                setProfileInfo({
                    ...profileInfo,
                    premium: profileData.profile.premium,
                    about: profileData.profile.about,
                    age: profileData.profile.age,
                    appName: profileData.profile.appName,
                    commentNumber: profileData.profile.commentNumber,
                    disLikes: profileData.profile.disLikes,
                    gender: profileData.profile.gender,
                    hotPoints: profileData.profile.hotPoints,
                    likes: profileData.profile.likes,
                    nickName: profileData.profile.nickName,
                    orientation: profileData.profile.orientation,
                    owner: profileData.profile.owner,
                    reports: profileData.profile.reports,
                    type: profileData.profile.type,
                    views: profileData.profile.views,
                    photos: profileData.profile.photos,
                    countryFullName: profileData.profile.countryFullName,
                    countryShortName: profileData.profile.countryShortName,
                    id: profileData.profile._id,
                    city: profileData.profile.city,
                    latitude: profileData.latitude,
                    longitude: profileData.longitude
                })
            } catch (e) {
            }
        }
    }, [request])

    const [filesData, setFilesData] = useState([])

    const changeHandlerForFormDataProfile = event => {
        setFormDataProfile({...formDataProfile, [event.target.name]: event.target.value})
    }

    const changeHandlerForFormDataStatistics = event => {
        setFormDataStatistics({...formDataStatistics, [event.target.name]: event.target.value})
    }

    const changeHandlerForCountrySelector = useCallback(() => {
        let short = window.getSelectedCountry().iso2
        let full = window.getSelectedCountry().name;
        if (short !== countryData.country_short_name && full !== countryData.country_full_name) {
            setIsDefaultProfileCity(false)
            setCountryData({country_short_name: short, country_full_name: full})
        }
    })

    const handleChangeCity = (city) => {
        setCity(city);
    }

    useEffect(() => {

        const fetchData = async () => {
            setReactSelectLoading(true)
            let cityList = await request('/api/cities/city_list/?country_name_short=' + countryData.country_short_name.toUpperCase(), 'GET')
            if (cityList && cityList.cityList) {
                let cityListMappedName = cityList.cityList.map(el => {
                    return {value: el.name, label: el.name, latitude: el.latitude, longitude: el.longitude}
                })
                setCityList(cityListMappedName)
                if(isDefaultProfileCity){
                    let label = profileInfo.city ? profileInfo.city : "Select..."
                    setCity({value: profileInfo.city, label: label, latitude:  profileInfo.latitude, longitude: profileInfo.longitude})
                }else{
                    setCity({value: null, label: "Select...", latitude: null, longitude: null})
                }
            } else {
                setCityList([])
                setCity({value: null, label: "Select...", latitude: null, longitude: null})
            }
            setReactSelectLoading(false)
        }

        // call the function
        fetchData()

    }, [countryData])

    const changeHandlerForOrientation = useCallback(() => {
        let orientation = document.getElementById('orientation').value
        setFormDataProfile({...formDataProfile, orientation: orientation})
    })

    const changeHandlerForProfileType = useCallback(() => {
        let profileType = document.getElementById('profile-type').value
        setFormDataProfile({...formDataProfile, profile_type: profileType})
    })

    const buildFullFormDataProfile = () => {
        fullFormDataProfile = {...formDataProfile, ...countryData, ...{city: city.value}, ...{latitude: city.latitude}, ...{longitude: city.longitude}}
    }

    const uploadPhoto = async (event) => {
        if (event) {
            event.preventDefault()
        }

        let filesOnly = filesData.map(el => {
            return el.file
        });
        let filesDataWithFileKey = {files: filesOnly}

        try {
            const formDataForValidationFile = new FormData();

            const fileArray = [...filesDataWithFileKey.files];

            for (let fileIndex = 0; fileIndex < fileArray.length; fileIndex++) {
                formDataForValidationFile.append(
                    "files",
                    filesDataWithFileKey.files[fileIndex]
                );
            }

            const validationImg = await request(
                '/api/file/images_validation',
                'POST',
                formDataForValidationFile,
                {Authorization: `Bearer ${auth.token}`},
                true
            )

            if (!validationImg) {
                throw new Error("Ошибка валидации фотографии профиля.");
            }

            message(validationImg.message, "success")

            //upload img
            const imgNames = [];
            for (let fileIndex = 0; fileIndex < fileArray.length; fileIndex++) {
                const response = await fileUploadHandler(filesDataWithFileKey.files[fileIndex])
                    .then(response => {
                        return response;
                    })
                imgNames.push(response.imgName);
            }

            //update profile photo
            const uploadPhoto = await request(
                '/api/profile/upload_profile_photos',
                'POST',
                {imgNames: imgNames, owner: profileInfo.id},
                {Authorization: `Bearer ${auth.token}`}
            )
            setFilesData([])
            setProfileInfo({
                ...profileInfo,
                photos: uploadPhoto.photos
            })

            message(uploadPhoto.message, "success", '/profile/' + profileInfo.id)
        } catch (e) {
            console.log(e)
        }
    }

    const fileUploadHandler = async (file) => {

        const formDataForUploadFile = new FormData();

        // Update the formData object
        formDataForUploadFile.append(
            "file",
            file
        );

        return await request(
            '/api/file/upload',
            'POST',
            formDataForUploadFile,
            {Authorization: `Bearer ${auth.token}`},
            true
        )
    }


    const updateProfileInfo = async (event) => {
        if (event) {
            event.preventDefault()
        }
        buildFullFormDataProfile()
        try {
            const updated = await request(
                '/api/profile_settings/update_profile_info',
                'POST',
                {...fullFormDataProfile},
                {Authorization: `Bearer ${auth.token}`}
            )
            message(updated.message, "success", '/profile/' + profileInfo.id)
        } catch (e) {

        }
    }

    const deleteProfile = async () => {
        try {
            const data = await request(
                '/api/profile_settings/delete_profile',
                'POST',
                {profile_id: profileInfo.id},
                {Authorization: `Bearer ${auth.token}`}
            )
            message(data.message, "success", '/my-profile-list')
        } catch (e) {

        }
    }

    const updateProfileAboutInfo = async (event) => {
        if (event) {
            event.preventDefault()
        }
        try {
            let aboutMe = getScEditorValue();
            const updated = await request(
                '/api/profile_settings/update_profile_about_info',
                'POST',
                {about_me: aboutMe, profile_id: profileInfo.id},
                {Authorization: `Bearer ${auth.token}`}
            )
            message(updated.message, "success", '/profile/' + profileInfo.id)
        } catch (e) {

        }
    }

    const updateProfileStatistics = async (event) => {
        if (event) {
            event.preventDefault()
        }
        try {

            const updated = await request(
                '/api/profile_settings/update_profile_statistics',
                'POST',
                {...formDataStatistics, ...{profile_id: profileInfo.id}},
                {Authorization: `Bearer ${auth.token}`}
            )
            message(updated.message, "success", '/profile/' + profileInfo.id)
        } catch (e) {

        }
    }

    useEffect(() => {
        if (auth.isAuthenticated) {
            getProfileInfo()
        }
    }, [getProfileInfo, auth])

    useEffect(() => {
        if (profileInfo.owner) {
            if (!(profileInfo.owner === auth.userId)) {
                redirectOnHome()
            } else {

                window.selectCountry(profileInfo.countryShortName)
                setIsDefaultProfileCity(true)
                setCountryData({
                    country_short_name: profileInfo.countryShortName,
                    country_full_name: profileInfo.countryFullName
                })

                window.selectGender(profileInfo.gender)
                window.selectOrientation(profileInfo.orientation)
                window.selectType(profileInfo.type)
                setFormDataProfile({
                    ...formDataProfile,
                    profileId: profileInfo.id,
                    app: profileInfo.appName,
                    profile_name: profileInfo.nickName,
                    age: profileInfo.age,
                    sex: profileInfo.gender,
                    orientation: profileInfo.orientation,
                    profile_type: profileInfo.type
                })
                if (profileInfo.premium) {
                    setFormDataStatistics({
                        ...formDataStatistics,
                        hot_points: profileInfo.hotPoints,
                        views: profileInfo.views,
                        likes: profileInfo.likes,
                        dis_likes: profileInfo.disLikes,
                        comments: profileInfo.commentNumber,
                        reports: profileInfo.reports
                    })
                }
            }
        }
    }, [auth, profileInfo])

    const haveClientPremiumProfiles = async () => {

        try {
            const data = await request(
                `/api/profile/haveClientPremiumProfiles/?userId=${auth.userId}`,
                'GET',
                null,
                {Authorization: `Bearer ${auth.token}`}
            )
            if (data) {
                setHaveClientPremiumProfilesData(data.haveClientPremiumProfiles)
            }
        } catch (e) {

        }

    }

    useEffect(() => {
        if (profileInfo.about !== "") {
            if (haveClientPremiumProfilesData !== null) {
                window.document.getElementById('about_me_info').value = profileInfo.about
                window.initScEditorTextArea(window.document.getElementById('about_me_info'), haveClientPremiumProfilesData)
            }
        }
    }, [haveClientPremiumProfilesData, profileInfo.about])

    useScrollIntoViewById("topBlock")

    return (
        <section className="py-0" style={{minWidth: '100%', minHeight: '100%'}}>
            {profileNotFound && (<AlertCardComponent
                textAlert={"Profile not found or deleted"}
            />)}
            {!profileNotFound && (<div className="container">
                <section className="clean-block clean-form dark h-100">
                    <div className="container">

                        <div className="block-heading" style={{paddingTop: "0px"}} id={"topBlock"}>
                            <h2 className="text-primary" style={{marginTop: "40px"}}>Edit Profile<br/></h2>
                        </div>
                        <form style={{maxWidth: "600px"}}>
                            <div className="form-group">
                                <label>Edit About Me Information</label>
                                <hr/>
                                <div className="mb-5 wow fadeIn">
                                    <div id={'aboutMe'} className={'aboutMe'}
                                         dangerouslySetInnerHTML={{__html: profileInfo.about}}/>
                                </div>
                                <textarea id="about_me_info" name="about_me_info" className="form-control"
                                          required/>
                            </div>
                            <hr style={{marginTop: "30px", marginBottom: "10px"}}/>
                            <div className="form-group">
                                <button className="save_account_changes btn btn-primary btn-block"
                                        type="submit"
                                        disabled={loading}
                                        onClick={(event) => {
                                            updateProfileAboutInfo(event)
                                        }}
                                >SAVE
                                </button>
                            </div>
                        </form>

                        {auth.userId === profileInfo.owner ?
                            <form className="bootstrap-form-with-validation mt-5" style={{maxWidth: "600px"}}>
                                <div className="form-group">
                                    <label><h5>Upload photos (PNG, JPG) | Min. size 250x250</h5></label>
                                    <FileUploadComponent filesData={filesData} setFilesData={setFilesData}/>
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn btn-primary btn-block"
                                        id="submit_btn"
                                        onClick={(event) => {
                                            uploadPhoto(event)
                                        }}
                                        disabled={loading}
                                    >
                                        {loading ?
                                            <div className="spinner-grow spinner-grow-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            :
                                            <div>
                                                Upload
                                            </div>
                                        }
                                    </button>
                                </div>
                            </form>
                            : <div/>
                        }

                        {profileInfo.premium &&
                        (<form className="mt-5"
                               style={{maxWidth: "600px"}}>
                            <div>
                                <label>Edit Statistics</label>
                                <hr/>
                            </div>
                            <div className="form-group">
                                <div className="input-group">
                                    <input type="number" id="hot-points" className="form-control" name="hot_points"
                                           defaultValue={profileInfo.hotPoints} min="0" max="999999999" required=""
                                           aria-required="true" onChange={changeHandlerForFormDataStatistics}/>
                                    <div style={{padding: "10px 15px", width: "50px"}} className="input-group-addon"
                                         data-toggle="tooltip" title="HOT points">
                                        <i className="fire-color fas fa-fire"/>
                                    </div>
                                </div>
                                <div className="input-group mt-2">
                                    <input type="number" id="views" className="form-control" name="views"
                                           defaultValue={profileInfo.views}
                                           min="0" max="999999999" required="" aria-required="true"
                                           onChange={changeHandlerForFormDataStatistics}/>
                                    <div style={{padding: "10px 15px", width: "50px"}} className="input-group-addon"
                                         data-toggle="tooltip" title="views">
                                        <i className="eye-color fas fa-eye"/>
                                    </div>
                                </div>
                                <div className="input-group mt-2">
                                    <input type="number" id="likes" className="form-control" name="likes"
                                           defaultValue={profileInfo.likes}
                                           min="0" max="999999999" required="" aria-required="true"
                                           onChange={changeHandlerForFormDataStatistics}/>
                                    <div style={{padding: "10px 15px", width: "50px"}} className="input-group-addon"
                                         data-toggle="tooltip" title="Likes">
                                        <i className="heart-beat fas fa-heart"/>
                                    </div>
                                </div>
                                <div className="input-group mt-2">
                                    <input type="number" id="dis-likes" className="form-control" name="dis_likes"
                                           defaultValue={profileInfo.disLikes} min="0" max="999999999" required=""
                                           aria-required="true" onChange={changeHandlerForFormDataStatistics}/>
                                    <div style={{padding: "10px 15px", width: "50px"}} className="input-group-addon"
                                         data-toggle="tooltip" title="disLikes">
                                        <i className="fas fa-heart-broken"/>
                                    </div>
                                </div>
                                <div className="input-group mt-2">
                                    <input type="number" id="comments" className="form-control" name="comments"
                                           defaultValue={profileInfo.commentNumber} min="0" max="999999999" required=""
                                           aria-required="true" onChange={changeHandlerForFormDataStatistics}/>
                                    <div style={{padding: "10px 15px", width: "50px"}} className="input-group-addon"
                                         data-toggle="tooltip" title="Coments">
                                        <i className="fas fa-comment"/>
                                    </div>
                                </div>
                                <div className="input-group mt-2">
                                    <input type="number" id="reports" className="form-control" name="reports"
                                           defaultValue={profileInfo.reports} min="0" max="999999999" required=""
                                           aria-required="true" onChange={changeHandlerForFormDataStatistics}/>
                                    <div style={{padding: "10px 15px", width: "50px"}} className="input-group-addon"
                                         data-toggle="tooltip" title="Reports">
                                        <i className="fas fa-user-slash"/>
                                    </div>
                                </div>
                            </div>

                            <hr style={{marginTop: "30px", marginBottom: "10px"}}/>
                            <div className="form-group">
                                <button className="save_account_changes btn btn-primary btn-block"
                                        onClick={(event) => {
                                            updateProfileStatistics(event)
                                        }} disabled={loading}>UPDATE
                                </button>
                            </div>
                        </form>)}


                        <div className={"mt-5"}>
                            <section className="clean-form dark h-100">
                                <div className="row">
                                    <div className="col">
                                        <form className="bootstrap-form-with-validation"
                                              style={{
                                                  maxWidth: '600px',
                                                  paddingLeft: '22px',
                                                  paddingRight: '22px'
                                              }}>
                                            <div className="form-group"><label htmlFor="text-input">
                                                <h5>App</h5></label>
                                                <select className="form-control" defaultValue={profileInfo.appName}
                                                        id="kik-app" name="app"
                                                        onChange={changeHandlerForFormDataProfile} required>
                                                    <option defaultValue={"kik"}>
                                                        kik
                                                    </option>
                                                    <option defaultValue={"snapchat"}>
                                                        snapchat
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group"><label htmlFor="text-input">
                                                <h5>Username</h5>
                                            </label><input type="text" className="form-control"
                                                           defaultValue={profileInfo.nickName}
                                                           id="kik-username"
                                                           name="profile_name"
                                                           onChange={changeHandlerForFormDataProfile}
                                                           autoComplete="off"/></div>
                                            <div className="col-md-5 col-sm-4 col-xs-5"
                                                 style={{margin: '0', padding: '0'}}>
                                                <div className="form-group field-profileform-age required">
                                                    <label className="control-label"
                                                           htmlFor="profileform-age"/>
                                                    <label htmlFor="text-input"><h5>Age</h5></label>
                                                    <div className="input-group">
                                                        <input type="number" id="profileform-age"
                                                               className="form-control"
                                                               name="age" defaultValue={profileInfo.age} min="18"
                                                               max="99" required=""
                                                               aria-required="true"
                                                               onChange={changeHandlerForFormDataProfile}/>
                                                        <div className="input-group-addon">yo</div>
                                                    </div>
                                                    <p className="help-block help-block-error"/>
                                                </div>
                                            </div>
                                            <div className="form-group" style={{display: 'grid'}}>
                                                <label htmlFor="text-input"><h5>Country</h5></label>
                                                <input className="form-control" id="country_selector"
                                                       type="text"
                                                       onFocus={changeHandlerForCountrySelector}
                                                       onChange={changeHandlerForCountrySelector}/>
                                            </div>

                                            <div className="form-group">
                                                <div className={"dropdown"}>
                                                    <h5 className={"label"}>City</h5>
                                                    <ReactSelect
                                                        value={city}
                                                        options={cityList}
                                                        onChange={handleChangeCity}
                                                        filterOption={createFilter({ignoreAccents: false})}
                                                        captureMenuScroll={false}
                                                        classNamePrefix="custom-select"
                                                        components={{
                                                            Option: ReactSelectCustomOptions,
                                                            MenuList: CustomMenuList
                                                        }}
                                                        isLoading={reactSelectLoading}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="text-input"><h5>Gender</h5></label>
                                                <div className="form-check"><input type="radio"
                                                                                   className="form-check-input"
                                                                                   name="sex" id="guy"
                                                                                   defaultValue={"guy"}
                                                                                   onChange={changeHandlerForFormDataProfile}/><label
                                                    className="form-check-label" htmlFor="guy"><i
                                                    className="fas fa-mars"/> Guy</label>
                                                </div>
                                                <div className="form-check"><input type="radio"
                                                                                   className="form-check-input"
                                                                                   name="sex"
                                                                                   defaultChecked={true}
                                                                                   id="girl"
                                                                                   defaultValue={"girl"}
                                                                                   onChange={changeHandlerForFormDataProfile}/><label
                                                    className="form-check-label" htmlFor="girl"><i
                                                    className="fas fas fa-venus"/> Girl</label></div>
                                                <div className="form-check"><input type="radio"
                                                                                   className="form-check-input"
                                                                                   name="sex" id="trans"
                                                                                   defaultValue={"trans"}
                                                                                   onChange={changeHandlerForFormDataProfile}/><label
                                                    className="form-check-label" htmlFor="trans"><i
                                                    className="fas fas fa-transgender"/> Trans</label></div>
                                            </div>
                                            <div id="orientation-btns" className="form-group"
                                                 style={{display: 'grid'}}>
                                                <label><h5>Orientation</h5></label>
                                                <div className="btn-tooltip" role="group">
                                                    <button id="gay" type="button"
                                                            className="marg-top btn btn-false mr-1"
                                                            onClick={changeHandlerForOrientation}>GAY
                                                    </button>
                                                    <button id="lesbian" type="button"
                                                            className="marg-top btn btn-false mr-1"
                                                            onClick={changeHandlerForOrientation}>LESBIAN
                                                    </button>
                                                    <button id="straight" type="button"
                                                            className="marg-top btn btn-false mr-1"
                                                            onClick={changeHandlerForOrientation}>STRAIGHT
                                                    </button>
                                                    <button id="bi" type="button"
                                                            className="marg-top btn btn-false mr-1"
                                                            onClick={changeHandlerForOrientation}>BI
                                                    </button>
                                                    <button id="other" type="button"
                                                            className="marg-top btn btn-false mr-1"
                                                            onClick={changeHandlerForOrientation}>OTHER
                                                    </button>
                                                </div>
                                                <input id="orientation" hidden={true} name="orientation"/>
                                                <p id="orientation-validation-error"
                                                   className="help-block help-block-error"/>
                                            </div>

                                            <div id="profile-type-btns" className="form-group"
                                                 style={{display: 'grid'}}>
                                                <label><h5>Profile type</h5></label>
                                                <div className="btn-tooltip" role="group">
                                                    <button id="clean" type="button"
                                                            className="marg-top btn btn-false mr-1"
                                                            onClick={changeHandlerForProfileType}>CLEAN
                                                    </button>
                                                    <button id="dirty" type="button"
                                                            className="marg-top btn btn-false"
                                                            onClick={changeHandlerForProfileType}>DIRTY
                                                    </button>
                                                </div>
                                                <input id="profile-type" hidden={true} name="profile_type"/>
                                                <p id="orientation-validation-error"
                                                   className="help-block help-block-error"/>
                                            </div>


                                            <div className="form-group">
                                                <button className="btn btn-primary btn-block"
                                                        onClick={(event) => {
                                                            updateProfileInfo(event)
                                                        }}
                                                        disabled={loading}>Update
                                                    Profile Information
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </section>
                        </div>


                        <form className="mt-5" role="form" style={{maxWidth: "600px"}}>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="button" onClick={(event) => {
                                    handleShow(event)
                                }}><i
                                    className="fas fa-trash"/> DELETE
                                    PROFILE
                                </button>
                            </div>
                        </form>

                    </div>
                </section>
            </div>)}
            <Modal show={showDeleteModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to delete
                        your account?</Modal.Title>
                    <button type="button" className="btn-close btn-close-grey"
                            aria-label="Close" onClick={handleClose}>
                        <i className="fas fa-times"/>
                    </button>
                </Modal.Header>
                <Modal.Body><h4>Deleting your account is <b style={{color: 'red'}}>permanent</b> and will remove all
                    content including
                    photos, avatars and all profiles. Are you sure you want to delete your account?</h4></Modal.Body>
                <Modal.Footer>
                    <button id="delete_account" type="button" className="btn btn-primary"
                            onClick={deleteProfile}>Delete
                    </button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}