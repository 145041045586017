import React, {useEffect, useState} from "react";
import Cookies from 'universal-cookie';
import {Button, Modal} from "react-bootstrap";


function nextWeek(){
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);
}

const AdultModal = () => {
    const [showModal, setShowModal] = useState(false)
    const handleClose = () => {setShowModal(false)};
    const handleOpen = () => {setShowModal(true)};
    const cookies = new Cookies(null ,{ path: '/' });

    const confirm = () => {
        cookies.set('confirm_adult', 'ok', {expires:  nextWeek()});
        handleClose()
    }

    useEffect(()=>{
        let cookie = cookies.get('confirm_adult')
        if(cookie !== 'ok'){
            handleOpen()
        }
    },[])

    return (
        <Modal
            show={showModal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false} centered
            contentClassName={"modal-adult"}
        >
            <Modal.Header>
                <Modal.Title>
                    <div style={{display: "inline-flex", alignItems: "center"}}>
                        <img style={{width: "15%", marginRight: "12px"}} src={"/assets/img/age.png"} alt={"age"}/>
                        <span style={{color: "#86377b"}}>Adult Content</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This website contains adult content. Please confirm that you are 18 or older to proceed.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={confirm}>
                    I'm 18 or older
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
};
export default AdultModal