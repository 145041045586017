import React, {useContext, useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/auth.context";
import {Button, Modal} from "react-bootstrap";
import {redirectToPage} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";

export const AccountSettings = () => {
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const auth = useContext(AuthContext)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [newEmail, setNewEmail] = useState({
        email: '',
        newEmail: ''
    });
    const [changePasswordForm, setChangePasswordForm] = useState({
        email: '',
        password: '',
        password_confirmation: '',
    })

    const changeHandlerForPasswordChangeForm = event => {
        setChangePasswordForm({...changePasswordForm, [event.target.name]: event.target.value})
    }

    const changeHandlerForNewEmail = event => {
        setNewEmail({...newEmail, [event.target.name]: event.target.value})
    }

    const handleClose = () => setShowDeleteModal(false);
    const handleShow = (event) => {
        if (event) {
            event.preventDefault()
        }
        setShowDeleteModal(true)
    };

    useEffect(() => {
        message(error, 'error')
        clearError();
    }, [error, message, clearError])

    useEffect(() => {
        let content = window.document.getElementById('html')
        if (showDeleteModal) {
            content.style.overflow = "hidden"
        } else {
            content.style.overflow = "unset"
        }

        setChangePasswordForm({...changePasswordForm, email: auth.userEmail})
        setNewEmail({...newEmail, email: auth.userEmail})

    }, [showDeleteModal, auth.userEmail])

    const deleteAccount = async () => {
        try {

            const data = await request(
                '/api/account_settings/delete-account',
                'POST',
                {id: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )
            if(data) {
                auth.logout()
                message(data.message, 'success')
            }
        } catch (e) {

        }
    }

    const changePassword = async (event) => {
        if (event) {
            event.preventDefault()
        }
        try {
            const data = await request(
                '/api/account_settings/auth-change-password',
                'POST',
                {...changePasswordForm},
                {Authorization: `Bearer ${auth.token}`})
            if(data) {
                auth.logout()
                message(data.message, 'success')
            }
            //redirectOnHome()
        } catch (e) {
        }
    }

    const changeEmail = async (event) => {
        if (event) {
            event.preventDefault()
        }
        try {
            const data = await request(
                '/api/account_settings/change-email',
                'POST',
                {...newEmail},
                {Authorization: `Bearer ${auth.token}`})
            if(data) {
                auth.logout()
                message(data.message, 'success')
            }
            //redirectOnHome()
        } catch (e) {
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className="py-0" style={{minWidth: '100%', minHeight: '100%'}}>
            <div className="container">
                <section className="clean-block clean-form dark h-100">
                    <div className="container">
                        <div className="block-heading" style={{paddingTop: '0px'}}>
                            <h2 className="text-primary" style={{marginTop: '40px'}}>Account Settings<br/></h2>
                        </div>
                        <form role="form">
                            <div className="form-group">
                                <label>Change Email</label>
                                <input id="email_change"
                                       className="form-control"
                                       type="email"
                                       autoComplete="off" required
                                       placeholder={auth.userEmail}
                                       name="newEmail" onChange={changeHandlerForNewEmail}/>
                            </div>
                            <hr style={{marginTop: '30px', marginBottom: '10px'}}/>
                            <div className="form-group">
                                <button className="save_account_changes btn btn-primary btn-block" onClick={(event) => {
                                    changeEmail(event)
                                }} disabled={loading}>
                                    {loading ?
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <div>
                                            SAVE
                                        </div>
                                    }
                                </button>
                            </div>
                        </form>

                        <form className="mt-5" role="form">
                            <div className="form-group"><label>Change Password</label>
                                <input id="password_change"
                                       className="form-control"
                                       type="password"
                                       autoComplete="off" required
                                       placeholder="*********"
                                       name="password" onChange={changeHandlerForPasswordChangeForm}/>
                            </div>
                            <div className="form-group"><label>Confirm new password</label>
                                <input
                                    id="password_change_confirm" className="form-control" type="password"
                                    autoComplete="off"
                                    required placeholder="*********" name="password_confirmation"
                                    onChange={changeHandlerForPasswordChangeForm}/>
                            </div>
                            <hr style={{marginTop: '30px', marginBottom: '10px'}}/>
                            <div className="form-group">
                                <button className="save_account_changes btn btn-primary btn-block" onClick={(event) => {
                                    changePassword(event)
                                }} disabled={loading}>
                                    {loading ?
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <div>
                                            SAVE
                                        </div>
                                    }
                                </button>
                            </div>
                        </form>

                        <form className="mt-5" encType="multipart/form-data"
                              role="form">
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="button" onClick={(event) => {
                                    handleShow(event)
                                }}>
                                    {loading ?
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <div>
                                            <i className="fas fa-trash"/>
                                            &nbsp;DELETE ACCOUNT
                                        </div>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
            <Modal show={showDeleteModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to delete
                        your account?</Modal.Title>
                    <button type="button" className="btn-close btn-close-grey"
                            aria-label="Close" onClick={handleClose}>
                        <i className="fas fa-times"/>
                    </button>
                </Modal.Header>
                <Modal.Body><h4>Deleting your account is <b style={{color: 'red'}}>permanent</b> and will remove all
                    content including
                    photos, avatars and all profiles. Are you sure you want to delete your account?</h4></Modal.Body>
                <Modal.Footer>
                    <button id="delete_account" type="button" className="btn btn-primary"
                            onClick={deleteAccount}>Delete
                    </button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}