import * as React from 'react';
import {useEffect, useState} from "react";
import useScript from "../../hooks/script.hook";

export default function BannerAdTop({width}) {
    useScript('https://poweredby.jads.co/js/jads.js');

    const bannerDataList = [
        {id: "1023311", width: 728, height: 102},
        {id: "1023324", width: 468, height: 72},
        {id: "1023316", width: 300, height: 62},
    ];

    const [currentBanner, setCurrentBanner] = useState(null)


    useEffect(() => {
        if(currentBanner) {
            window.initJuicyAds(currentBanner.id);
        }
    },[currentBanner])


    useEffect(() => {
        for(let bannerData of bannerDataList){
            if(width >= bannerData.width){
                setCurrentBanner(bannerData)
                break;
            }
        }
    },[width])

    return (
       <div style={{
           display: "flex",
           justifyContent: "center",
           paddingBottom: "10px"
       }}>
           {currentBanner && <ins id={currentBanner.id} data-width={currentBanner.width} data-height={currentBanner.height}/>}
       </div>
    );
}