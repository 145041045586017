import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/auth.context";
import {Loader} from "../../components/Loader";
import {Button, Modal, Overlay, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import {ImgPreloader} from "../../components/ImgPreloader";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import 'material-icons/iconfont/material-icons.css';
import mySocket from "../../SocketUtils";
import {ProfileComments} from "../../components/ProfileComments";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import {AlertCardComponent} from "../../components/AlertCardComponent";
import sortArray from "sort-array";
import useScrollIntoViewById from "../../hooks/scrollIntoView";
import {redirectToPage} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";
import Reporter from "../../components/modal/reporter";


const UpdatingPopover = React.forwardRef(
    ({popper, children, show: _, ...props}, ref) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [children, popper]);

        return (
            <Popover ref={ref} body {...props}>
                {children}
            </Popover>
        );
    },
);

export const AccountProfile = (props) => {
    const auth = useContext(AuthContext)
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const targetHotPoint = useRef(null);
    const [profileNotFound, setProfileNotFound] = useState(false)
    const [showReportModal, setShowReportModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteProfilePhotoModal, setShowDeleteProfilePhotoModal] = useState(false);
    const [showPremiumFeature, setShowPremiumFeature] = useState(false);
    const [openPhotoLightbox, setOpenPhotoLightbox] = useState(false)
    const [photoIndex, setPhotoIndex] = useState(0)
    const [profileMainPhoto, setProfileMainPhoto] = useState({
        imgLink: "/assets/img/no-photo.svg",
        imgAlt: "no-photo"
    })
    const [currentProfilePhotoItem, setCurrentProfilePhotoItem] = useState(null)
    const [likeLimitTooltip, setLikeLimitTooltip] = useState({
        value: "+1"
    });
    const [likeBlockedStatus, setLikeBlockedStatus] = useState(false);
    const [disLikeLimitTooltip, setDisLimitTooltip] = useState({
        value: "+1"
    });
    const [disLikeBlockedStatus, setDisLikeBlockedStatus] = useState(false);
    const [hotPointLimitTooltip, setHotPointLimitTooltip] = useState({
        value: "+1"
    });
    const [overlayLikeShow, setOverlayLikeShow] = useState(false)
    const [overlayDisShow, setOverlayDisShow] = useState(false)
    const [overlayHotPointShow, setOverlayHotPointShow] = useState(false)

    useScrollIntoViewById(null)

    const redirectOnEditProfilePage = (profileId) => {
        redirectToPage('/edit_profile/' + profileId)
    }

    const redirectOnPremiumRules = event => {
        if (typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage('/documents/premium_rules')
    }

    const [copyNickName, setCopyNickName] = useState({
        value: '',
        copied: false,
    })

    const [profileInfo, setProfileInfo] = useState({
        isOnline: false,
        premium: false,
        about: "",
        acceptProfileTerms: "",
        age: "",
        appName: "",
        commentNumber: "",
        directLink: "",
        disLikes: "",
        disLikesHistory: "",
        gender: "",
        hotPoints: "",
        likes: "",
        likeHistory: "",
        nickName: "",
        orientation: "",
        owner: "",
        photos: [],
        reports: "",
        type: "",
        views: "",
        countryFullName: "",
        countryShortName: "",
        id: ""
    })

    const [reporterContent, setReporterContent] = useState({
        fromEmail: "",
        fromId: "",
        id: "",
        type: "",
        title: "",
        body: "",
        reportReasons: []
    })

    const getProfileInfo = useCallback(async () => {
        if (props.match.path === "/profile/:profileId") {
            try {
                const profileData = await request(
                    `/api/profile/get_profile/${props.match.params.profileId}`,
                    'GET'
                );
                setProfileInfo({
                    ...profileInfo,
                    isOnline: profileData.profile.isOnline,
                    premium: profileData.profile.premium,
                    about: profileData.profile.about,
                    acceptProfileTerms: profileData.profile.acceptProfileTerms,
                    age: profileData.profile.age,
                    appName: profileData.profile.appName,
                    commentNumber: profileData.profile.commentNumber,
                    directLink: profileData.profile.directLink,
                    disLikes: profileData.profile.disLikes,
                    disLikesHistory: profileData.profile.disLikesHistory,
                    gender: profileData.profile.gender,
                    hotPoints: profileData.profile.hotPoints,
                    likes: profileData.profile.likes,
                    likeHistory: profileData.profile.likeHistory,
                    nickName: profileData.profile.nickName,
                    orientation: profileData.profile.orientation,
                    owner: profileData.profile.owner,
                    photos: profileData.profile.photos,
                    reports: profileData.profile.reports,
                    type: profileData.profile.type,
                    views: profileData.profile.views,
                    countryFullName: profileData.profile.countryFullName,
                    countryShortName: profileData.profile.countryShortName,
                    id: profileData.profile._id
                })
                setCopyNickName({...copyNickName, value: profileData.profile.nickName})
            } catch (e) {
                setProfileNotFound(true)
            }
        }
    }, [request])

    useEffect(() => {
        switch (error) {
            case "Profile not found":
                setProfileNotFound(true)
                message(error, 'error', "/")
                break;
            default:
                message(error, 'error')
                break;
        }
        clearError();
    }, [error, message, clearError])

    useEffect(() => {
        window.initProfileScript()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getProfileInfo()
    }, [getProfileInfo])

    useEffect(() => {
        window.initCopyIconClickTooltip()
    }, [copyNickName.value])

    useEffect(() => {
        window.initToolkit()
    }, [profileInfo.owner])

    useEffect(() => {
        window.document.querySelector('div#aboutMe').innerHTML = profileInfo.about
        window.initProfileScript()
        if (profileInfo.premium && auth.isAuthenticated && profileInfo.owner === auth.userId) {
            setShowPremiumFeature(true)
        } else {
            setShowPremiumFeature(false)
        }

        if (profileInfo.photos.length > 0) {
            const mainPhoto = profileInfo.photos.find(el => {
                return el.mainPhoto === true
            })

            if (mainPhoto) {
                setProfileMainPhoto({
                    imgLink: mainPhoto.imgLink,
                    imgAlt: mainPhoto.imgAlt
                })
            }
        }

    }, [profileInfo])

    const handleShowReporter = (fromEmail, fromId, id, type, title, body, reportReasons) => {
        setReporterContent({fromEmail, fromId, id, type, title, body, reportReasons})
        setShowReportModal(true)
    };

    const handleDeleteClose = () => setShowDeleteModal(false);
    const handleDeleteShow = () => setShowDeleteModal(true);

    const handleDeleteProfileClose = () => setShowDeleteProfilePhotoModal(false);
    const handleDeleteProfileShow = () => setShowDeleteProfilePhotoModal(true);

    const setOverflowHidden = () => {
        window.document.getElementById('html').style.overflow = "hidden"
    }

    useEffect(() => {
        let content = window.document.getElementById('html')

        if (showDeleteModal) {
            content.style.overflow = "hidden"
        } else {
            content.style.overflow = "unset"
        }

        if (!openPhotoLightbox) {
            content.style.overflow = "unset"
        }

        if (showDeleteProfilePhotoModal) {
            content.style.overflow = "hidden"
        } else {
            content.style.overflow = "unset"
        }

    }, [showDeleteModal, openPhotoLightbox, showDeleteProfilePhotoModal])

    const changeMainPhotoHandler = async (event) => {
        const newMainPhotoId = event.target.value;

        try {
            const changedMainPhoto = await request(
                `/api/profile/change_main_photo/?` +
                `newMainPhotoId=${newMainPhotoId}&` +
                `owner=${profileInfo.id}`,
                'GET',
                null,
                {Authorization: `Bearer ${auth.token}`}
            );
            setProfileInfo({
                ...profileInfo,
                photos: changedMainPhoto.photos
            })
        } catch (ex) {

        }
    }

    const likeHandler = async () => {
        try {
            const likeEvent = await request(
                '/api/profile/like',
                'POST',
                {profileId: profileInfo.id, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )

            setOverlayLikeShow(true);
            setTimeout(() => {
                setOverlayLikeShow(false)
            }, 1000);


            if (typeof likeEvent.error !== "undefined") {
                setLikeLimitTooltip({
                        ...likeLimitTooltip, value: "Like limit left: " +
                        likeEvent.error.likeLimitLeftHours + "h " +
                        likeEvent.error.likeLimitLeftMinutes + "m " +
                        likeEvent.error.likeLimitLeftSeconds + "s"
                    }
                )
            } else {
                setProfileInfo({
                    ...profileInfo,
                    likes: likeEvent.likes
                })
                setLikeLimitTooltip({...likeLimitTooltip, value: "+1"})
            }
            await updateClientLikeClickStatus()
        } catch (e) {
            console.log(e.message)
        }
    }

    const disLikeHandler = async () => {
        try {
            const disLikeEvent = await request(
                '/api/profile/dislike',
                'POST',
                {profileId: profileInfo.id, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )

            setOverlayDisShow(true);
            setTimeout(() => {
                setOverlayDisShow(false)
            }, 1000);

            if (typeof disLikeEvent.error !== "undefined") {
                setDisLimitTooltip({
                        ...disLikeLimitTooltip, value: "DisLike limit left: " +
                        disLikeEvent.error.dislikeLimitLeftHours + "h " +
                        disLikeEvent.error.dislikeLimitLeftMinutes + "m " +
                        disLikeEvent.error.dislikeLimitLeftSeconds + "s"
                    }
                )
            } else {
                setProfileInfo({
                    ...profileInfo,
                    disLikes: disLikeEvent.disLikes
                })
                setDisLimitTooltip({...disLikeLimitTooltip, value: "+1"})
            }
            await updateClientDisLikeClickStatus();
        } catch (e) {
            console.log(e.message)
        }

    }

    const hotPointHandler = async (event, photoId) => {
        targetHotPoint.current = event.currentTarget;
        try {
            const hotPointEvent = await request(
                '/api/profile/photo/addHotPoint',
                'POST',
                {photoId: photoId, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )
            if (hotPointEvent) {
                setOverlayHotPointShow(true);
                setTimeout(() => {
                    setOverlayHotPointShow(false);
                }, 1000);
                window.addBouncyToHotPointBtn(targetHotPoint.current);
                if (typeof hotPointEvent.error !== "undefined") {
                    setHotPointLimitTooltip({
                            ...hotPointLimitTooltip, value: "Hot point limit left: " +
                            hotPointEvent.error.hotPointLimitLeftHours + "h " +
                            hotPointEvent.error.hotPointLimitLeftMinutes + "m " +
                            hotPointEvent.error.hotPointLimitLeftSeconds + "s"
                        }
                    )
                } else {
                    setProfileInfo({
                        ...profileInfo,
                        hotPoints: hotPointEvent.hotPointsProfile
                    })
                    setHotPointLimitTooltip({...hotPointLimitTooltip, value: "+1"})
                }
            }
            await updateClientHotPointClickStatus()
        } catch (e) {
            console.log(e.message)
        }

    }

    const deleteProfile = async () => {
        try {
            const data = await request(
                '/api/profile_settings/delete_profile',
                'POST',
                {profile_id: profileInfo.id},
                {Authorization: `Bearer ${auth.token}`}
            )
            message(data.message, "success", "/")
        } catch (e) {

        }
    }

    const deleteProfilePhoto = async (photoItem) => {
        setShowDeleteProfilePhotoModal(false)
        try {
            const deletePhotoOnlyText = await request(
                '/api/profile/photo/delete_profile_photo',
                'POST',
                {photo_id: photoItem._id, profile_id: profileInfo.id},
                {Authorization: `Bearer ${auth.token}`}
            )
            if (deletePhotoOnlyText) {
                const deletePhotoFile = await request(
                    '/api/file/delete/' + photoItem.imgAlt,
                    'POST',
                    {},
                    {Authorization: `Bearer ${auth.token}`}
                )
                setProfileInfo({
                    ...profileInfo,
                    photos: deletePhotoOnlyText.photos
                })
                message(deletePhotoOnlyText.message, "success")
            }
        } catch (e) {

        }
    }

    const updateClientHotPointClickStatus = async () => {
        try {
            const hotPointClickStatus = await request(
                '/api/profile/photo/updateHotPointBlockStatus',
                'POST',
                {profileId: profileInfo.id, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )
            if (hotPointClickStatus) {
                if (hotPointClickStatus.photos) {
                    setProfileInfo({
                        ...profileInfo,
                        photos: hotPointClickStatus.photos,
                        hotPoints: hotPointClickStatus.hotPointsProfile
                    })
                }
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const updateClientLikeClickStatus = async () => {
        try {
            const likeClickStatus = await request(
                '/api/profile/updateLikeBlockStatus',
                'POST',
                {profileId: profileInfo.id, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )
            if (likeClickStatus) {
                if (likeClickStatus.likeHistory) {
                    setProfileInfo({
                        ...profileInfo,
                        likeHistory: likeClickStatus.likeHistory,
                        likes: likeClickStatus.likes
                    })
                    let blockedUser = likeClickStatus.likeHistory.find(el => {
                        return el.userId === auth.userId
                    })
                    if (blockedUser) {
                        setLikeBlockedStatus(blockedUser.isBlocked)
                    }
                }
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const updateClientDisLikeClickStatus = async () => {
        try {
            const disLikeClickStatus = await request(
                '/api/profile/updateDisLikeBlockStatus',
                'POST',
                {profileId: profileInfo.id, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )
            if (disLikeClickStatus) {
                if (disLikeClickStatus.disLikesHistory) {
                    setProfileInfo({
                        ...profileInfo,
                        disLikesHistory: disLikeClickStatus.disLikesHistory,
                        disLikes: disLikeClickStatus.disLikes
                    })
                    let blockedUser = disLikeClickStatus.disLikesHistory.find(el => {
                        return el.userId === auth.userId
                    })
                    if (blockedUser) {
                        setDisLikeBlockedStatus(blockedUser.isBlocked)
                    }
                }
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const clickOnCopyIcon = () => {
        setTimeout(() => {
            window.hideCopyIconClickTooltip()
            setCopyNickName({...copyNickName, copied: false})
        }, 1000);
    }

    useEffect(() => {
        if (showPremiumFeature) {
            window.setOnlineSwitcher(profileInfo.isOnline)
        }
    }, [showPremiumFeature, profileInfo])

    const switchOnlineStatus = async () => {
        try {
            const switchOnline = await request(
                '/api/profile/switchOnlineStatus',
                'POST',
                {profileId: profileInfo.id},
                {Authorization: `Bearer ${auth.token}`}
            )

            setProfileInfo({
                ...profileInfo,
                isOnline: switchOnline.switchedOnlineStatus
            })

        } catch (e) {
            console.log(e.message)
        }
    }

    const updateOnlineStatus = async () => {
        try {
            const currentOnlineStatus = await request(
                '/api/profile/getOnlineStatus',
                'POST',
                {profileId: profileInfo.id},
                {},
                false,
                false
            )

            setProfileInfo({
                ...profileInfo,
                isOnline: currentOnlineStatus.onlineStatus
            })

        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        if (mySocket.connected) {
            mySocket.on("message", function (message) {
                if (message.online && profileInfo.id) {
                    updateOnlineStatus()
                }
            })
        }
    }, [mySocket.connected, profileInfo])

    useEffect(() => {
        if (auth.userId && profileInfo.id && (auth.userId !== profileInfo.owner)) {
            updateClientHotPointClickStatus();
            updateClientLikeClickStatus();
            updateClientDisLikeClickStatus();
        }
    }, [auth.userId, profileInfo.id])

    //profile photo sorting
    useEffect(() => {
        setProfileInfo({
            ...profileInfo,
            photos: sortArray(profileInfo.photos, {
                by: 'uploadDateOriginal',
                order: 'desc'
            })
        })
    }, [profileInfo.photos])

    return (
        <section className="py-3" style={{minHeight: '100%', minWidth: '100%'}}>
            {profileNotFound && (<AlertCardComponent
                textAlert={"Profile not found or deleted"}
            />)}
            {openPhotoLightbox && (
                <Lightbox
                    mainSrc={profileInfo.photos[photoIndex].imgLink}
                    nextSrc={profileInfo.photos[(photoIndex + 1) % profileInfo.photos.length].imgLink}
                    prevSrc={profileInfo.photos[(photoIndex + profileInfo.photos.length - 1) % profileInfo.photos.length].imgLink}
                    enableZoom={false}
                    onAfterOpen={setOverflowHidden}
                    onCloseRequest={() => {
                        setOpenPhotoLightbox(false)
                    }}
                    onMovePrevRequest={() => {
                        setPhotoIndex((photoIndex + profileInfo.photos.length - 1) % profileInfo.photos.length)
                    }
                    }
                    onMoveNextRequest={() => {
                        setPhotoIndex((photoIndex + 1) % profileInfo.photos.length)
                    }
                    }
                />
            )}

            {!profileNotFound && (<div className="container">
                <div className="container main-container">
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-4 mb-5 mb-lg-0 wow fadeIn">
                            <div className="card border-0 shadow" id={"profile_info"}>
                                <div className="imgBoxProfile">
                                    <ImgPreloader
                                        src={profileMainPhoto.imgLink}
                                        alt={profileMainPhoto.imgAlt}
                                    />
                                    {profileInfo.premium === true ?
                                        <ul className="verify">
                                            <li className={"verify_wrapper"}
                                                data-toggle={"tooltip"}
                                                title={"Trusted"}
                                            >
                                                <img src={"/assets/img/svg/ver1.svg"} alt={"verified"}/>
                                            </li>
                                        </ul> :
                                        <div/>
                                    }
                                </div>
                                <div className="mb-2 mt-2" style={{
                                    gridTemplateColumns: (showPremiumFeature ? "5fr 12fr 5fr" : "2fr 12fr 2fr"),
                                    display: "grid",
                                    gridTemplateAreas: `'mock nickName copyIcon'`,
                                    wordWrap: "break-word",
                                    wordBreak: "break-all"
                                }}>
                                    <div id="onlineIndicator"
                                         style={{
                                             gridArea: "mock",
                                             alignSelf: "center",
                                             justifySelf: "start",
                                             fontSize: "10px",
                                             paddingLeft: "10px",
                                             display: "inline-flex"
                                         }}
                                    >
                                        <div className={"indicatorContainer"}>
                                            <div data-toggle="tooltip"
                                                 data-bs-original-title={profileInfo.isOnline ? "Online" : "Offline"}
                                                 className={profileInfo.isOnline ? "online-indicator" : "online-indicator-offline"}>
                                                <span className={profileInfo.isOnline ? "blink" : "noblink"}/>
                                            </div>
                                        </div>
                                        {showPremiumFeature ?
                                            <div onChange={switchOnlineStatus}
                                                 className={loading ? "custom-disabled form-check form-switch" : "form-check form-switch"}
                                                 style={{
                                                     fontSize: "14px",
                                                     display: "flex",
                                                     alignItems: "center",
                                                     paddingLeft: "2.5em"
                                                 }}
                                            >
                                                <input className="form-check-input" type="checkbox"
                                                       id="online_checkbox"/>
                                            </div>
                                            :
                                            <div/>
                                        }
                                    </div>
                                    <h3 className="h4 mb-0" style={{
                                        gridArea: "nickName",
                                        alignSelf: "center",
                                        justifySelf: "center",
                                        textAlign: "center",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden"
                                    }}>
                                        {profileInfo.nickName}
                                    </h3>
                                    <CopyToClipboard text={copyNickName.value}
                                                     onCopy={() => setCopyNickName({...copyNickName, copied: true})}>
                                        <i onClick={() => clickOnCopyIcon()}
                                           id={"copyIcon"} data-toggle={"tooltip"}
                                           data-bs-original-title={copyNickName.value + " Copied!"}
                                           className="fas fa-copy" style={copyNickName.copied ? {
                                                gridArea: "copyIcon",
                                                alignSelf: "center",
                                                justifySelf: "end",
                                                fontSize: "25px",
                                                paddingRight: "12px",
                                                color: "#039993"
                                            }
                                            :
                                            {
                                                gridArea: "copyIcon",
                                                alignSelf: "center",
                                                justifySelf: "end",
                                                fontSize: "25px",
                                                cursor: "pointer",
                                                paddingRight: "12px",
                                                color: "#878787"
                                            }
                                        }/>
                                    </CopyToClipboard>
                                </div>
                                <div className="">
                                    <div className="my-rating"
                                         style={{
                                             display: "flex",
                                             flexDirection: "column",
                                             textAlign: "center"
                                         }}
                                    >
                                        <div className="app mb-2">
                                            <span className="fas fa-mobile-alt">
                                                <h6 className={'pl-1'}>{profileInfo.appName}</h6>
                                            </span>
                                        </div>
                                        <h4>Add On {profileInfo.appName}</h4>
                                        <div className={"mb-4"}>
                                            <a
                                                href={profileInfo.appName === "snapchat" ? "snapchat://add//" + profileInfo.nickName : "kik://users//" + profileInfo.nickName}
                                                target="_blank"
                                                className="btn btn-primary">
                                                Add
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card border-0 shadow mt-5">
                                <div className="card-body p-1-9 pr-5 pl-5">
                                    <ul className="list-unstyled-mini-info mb-2 mt-2">
                                        <li style={{color: "#000000ad"}} className="mb-2 mt-2 rating">
                                            <i className="fas fa-calendar-alt"/>
                                            <span>I'm a {profileInfo.age}yo {profileInfo.gender}</span>
                                        </li>
                                        <li style={{color: "#000000ad"}} className="mb-2 mt-2 rating">
                                            <i className="fas fa-map-marker-alt"/>
                                            <span>Living in {profileInfo.countryFullName}</span>
                                            <div className="f32">
                                                <a className={'flag ' + profileInfo.countryShortName}/>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="social-icon-style3 ps-0">
                                        {profileInfo.owner === auth.userId && auth.isAuthenticated ?
                                            <div>
                                                {profileInfo.premium &&
                                                <li className="rounded-3"
                                                    onClick={() => redirectOnPremiumRules()}>
                                                    <i className="fas fa-bolt"/><span>Premium rules</span>
                                                </li>}
                                                <li className="rounded-3"
                                                    onClick={() => redirectOnEditProfilePage(profileInfo.id)}>
                                                    <i className="fas fa-user-edit"/><span>Edit profile</span>
                                                </li>
                                                <li className="rounded-3" onClick={handleDeleteShow}>
                                                    <i className="fas fa-trash"/><span>Delete profile</span>
                                                </li>
                                            </div>
                                            :
                                            <div/>
                                        }
                                        {profileInfo.owner !== auth.userId && auth.isAuthenticated ?
                                            <div>
                                                <li className="rounded-3" onClick={() => handleShowReporter(
                                                    auth.userEmail,
                                                    auth.userId,
                                                    profileInfo.id,
                                                    'profile',
                                                    "Report "+profileInfo.nickName+" profile",
                                                    "If you see someone posting a spammy or inappropriate profile, please report them straight away.",
                                                    [
                                                        {value: "spam", text: "Spam posted"},
                                                        {value: "sexual_under_18", text: "Sexual (Under 18)"},
                                                        {value: "illegal_criminal", text: "Illegal / Criminal"},
                                                        {value: "invalid_kik_username", text: "Invalid "+profileInfo.appName+" username"},
                                                        {value: "illicit_service", text: "Illicit services"},
                                                        {value: "personal_identifyable_information", text: "Personally identifyable information (PII)"},
                                                        {value: "uploader_requesting_removal", text: "Uploader requesting removal (Leaked)"},
                                                        {value: "scammed_user", text: "I was scammed by this user"},
                                                        {value: "other", text: "Other report reason"},
                                                    ]
                                                )}>
                                                    <i className="fas fa-user-slash"/><span>Report profile</span>
                                                </li>
                                            </div> :
                                            <div/>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="card border-0 shadow mt-5">
                                <div className="card-body p-1-9 pr-5 pl-5">
                                    <h3 className="mt-4">Statistics</h3>
                                    <ul className="list-unstyled mb-3 mt-2">
                                        <li data-toggle="tooltip" className="mt-2 rating" title="HOT points">
                                            <i className="fire-color fas fa-fire"/>
                                            <span>{profileInfo.hotPoints}</span></li>
                                        <li data-toggle="tooltip" className="mb-2 mt-2 rating" title="views">
                                            <i className="eye-color fas fa-eye"/>
                                            <span>{profileInfo.views}</span></li>
                                        <li data-toggle="tooltip" className="mb-2  rating" title="Likes"><i
                                            className="heart-beat fas fa-heart"/>
                                            <span>{profileInfo.likes}</span></li>
                                        <li style={{color: "#000000ad"}} data-toggle="tooltip"
                                            className="mb-2 mt-2 rating"
                                            title="disLikes"><i className="fas fa-heart-broken"/>
                                            <span>{profileInfo.disLikes}</span>
                                        </li>
                                        <li style={{color: "#000000ad"}} data-toggle="tooltip"
                                            className="mb-2 mt-2 rating"
                                            title="Photos"><i className="fas fa-camera"/>
                                            <span>{profileInfo.photos.length}</span>
                                        </li>
                                        <li style={{color: "#000000ad"}} data-toggle="tooltip"
                                            className="mb-2 mt-2 rating"
                                            title="Coments"><i className="fas fa-comment"/>
                                            <span>{profileInfo.commentNumber}</span>
                                        </li>
                                        <li style={{color: "#000000ad"}} data-toggle="tooltip"
                                            className="mb-2 mt-2 rating"
                                            title="Reports"><i className="fas fa-user-slash"/>
                                            <span>{profileInfo.reports}</span>
                                        </li>
                                    </ul>
                                    {auth.userId !== profileInfo.owner && auth.isAuthenticated ?
                                        <div className="container">
                                            <div className={loading ? "custom-disabled wrapper" : "wrapper"}>
                                                <OverlayTrigger
                                                    show={overlayDisShow}
                                                    placement="top"
                                                    rootClose
                                                    overlay={
                                                        overlayDisShow ?
                                                            <UpdatingPopover
                                                                id="popover-contained">{disLikeLimitTooltip.value}</UpdatingPopover>
                                                            :
                                                            <div/>
                                                    }
                                                >
                                                    <div className={
                                                        disLikeBlockedStatus ? "dis-like-button mr-2 is-active" : "dis-like-button mr-2"
                                                    }
                                                         onClick={disLikeHandler}>
                                                        <i style={{padding: "unset"}}
                                                           className="material-icons-round not-dis-liked bouncy"
                                                        >heart_broken</i>
                                                        <i style={{padding: "unset"}}
                                                           className="material-icons-round is-dis-liked bouncy">heart_broken</i>
                                                        <span className="dis-like-overlay"/>
                                                    </div>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    show={overlayLikeShow}
                                                    placement="top"
                                                    rootClose
                                                    overlay={
                                                        overlayLikeShow ?
                                                            <UpdatingPopover
                                                                id="popover-contained">{likeLimitTooltip.value}</UpdatingPopover>
                                                            :
                                                            <div/>
                                                    }
                                                >
                                                    <div className={
                                                        likeBlockedStatus ? "like-button is-active" : "like-button"
                                                    }
                                                         onClick={likeHandler}>
                                                        <i style={{padding: "unset"}}
                                                           className="material-icons not-liked bouncy"
                                                        >favorite_border</i>
                                                        <i style={{padding: "unset"}}
                                                           className="material-icons is-liked bouncy">favorite</i>
                                                        <span className="like-overlay"/>
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        :
                                        <div/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="ps-lg-1-6 ps-xl-5 text-white">
                                <div className="mb-5 wow fadeIn">
                                    <div className="text-start mb-1-6 wow fadeIn">
                                        <h2 className="h1 mb-0 text-primary">About Me</h2>
                                    </div>
                                    <div id={'aboutMe'} className={'aboutMe'}>
                                    </div>
                                </div>
                                <div className="mb-5 wow fadeIn" id={"photos"}>
                                    <div className="text-start mb-1-6 wow fadeIn">
                                        <h2 className="mb-0 text-primary">Photos</h2>
                                        {showPremiumFeature ?
                                            <div className="form-check form-switch pl-0 mt-3"
                                                 data-toggle={"tooltip"}
                                                 title={"In Development. For more info contact us kikflirt.verify@gmail.com"}
                                            >
                                                <label className="form-check-label"
                                                       htmlFor="mainPhotoSwitchBox"
                                                >Each 10
                                                    min
                                                    switch main photo </label>
                                                <input className="form-check-input ml-2 custom-disabled" type="checkbox"
                                                       id="mainPhotoSwitchBox"/>
                                            </div>
                                            : <div/>
                                        }
                                    </div>
                                    <div className="row">
                                        <Overlay
                                            target={targetHotPoint.current}
                                            show={overlayHotPointShow}
                                            placement="top"
                                        >
                                            <Tooltip id="hot-btn-tooltip">{hotPointLimitTooltip.value}</Tooltip>
                                        </Overlay>
                                        {profileInfo.photos.map((item, index) =>
                                            <div key={index} className="col-lg-4 col-6  thumb photo-gallery-card">
                                                {item.length !== 0 ?
                                                    <div id={"galleryWrapper"}
                                                        onClick={() => {
                                                        setPhotoIndex(index);
                                                        setOpenPhotoLightbox(true)
                                                        }}
                                                         style={{
                                                             cursor: "pointer",
                                                         }}>
                                                        <ImgPreloader
                                                            src={item.imgLink}
                                                            alt={item.imgAlt}
                                                        />
                                                    </div>
                                                    : <Loader/>}
                                                <div
                                                    key={index}
                                                    className="mt-2"
                                                    style={{display: "inline-flex", justifyContent: "center"}}
                                                >
                                                    {auth.userId ?
                                                        <div key={index} className="social-icon-style4">
                                                            {auth.userId !== profileInfo.owner ?
                                                                <div key={index} id="hot-photo"
                                                                     className={
                                                                         item.hotPointsHistory.find(el => {
                                                                             return el.userId === auth.userId
                                                                         }) ? item.hotPointsHistory.find(el => {
                                                                             return el.userId === auth.userId
                                                                         }).isBlocked
                                                                             ? "rounded-3 fire-color" : "rounded-3" : "rounded-3"}
                                                                     onClick={(event) => {
                                                                         hotPointHandler(event, item._id)
                                                                     }
                                                                     }
                                                                >
                                                                    <i className="fas fa-fire"/>
                                                                </div>
                                                                :
                                                                <p/>
                                                            }
                                                            <div style={{display: "none"}} data-toggle={"tooltip"}
                                                                 title={"In Development. For more info contact us kikflirt.verify@gmail.com"}>
                                                                <div id="comment-photo"
                                                                     className="rounded-3 custom-disabled">
                                                                    <i className="fas fa-comment"/>
                                                                </div>
                                                            </div>
                                                            {auth.userId !== profileInfo.owner ?
                                                                <div onClick={() => handleShowReporter(
                                                                        auth.userEmail,
                                                                        auth.userId,
                                                                        item._id,
                                                                        'photo',
                                                                    "Report "+profileInfo.nickName+" photo",
                                                                    "If you see someone posting a spammy or inappropriate profile, please report them straight away.",
                                                                    [
                                                                        {value: "spam", text: "Spam posted"},
                                                                        {value: "sexual_under_18", text: "Sexual (Under 18)"},
                                                                        {value: "illegal_criminal", text: "Illegal / Criminal"},
                                                                        {value: "invalid_kik_username", text: "Invalid "+profileInfo.appName+" username"},
                                                                        {value: "illicit_service", text: "Illicit services"},
                                                                        {value: "personal_identifyable_information", text: "Personally identifyable information (PII)"},
                                                                        {value: "uploader_requesting_removal", text: "Uploader requesting removal (Leaked)"},
                                                                        {value: "scammed_user", text: "I was scammed by this user"},
                                                                        {value: "other", text: "Other report reason"},                                                                    ]
                                                                )}>
                                                                    <div
                                                                        id="report-photo"
                                                                        className="rounded-3"
                                                                    >
                                                                        <i className="fas fa-user-slash"/>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <p/>
                                                            }
                                                            {auth.userId === profileInfo.owner ?
                                                                <div id="delete-photo"
                                                                     className={!loading ? "rounded-3" : "rounded-3 custom-disabled"}
                                                                     onClick={() => {
                                                                         setCurrentProfilePhotoItem(item)
                                                                         setShowDeleteProfilePhotoModal(true)
                                                                     }}>
                                                                    <i className="fas fa-trash"/>
                                                                </div>
                                                                :
                                                                <p/>
                                                            }
                                                        </div>
                                                        :
                                                        <div/>
                                                    }
                                                </div>
                                                {auth.userId === profileInfo.owner ?
                                                    <div className="form-check main-photo-check mt-1">
                                                        {item.mainPhoto === true ?
                                                            <div/>
                                                            :
                                                            <div>
                                                                <input className="form-check-input chb"
                                                                       type="checkbox"
                                                                       key={index}
                                                                       value={item._id}
                                                                       onChange={changeMainPhotoHandler}
                                                                       disabled={loading}
                                                                />
                                                                <label className="form-check-label"
                                                                       htmlFor="mainPhotoCheck">
                                                                    Main photo
                                                                </label>
                                                            </div>
                                                        }
                                                    </div>
                                                    : <div/>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center" id={"comments"}>
                        <div className="text-start mt-4 wow fadeIn">
                            <h2 className="h1 mb-0 text-primary">Comments</h2>
                        </div>
                        <ProfileComments currentProfile={profileInfo}/>
                    </div>
                </div>
            </div>)}
            <Reporter
                showReportModal={showReportModal}
                setShowReportModal={setShowReportModal}
                reporterContent={reporterContent}
            />
            <Modal show={showDeleteModal} onHide={handleDeleteClose}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to delete
                        your profile {profileInfo.nickName}?</Modal.Title>
                    <button type="button" className="btn-close btn-close-grey"
                            aria-label="Close" onClick={handleDeleteClose}>
                        <i className="fas fa-times"/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {profileInfo.premium ?
                        <h4>Deleting your profile is <b style={{color: 'red'}}>permanent</b> and will remove all content
                            including
                            photos, avatars and all profiles information.
                            <b style={{color: 'red'}}>Also your PREMIUM status will be lost.</b>
                            Are you sure you want to delete your profile?
                        </h4>
                        :
                        <h4>Deleting your profile is <b style={{color: 'red'}}>permanent</b> and will remove all content
                            including
                            photos, avatars and all profiles information. Are you sure you want to delete your profile?
                        </h4>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button id="delete_account" type="button" className="btn btn-primary"
                            onClick={deleteProfile}>Delete
                    </button>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteProfilePhotoModal} onHide={handleDeleteProfileClose}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to delete
                        your Photo?</Modal.Title>
                    <button type="button" className="btn-close btn-close-grey"
                            aria-label="Close" onClick={handleDeleteProfileClose}>
                        <i className="fas fa-times"/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <h4>
                        Deleting your photo is <b style={{color: 'red'}}>permanent</b>
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <button id="delete_profile_photo" type="button" className="btn btn-primary"
                            onClick={() => deleteProfilePhoto(currentProfilePhotoItem)}>Delete
                    </button>
                    <Button variant="secondary" onClick={handleDeleteProfileClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}