import React, {useRef} from 'react';
//import logo from './logo.svg';
import {useEffect} from 'react'
import {useRoutes} from "./routs";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import {useAuth} from "./hooks/auth.hook";
import {AuthContext} from "./context/auth.context";
import {NavBarAuth} from "./components/NavBarAuth";
import {NavBarUnAuth} from "./components/NavBarUnAuth";
import {FooterWave} from "./components/FooterWave";
import mySocket from "../src/SocketUtils";
import {HelmetProvider } from "react-helmet-async";
import $ from 'jquery'
import {ToastContainer} from "react-toastify";
import useScript from "./hooks/script.hook";
import {MainPage} from "./pages/MainPage";
import {AccountSettings} from "./pages/account/AccountSettings";
import {RegistrationPage} from "./pages/RegistrationPage";
import {AuthorizationPage} from "./pages/AthorizationPage";
import {AccountEmailConfirmation} from "./pages/account/AccountEmailConfirmation";
import {AccountPasswordRecovery} from "./pages/account/AccountPasswordRecovery";
import {AccountForgotPassword} from "./pages/account/AccountForgotPassword";

//const socket = io.connect("http://localhost:5000")

function App() {
    const {token, login, logout, userId, userEmail} = useAuth()
    const isAuthenticated = !!token
    const routes = useRoutes(isAuthenticated)
    const socket = useRef()
    // useScript('/assets/js/jquery.min.js');
    // useScript('/assets/bootstrap/js/bootstrap.min.js');
    // useScript('/assets/js/-Filterable-Cards-.js');
    // useScript('assets/js/accountSettings.js');
    // useScript('/assets/js/countrySelect.js');
    // useScript('/assets/js/countrySelect.min.js');
    // useScript('assets/js/editProfile.js');
    // useScript('assets/js/fancybox.min.js');
     //useScript('/assets/js/filter.js');
    // useScript('assets/js/Loading-Page-Animation-Style.js');
    // useScript('/assets/js/modalJS.js');
     //useScript('/assets/js/nav-bar.js');
    // useScript('assets/js/pCard_script.js');
    // useScript('assets/js/photoPage.js');
    // useScript('assets/js/photoUploadScript.js');
    // useScript('assets/js/postProfile.js');
    // useScript('assets/js/profilePage.js');
    // useScript('assets/js/profilePageList.js');
    // useScript('assets/js/scEditor.js');
     //useScript('/assets/js/toolkit.js');

    useEffect(() => {
        window.initToolkit()
        window.initToTopBtn()
    },[])

    useEffect(()=>{
        socket.current = mySocket
        socket.current.on('connect', ()=>{})
        socket.current.on('connect_error', ()=>{
            setTimeout(()=>socket.current.connect(),5000)
        })
        socket.current.on('disconnect',() => {})
    },[])

    useEffect(() => {
        if(isAuthenticated && userId){
            socket.current.send({event: "login", userId: userId, isAuth:isAuthenticated})
        }
    },[isAuthenticated,userId])

    return (

        <AuthContext.Provider value={{
            token, login, logout, userId, userEmail, isAuthenticated
        }}>
            <Router>
                    {isAuthenticated ? <NavBarAuth/> : <NavBarUnAuth/>}
                    {routes}
                    <FooterWave/>
            </Router>
        </AuthContext.Provider>

    )
}

export default App;
