import React, {useCallback, useEffect, useRef, useState} from 'react'
import "../styles/filter.css"
import {HiOutlineSearch} from 'react-icons/hi'
import Dropdown from "./Dropdown";
import ColumnSizer from "./ColumnSizer";
import useOutsideClick from "../hooks/useOutsideClick";
import Slider from "@mui/material/Slider";
import {redirectToPage} from "../scripts/Utils";
import {gaSendEvent} from "./GoogleAnalyticsComponent";

const storageNameFilters = 'filtersData'

export const FiltersComponents = (props) => {

    const {
        OPTIONS_GENDER,
        OPTIONS_ORIENTATION,
        OPTIONS_PROFILE_TYPE,
        defaultFilterState,
        countryIso,
        setCountryIso,
        search,
        setSearch,
        minAge,
        maxAge,
        setRangeValues,
        optionGender,
        setOptionGender,
        optionOrientation,
        setOptionOrientation,
        optionProfType,
        setOptionProfType,
    } = props.obj

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, window.hideFilterContainer, ["show-filter-btn", "show-filter-btn-ico"]);

    const [range, setRange] = useState([minAge, maxAge]);

    const handleSetDefaultFilters = () => {
        setSearch(defaultFilterState.search);
        setRangeValues({minAge: defaultFilterState.minAge, maxAge: defaultFilterState.maxAge});
        setOptionGender(defaultFilterState.gender);
        setOptionOrientation(defaultFilterState.orientation);
        setOptionProfType(defaultFilterState.profType);
        window.setCountry(defaultFilterState.country.code, defaultFilterState.country.placeHolder)
        setCountryIso(defaultFilterState.country.code)
    }

    const handleChangeGender = (gender) => {
        setOptionGender(gender);
    }

    const handleChangeOrientation = (orientation) => {
        setOptionOrientation(orientation);
    }

    const handleChangeProfType = (profType) => {
        setOptionProfType(profType);
    }

    const handleChange = ({target: {name, value}}) => {
        setRangeValues((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    const handleChangeRange = (event, newValue) => {
        setRangeValues({minAge: newValue[0], maxAge: newValue[1]});
    };

    const changeHandlerForCountrySelector = () => {
        setCountryIso(window.getSelectedCountry().iso2)
    }

    useEffect(() => {
        setRange([minAge, maxAge])
    }, [minAge, maxAge])

    useEffect(() => {
        window.initCountrySelect()
        window.setCountry(defaultFilterState.country.code, defaultFilterState.country.placeHolder)
    }, [])

    /**
     * устанавливаем значения фильтров которые были сохранены
     */
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem(storageNameFilters))
        if(savedFilters) {
            setSearch(savedFilters.search);
            setRangeValues({minAge: savedFilters.minAge, maxAge: savedFilters.maxAge});
            setOptionGender(savedFilters.gender);
            setOptionOrientation(savedFilters.orientation);
            setOptionProfType(savedFilters.profType);
            window.setCountry(savedFilters.country.code, savedFilters.country.placeHolder)
            setCountryIso(savedFilters.country.code)
        }
    }, [])

    /**
     * сохраняем фильтра в localStorage
     */
    useEffect(() => {
        localStorage.setItem(storageNameFilters,
            JSON.stringify({
                search: search,
                minAge: minAge,
                maxAge: maxAge,
                country: {code: countryIso, placeHolder: defaultFilterState.country.placeHolder},
                gender: optionGender,
                orientation: optionOrientation,
                profType: optionProfType
            }))
    }, [countryIso, search, minAge, maxAge, optionGender, optionOrientation, optionProfType])


    return (
        <div id={"filterContainer"} className={"sectionFilter"} ref={wrapperRef}>
            <div className={"filters"}>
                <button type="button" className={"close-filter-btn"} aria-label="Close"
                        onClick={() => window.hideFilterContainer()}>
                    <i className="fas fa-times"/>
                </button>
                <div className={"form"}>
                    <div className={"label"}>Search by Username</div>
                    <div className={"search"}>
                        <input
                            className={"inputMy"}
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            name="search"
                            placeholder="Search..."
                            required
                        />
                        <button className={"result"}>
                            <HiOutlineSearch name="search" size="16"/>
                        </button>
                    </div>
                </div>

                <div className={"sorting"}>
                    <div className={"dropdown"}>
                        <div className={"label"}>Gender</div>
                        <Dropdown
                            value={optionGender}
                            setValue={handleChangeGender}
                            options={OPTIONS_GENDER}
                        />
                    </div>
                </div>

                <div className={"sorting"}>
                    <div className={"dropdown"}>
                        <div className={"label"}>Orientation</div>
                        <Dropdown
                            value={optionOrientation}
                            setValue={handleChangeOrientation}
                            options={OPTIONS_ORIENTATION}
                        />
                    </div>
                </div>

                <div className={"sorting"}>
                    <div className={"dropdown"}>
                        <div className={"label"}>Profile type</div>
                        <Dropdown
                            value={optionProfType}
                            setValue={handleChangeProfType}
                            options={OPTIONS_PROFILE_TYPE}
                        />
                    </div>
                </div>

                <div className="sorting" style={{display: 'grid'}}>
                    <div className={"label"}>Country</div>
                    <input className="inputMy" id="country_selector" type="text"
                           onFocus={changeHandlerForCountrySelector} onChange={changeHandlerForCountrySelector}/>
                </div>

                <div className={"range"}>
                    <div className={"label"}>Age range</div>
                    <div className={"prices"}>
                        <input
                            className={"inputMy"}
                            type="number"
                            value={minAge}
                            onChange={handleChange}
                            name="minAge"
                            min={defaultFilterState.minAge}
                            max={defaultFilterState.maxAge}
                            placeholder={defaultFilterState.minAge}
                            required
                        />
                        <p className={"separator"}>to</p>
                        <input
                            className={"inputMy"}
                            type="number"
                            value={maxAge}
                            min={defaultFilterState.minAge}
                            max={defaultFilterState.maxAge}
                            onChange={handleChange}
                            name="maxAge"
                            placeholder={defaultFilterState.maxAge}
                            required
                        />
                    </div>

                    <div style={{marginTop: "8px"}}>
                        <Slider min={defaultFilterState.minAge} max={defaultFilterState.maxAge} value={range} onChange={handleChangeRange} valueLabelDisplay="auto"
                                disableSwap color="secondary"/>
                    </div>
                </div>
                <div className={"clearBtn"}>
                    <button className={"btn btn-primary"} onClick={handleSetDefaultFilters}>Clear</button>
                </div>
            </div>
        </div>
    )
}

export const NavFilter = ({
                            showColumnSizer,
                            defaultFilterState,
                            filterConditions,
                            handleSetGridOneColumn,
                            handleSetGridTwoColumns,
                            activeCategoriesIndex,
                            setCategoriesActiveIndex,
                            gridActive,
                            mapActive,
                            profileData
                          }) => {

    const [activeFiltersNumber, setActiveFiltersNumbers] = useState(null)
    const [categories, setCategories] = useState([]);

    const getCategories = async () => {
        setCategories(profileData.map(el => {return {id: el.id, title: el.title}}))
    };

    useEffect(() => {
        if (filterConditions) {
            setActiveFiltersNumbers(filterConditions.filter(cond => {
                if(cond.key === 'age' && (cond.value === defaultFilterState.minAge || cond.value === defaultFilterState.maxAge)){
                    return false
                }
                return cond.value !== ''
            }).length)
        }
    }, [filterConditions, defaultFilterState]);


    useEffect(() => {
        let isMount = true;
        !categories?.length && getCategories();
        return () => {
            isMount = false;
        };
    }, []);

    const handleChangeCategory = (id) => {
        setCategoriesActiveIndex(id);
    };

    useEffect(() => {
        if(activeCategoriesIndex === 'kik')
            gaSendEvent('user','main_page_change_category_kik')
        if(activeCategoriesIndex === 'snapchat')
            gaSendEvent('user','main_page_change_category_snapchat')
    },[activeCategoriesIndex])

    const handleShowFilter = () => {
        if (window.isFilterContainerVisible()) {
            window.hideFilterContainer()
        } else {
            window.showFilterContainer()
        }
    };

    const handleRedirectOnMainGridPage = () => {
        gaSendEvent('user','main_page_click_on_grid')
        redirectToPage('/')
    };

    const handleRedirectOnMainMapPage = () => {
        gaSendEvent('user','main_page_click_on_map')
        redirectToPage('/local-usernames')
    };

    return (
        <div className={"sectionNav"}>
            <div className={"nav notification-badges"}>
                {activeFiltersNumber ?
                    (<div data-badge={activeFiltersNumber} id={"show-filter-btn"} className={"filter-css"}
                         onClick={handleShowFilter}>
                        <i id="show-filter-btn-ico" className="fas fa-filter"/>
                    </div>)
                    :
                    (<div id={"show-filter-btn"} className={"filter-css"}
                          onClick={handleShowFilter}>
                        <i id="show-filter-btn-ico" className="fas fa-filter"/>
                    </div>)
                }
                <div id={"show-filter-btn"} className={gridActive ? "filter-css active" : "filter-css"}
                     onClick={handleRedirectOnMainGridPage}>
                    <i className="fas fa-th"/>
                </div>
                <div id={"show-filter-btn"} className={mapActive ? "filter-css active" : "filter-css"}
                     onClick={handleRedirectOnMainMapPage}>
                    <i className="fas fa-map-marked-alt"/>
                </div>
                <div className={"vl"} style={{marginRight: "10px"}}/>
                {categories?.map((item) => (
                    <button
                        className={item?.id === activeCategoriesIndex ? "link active" : "link"}
                        onClick={() => handleChangeCategory(item.id)}
                        key={item?.id}
                    >
                        {item?.title}
                    </button>
                ))}
            </div>
            <ColumnSizer handleSetGridOneColumn={handleSetGridOneColumn}
                         handleSetGridTwoColumns={handleSetGridTwoColumns}
                         showColumnSizer={showColumnSizer}
            />
        </div>
    )
}