import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {Loader} from "../../components/Loader";
import {appendHtmlContent} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";


export const BlogPage = (props) => {

    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()

    const [blog, setBlog] = useState(null)
    const [dateFormat, setFormatDate] = useState(null)

    const getBloc = useCallback(async () => {
        if (props.match.path === "/blog/:blogAlias") {
            try {
                const blog = await request(
                    `/api/blog/${props.match.params.blogAlias}`,
                    'GET',
                    null
                )
                setBlog(blog.blog)
                let options = {year: 'numeric', month: 'long', day: 'numeric' };
                let date  = new Date(blog.blog.publicationDate);
                setFormatDate(date.toLocaleDateString("en-US", options))
                window.scrollTo(0, 0)
            } catch (e) {
            }
        }
    }, [request])

    useEffect(() => {
        getBloc()
    }, [])

    useEffect(() =>{
        if(blog) {
            appendHtmlContent("#blogMainTitle", blog.blogMainTitle)
            appendHtmlContent("#blogMainContent", blog.blogMainContent)
        }
    },[blog])

    useEffect(() => {
        message(error,'error', "/")
        clearError();
    },[error,message,clearError])

    return (
        <section className="py-5" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <div className={"card"} style={{padding: "20px", display: "flex", height: "100%", color: "black",
                    backgroundColor: "white"}}>
                    {blog ?
                        <div style={{width: '100%'}}>
                            <div>
                                <h2>{blog.blogMainTitle}</h2>
                            </div>
                            <div style={{color: "#0000008f"}} className="mb-3">
                                {dateFormat}
                            </div>
                            <div id={"blogMainContent"} className=""/>
                        </div>
                        : <Loader/>}
                </div>
            </div>
        </section>
    )
}