import React from 'react';
import {ImgPreloader} from "./ImgPreloader";


const getMainPhotoLinkFromProfile = (profile) => {
    let mainP = profile.photos.find(el => {
        return el.mainPhoto === true
    })
    return mainP ? mainP.imgLink : "/assets/img/no-photo.svg"
}

const getMainPhotoAltFromProfile = (profile) => {
    let mainP = profile.photos.find(el => {
        return el.mainPhoto === true
    })
    return mainP ? mainP.imgAlt : "no-photo"
}

export const MainPageProfileCardContent = (
    {
        gridContentMobileTwoColumn,
        setCurrentTargetProfile,
        setShowProfilePreview,
        profile,
    }) => {

    return (
        <div>
            <div
                className={!gridContentMobileTwoColumn ? "imgBx" : "imgBx-mobile-two-column"}
                style={{cursor: "pointer"}}
                onClick={() => {
                    setCurrentTargetProfile(profile);
                    setShowProfilePreview(true)
                }}>
                {profile.photos.length > 0 ?
                    <ImgPreloader
                        src={getMainPhotoLinkFromProfile(profile)}
                        alt={getMainPhotoAltFromProfile(profile)}
                    />
                    :
                    <ImgPreloader
                        src={"/assets/img/no-photo.svg"}
                        alt={"no-photo"}
                    />
                }
                {profile.premium === true ?
                    <ul className={!gridContentMobileTwoColumn ? "verify_main" : "verify_main-mobile-two-column"}>
                        <li className={!gridContentMobileTwoColumn ? "verify_wrapper" : "verify_wrapper-mobile-two-column"}
                            data-toggle={"tooltip"}
                            data-bs-original-title={"Trusted"}
                        >
                            <img src={"/assets/img/svg/ver1.svg"} alt={"verified"}/>
                        </li>
                    </ul> :
                    <div/>
                }
            </div>
            <div className="my-content">
                <div className="mb-0 mt-0"
                     style={{
                         gridTemplateColumns: "2fr 12fr 2fr",
                         display: "grid",
                         gridTemplateAreas: `'mock nickName copyIcon'`,
                         wordWrap: "break-word",
                         wordBreak: "break-all",
                         minHeight: "38px"
                     }}>
                    <div id="onlineIndicator"
                         style={{
                             gridArea: "mock",
                             alignSelf: "baseline",
                             justifySelf: "start",
                             fontSize: "10px",
                             paddingLeft: "4px",
                         }}
                    >
                        <div
                            className={!gridContentMobileTwoColumn ? "indicatorContainer" : "indicatorContainer-mobile-two-column"}>
                            <div data-toggle="tooltip"
                                 title={profile.isOnline ? "Online" : "Offline"}
                                 className=
                                     {!gridContentMobileTwoColumn ?
                                         (profile.isOnline ? "online-indicator" : "online-indicator-offline") :
                                         (profile.isOnline ? "online-indicator-mobile-two-column" : "online-indicator-offline-mobile-two-column")
                                     }
                            >
                                <span className={profile.isOnline ? (!gridContentMobileTwoColumn ? "blink" : "blink-mobile-two-column") : "noblink"}/>
                            </div>
                        </div>
                    </div>
                    <h3 className={!gridContentMobileTwoColumn ? "h5 mb-0" : "h5 mb-0 nickName-text-mobile-two-column"}
                        style={{
                            gridArea: "nickName",
                            alignSelf: "baseline",
                            justifySelf: "center",
                            textAlign: "center",
                            cursor: "pointer",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}
                        onClick={() => {
                            setCurrentTargetProfile(profile);
                            setShowProfilePreview(true)
                        }
                        }
                    >
                        {profile.nickName}
                    </h3>
                </div>
                <div className="app">
                    <span className={!gridContentMobileTwoColumn ? "fas fa-mobile-alt" : "fas fa-mobile-alt app-icon-font-size-mobile-two-column"}>
                        <h6 className={!gridContentMobileTwoColumn ? "pl-1" : "pl-1 app-text-font-size-mobile-two-column"}>{profile.appName}</h6>
                    </span>
                </div>
                <div className="my-rating">
                    <div data-toggle="tooltip" className="rating" title="HOT points">
                        <i className="fire-color fas fa-fire"/>
                        <span>{profile.hotPoints}</span>
                    </div>
                    <div data-toggle="tooltip" className="rating" title="">
                        <i className="eye-color fas fa-eye"/>
                        <span>{profile.views}</span>
                    </div>
                    <div data-toggle="tooltip" className="rating" title="">
                        <i className="heart-beat fas fa-heart"/>
                        <span>{profile.likes}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}