import React, {useContext, useEffect, useRef, useState} from 'react'
import {AuthContext} from "../context/auth.context";
import {redirectToPage} from "../scripts/Utils";
import useOutsideClick from "../hooks/useOutsideClick";

export const NavBarAuth = () => {
    const auth = useContext(AuthContext)

    const [showProfileMenu, setShowProfileMenu] = useState(false)

    const handleShowProfileMenu = () => setShowProfileMenu(!showProfileMenu);

    function hideProfMenu() {
        setShowProfileMenu(false)
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, hideProfMenu);

    useEffect(() => {
        window.initNavBarManager()
        //window.initProfileMenuDropDown()
        window.initNavBarLogoAnimation()
    })

    const logoutHandler = event => {
        event.preventDefault()
        auth.logout()
        redirectToPage('/')
    }

    const redirectOnHome = event => {
        event.preventDefault()
        redirectToPage('/')
    }

    const redirectOnAccountSettings = event => {
        event.preventDefault()
        redirectToPage('/account-settings')
    }

    const redirectOnPostProfile = event => {
        event.preventDefault()
        redirectToPage('/post-profile')
    }

    const redirectOnMyProfileList = event => {
        event.preventDefault()
        redirectToPage('/my-profile-list')
    }

    const redirectOnLeakedNudes = () => {
        redirectToPage("/leaked-nudes", true)
    }

    const redirectOnLocalUsernames = () => {
        redirectToPage("/local-usernames", true)
    }

    const redirectOnBlog = () => {
        redirectToPage("/blog", true)
    }

    const redirectOnContactUs = (event) => {
        redirectToPage('/contact_us', true)
    }


    const redirectOnContactUsRemoveUserNames = (event) => {
        redirectToPage("/contact_us/?subject=remove_username", true)
    }

    const redirectOnFAQ = (event) => {
        redirectToPage("/documents/faq", true)
    }

    return (
        <div>
            <span className="screen-darken"/>
            <nav id="navbar_main"
                 className="bg-nav-bar nav-bar-display-no mobile-offcanvas navbar navbar-expand-sm navbar-dark"
            >
                <div className="container-fluid" style={{marginTop: "10px"}}>

                    <ul className="mobile navbar-nav ml-2 notification-badges-main-menu">
                        <div style={{display: "inline-flex", paddingBottom: "8px"}}>
                            <span style={{color: "white",textOverflow: "ellipsis", overflow: "hidden", width: "100%"}}>{auth.userEmail}</span>
                            <button className="btn-close float-end" style={{display: "contents"}}><i className="fas fa-times"/></button>
                        </div>
                        <li style={{
                            borderTop: "1px solid white",
                            display: "block",
                            overflow: "hidden",
                            paddingTop: "6px"
                        }}/>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnHome}> <i
                                className="fas fa-home menu-icon"/> <span>Home</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnPostProfile}>
                                <i className="fas fa-user-plus menu-icon"/>
                                <span>Post Profile</span> </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnMyProfileList}>
                                <i className="fas fa-users menu-icon"/> My Profiles </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnAccountSettings}>
                                <i className="fas fa-user-cog menu-icon"/> Account Settings </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnLeakedNudes}>
                                <img src={"/assets/img/svg/kiss.svg"} style={{width: "20px", marginRight: "4px"}} alt={"kiss"}/>
                                <span>Leaked Nudes</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnBlog}>
                                <i className="fas fa-blog"/>
                                <span> Blog</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnLocalUsernames}>
                                <i className="fas fa-map-marked-alt"/>
                                <span> Local Usernames</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={redirectOnContactUs}>
                                <i className="far fa-address-book"/>
                                <span> Contact Us</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={redirectOnContactUsRemoveUserNames}>
                                <i className="fas fa-user-minus"/>
                                <span> Remove Username</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={redirectOnFAQ}>
                                <i className="far fa-question-circle"/>
                                <span> FAQ</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={logoutHandler}> <i
                                className="fas fa-sign-out-alt menu-icon"/> Logout</a>
                        </li>
                        <li style={{
                            borderBottom: "1px solid white",
                            display: "block",
                            overflow: "hidden",
                            paddingBottom: "6px"
                        }}/>
                    </ul>
                    <div style={{fontSize: "xx-small", color: "white", marginTop: "16px"}}>
                        <p className="text-center">KikFlirt is not affiliated with, or sponsored or endorsed by, Kik Interactive, Inc or Snap Inc.</p>
                        <p className="copyright text-center">© 2024 KikFlirt. All rights reserved.</p>
                    </div>
                </div>
            </nav>

            <nav className="navbar navbar-expand-sm navbar-dark">
                <div className="logo-and-brand-mobile" onClick={redirectOnHome}>
                    <img src={"/assets/img/example3.png"} alt="Main Logo" className="image-logo"/>
                    <a className="navbar-brand" >
                        <span>Kik</span>
                        <div className="textcontainer">
                            <span className="particletext hearts flirttext">
                            <b>Flirt</b>
                           </span>
                        </div>
                    </a>
                </div>
                <button className="navbar-toggler x collapsed" type="button" data-trigger="navbar_main"
                        data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="icon-bar"/>
                    <span className="icon-bar"/>
                    <span className="icon-bar"/>
                </button>
                <div className="collapse navbar-collapse">
                    <ul className="desktop-navbar navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnBlog}>
                                <i className="fas fa-blog"/>
                                <span> Blog</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnLocalUsernames}>
                                <i className="fas fa-map-marked-alt"/> <span> Local Usernames</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnLeakedNudes}>
                                <img src={"/assets/img/svg/kiss.svg"} style={{width: "20px", marginRight: "4px"}} alt={"kiss"}/>
                            <span>Leaked Nudes</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnHome}> <i
                                className="fas fa-home menu-icon"/> <span>Home</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnPostProfile}> <i
                                className="fas fa-user-plus menu-icon"/> <span>Post Profile</span>
                            </a>
                        </li>
                        <li className="nav-item dropdown" ref={wrapperRef}>
                            <div className="nav-link  dropdown-toggle">
                                <div className="action-profile">
                                    <div id="profileWrapper">
                                        <div className="profile-nav-menu rounded-circle"
                                             onClick={handleShowProfileMenu}>
                                            <i className="fas fa-user-circle"/>
                                        </div>
                                    </div>
                                    <div className={showProfileMenu ? "prof-menu active" : "prof-menu"}>
                                        <ul>
                                            <li style={{
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                                                display: "block",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden"
                                            }}>
                                                <h6>{auth.userEmail}</h6>
                                            </li>
                                            <li>
                                                <img src={"/assets/icons/user.png"} alt={"My profiles"}/>
                                                <a href={"/my-profile-list"} target="_blank">My profiles</a>
                                            </li>
                                            <li>
                                                <img src={"/assets/icons/settings.png"} alt={"Setting"}/>
                                                <a href={'/account-settings'} target="_blank">Setting</a>
                                            </li>
                                            <li>
                                                <img src={"/assets/icons/question.png"} alt={"Help"}/>
                                                <a href={"/documents/faq"} target="_blank">Help</a>
                                            </li>
                                            <li style={{cursor: "pointer"}} onClick={logoutHandler}>
                                                <img src={"/assets/icons/log-out.png"} alt={"Logout"}/>
                                                <div>Logout</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
)
}