import React, {useCallback} from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {redirectToPage} from "../scripts/Utils";
import {useAuth} from "./auth.hook";

const redirectOnLogin = () => {
    redirectToPage('/login')
}

export const useMessage = () => {
    const {logout} = useAuth()
    return useCallback((message, toastType = null, redirectToAfterClosing = false) =>{

        switch (toastType){
            case 'success':
                toastType = toast.TYPE.SUCCESS;
                break;
            case 'error':
                toastType = toast.TYPE.ERROR;
                break;
            default:
                toastType = toast.TYPE.INFO;
                break;

        }

        toast(message, {
            type: toastType,
            autoClose: 2600,
            pauseOnHover: true,
            closeButton: true,
            closeOnClick: true,
            draggable: true,
            onClose: () => {
                if(redirectToAfterClosing){
                    redirectToPage(redirectToAfterClosing)
                }
                if(message.includes('Your session was expired')){
                    logout()
                    redirectOnLogin()
                    window.location.reload();
                }
            }
        });

    }, [logout])
}