import React, {useContext, useEffect, useState} from 'react'
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/auth.context";
import {gaSendEvent} from "../components/GoogleAnalyticsComponent";
import {redirectToPage} from "../scripts/Utils";
import {useMessage} from "../hooks/message.hook";
import useClientDeviceInfo from "../hooks/client_device_info.hook";

export const AuthorizationPage = (props) => {
    const auth = useContext(AuthContext)
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const clientInfo = useClientDeviceInfo()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        message(error,'error')
        clearError();
    },[error,message,clearError])

    const redirectOnRegistration = event => {
         if(typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage("/register")
    }

    const redirectOnMain = event => {
        if(typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage("/")
    }

    const redirectOnForgotPassword = event => {
         if(typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage("/forgot-password")
    }

    const redirectOnPostProfile = event => {
        if(typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage("/post-profile")
    }

    const [loginForm, setLoginForm] = useState({
        email: '',
        password: ''
    })

    const changeHandlerForLogin = event => {
        setLoginForm({...loginForm, [event.target.name]: event.target.value})
    }

    const loginHandler = async (event) => {
        if(event) {
            event.preventDefault()
        }
        try {
            const data = await request(
                '/api/auth/login',
                'POST',
                {...loginForm,...{devicesInfo: clientInfo}},
                {})
            //message(data.message,'success')
            gaSendEvent('user','login',data.userEmail)
            auth.login(data.token, data.userId, data.userEmail)
            if(props.match.path === '/post-profile'){
                redirectOnPostProfile()
            }else {
                redirectOnMain()
            }
        } catch (e) {
        }
    }


    return (
        <section className="py-0" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <section className="clean-block clean-form dark h-100">
                    <div className="container">
                        <div className="block-heading" style={{paddingTop: '0px'}}>
                            <h2 className="text-primary" style={{marginTop: '40px'}}>Sign In To KikFlirt<br/></h2>
                        </div>
                        <form id={"loginForm"}>
                            <div className="form__group field">
                                <input className="form__field" type="email" autoComplete="off" required
                                       placeholder="Email" id={'email'} name="email" onChange={changeHandlerForLogin}/>
                                <label htmlFor="email" className="form__label">Email</label>
                            </div>
                            <div className="form__group field mt-3">
                                <input className="form__field" type="password" autoComplete="off" required
                                       placeholder="Password" id={'password'} name="password" onChange={changeHandlerForLogin}/>
                                <label htmlFor="password" className="form__label">Password</label>
                            </div>
                            <hr style={{marginTop: '30px', marginBottom: '10px'}}/>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" onClick={(event) => {loginHandler(event)}} disabled={loading}>
                                    {loading ?
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <div>
                                            <i className="fas fa-sign-in-alt"/>
                                            &nbsp;SIGN IN
                                        </div>
                                    }
                                </button>
                            </div>
                            <div className="form-group">
                                <p style={{textAlign: 'center'}}>
                                    <a className="text-sm" href="" onClick={redirectOnForgotPassword}>Forgot Password?</a>
                                </p>
                            </div>
                            <div className="form-group">
                                <p style={{textAlign: 'center'}}>Don&#39;t have an account? <br/>
                                    <a className="text-sm" href={""} onClick={redirectOnRegistration}>Sign Up</a>
                                </p>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </section>
    )
}