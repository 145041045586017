import {Modal, OverlayTrigger, Popover} from "react-bootstrap";
import React, {useContext, useEffect, useState} from 'react'
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/auth.context";
import {Loader} from "./Loader";
import {ImgPreloader} from "./ImgPreloader";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {redirectToPage} from "../scripts/Utils";
import {useMessage} from "../hooks/message.hook";

const UpdatingPopover = React.forwardRef(
    ({popper, children, show: _, ...props}, ref) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [children, popper]);

        return (
            <Popover ref={ref} body {...props}>
                {children}
            </Popover>
        );
    },
);

export const PreviewProfileModal = ({currentProfile, showProfilePreviewModal, handleProfilePreviewClose}) => {

    const auth = useContext(AuthContext)
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()

    const [profile, setProfile] = useState(null)
    const [profileMainPhoto, setProfileMainPhoto] = useState({
        imgLink: "/assets/img/no-photo.svg",
        imgAlt: "no-photo"
    })
    const [showProfilePreview, setShowProfilePreview] = useState(false);

    const [copyNickName, setCopyNickName] = useState({
        value: '',
        copied: false,
    })

    const clickOnCopyIcon = () => {
        setTimeout(() => {
            window.hideCopyIconClickTooltip()
            setCopyNickName({...copyNickName, copied: false})
        }, 1000);
    }

    const [likeLimitTooltip, setLikeLimitTooltip] = useState({
        value: "+1"
    });
    const [likeBlockedStatus, setLikeBlockedStatus] = useState(false);
    const [disLikeLimitTooltip, setDisLimitTooltip] = useState({
        value: "+1"
    });
    const [disLikeBlockedStatus, setDisLikeBlockedStatus] = useState(false);
    const [overlayLikeShow, setOverlayLikeShow] = useState(false)
    const [overlayDisShow, setOverlayDisShow] = useState(false)

    const redirectOnProfilePageViaImg = (event, profileId) => {
        //event.preventDefault()
        if (event.target.tagName.toLowerCase() === 'img') {
            redirectToPage('/profile/' + profileId)
        }
    }

    const redirectOnProfilePagePhoto = (profileId) => {
        //event.preventDefault()
        redirectToPage('/profile/' + profileId + '#photos')
    }

    const redirectOnProfilePageComments = (profileId) => {
        //event.preventDefault()
        redirectToPage('/profile/' + profileId + '#comments')
    }

    const currentProfileMainPhoto = async (profile) => {
        let response = await request(
            `/api/profile/photo/main_profile_photo`,
            'POST',
            {
                profileId: profile._id
            },
        );

        if (response && response.photo) {
            setProfileMainPhoto({
                imgLink: response.photo.imgLink,
                imgAlt: response.photo.imgAlt
            })
        }else{
            setProfileMainPhoto({
                imgLink: "/assets/img/no-photo.svg",
                imgAlt: "no-photo"
            })
        }
    }

    useEffect(() => {
        if (currentProfile) {
            setProfile(currentProfile)
            setCopyNickName({...copyNickName, value: currentProfile.nickName})

            currentProfileMainPhoto(currentProfile)
        }
    }, [currentProfile])

    useEffect(() => {
        setShowProfilePreview(showProfilePreviewModal)
    }, [showProfilePreviewModal])

    useEffect(() => {
        window.initCopyIconClickTooltip()
    }, [copyNickName.value])

    useEffect(() => {
        message(error, 'error')
        clearError();
    }, [error, message, clearError])

    const likeHandler = async () => {
        try {
            const likeEvent = await request(
                '/api/profile/like',
                'POST',
                {profileId: profile._id, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )

            setOverlayLikeShow(true);
            setTimeout(() => {
                setOverlayLikeShow(false)
            }, 1000);


            if (typeof likeEvent.error !== "undefined") {
                setLikeLimitTooltip({
                        ...likeLimitTooltip, value: "Like limit left: " +
                        likeEvent.error.likeLimitLeftHours + "h " +
                        likeEvent.error.likeLimitLeftMinutes + "m " +
                        likeEvent.error.likeLimitLeftSeconds + "s"
                    }
                )
            } else {
                setLikeLimitTooltip({...likeLimitTooltip, value: "+1"})
            }
            await updateClientLikeClickStatus()
        } catch (e) {
            console.log(e.message)
        }
    }

    const disLikeHandler = async () => {
        try {
            const disLikeEvent = await request(
                '/api/profile/dislike',
                'POST',
                {profileId: profile._id, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )

            setOverlayDisShow(true);
            setTimeout(() => {
                setOverlayDisShow(false)
            }, 1000);

            if (typeof disLikeEvent.error !== "undefined") {
                setDisLimitTooltip({
                        ...disLikeLimitTooltip, value: "DisLike limit left: " +
                        disLikeEvent.error.dislikeLimitLeftHours + "h " +
                        disLikeEvent.error.dislikeLimitLeftMinutes + "m " +
                        disLikeEvent.error.dislikeLimitLeftSeconds + "s"
                    }
                )
            } else {
                setDisLimitTooltip({...disLikeLimitTooltip, value: "+1"})
            }
            await updateClientDisLikeClickStatus();
        } catch (e) {
            console.log(e.message)
        }

    }


    const updateClientLikeClickStatus = async () => {
        try {
            const likeClickStatus = await request(
                '/api/profile/updateLikeBlockStatus',
                'POST',
                {profileId: profile._id, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )
            if (likeClickStatus) {
                if (likeClickStatus.likeHistory) {
                    let blockedUser = likeClickStatus.likeHistory.find(el => {
                        return el.userId === auth.userId
                    })

                    if (blockedUser) {
                        setLikeBlockedStatus(blockedUser.isBlocked)
                    } else {
                        setLikeBlockedStatus(false)
                    }
                }
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const updateClientDisLikeClickStatus = async () => {
        try {
            const disLikeClickStatus = await request(
                '/api/profile/updateDisLikeBlockStatus',
                'POST',
                {profileId: profile._id, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )

            if (disLikeClickStatus) {
                if (disLikeClickStatus.disLikesHistory) {
                    let blockedUser = disLikeClickStatus.disLikesHistory.find(el => {
                        return el.userId === auth.userId
                    })
                    if (blockedUser) {
                        setDisLikeBlockedStatus(blockedUser.isBlocked)
                    } else {
                        setDisLikeBlockedStatus(false)
                    }
                }
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        if (showProfilePreview) {
            if (profile) {
                if ((auth.userId !== profile.owner) && auth.isAuthenticated) {
                    updateClientLikeClickStatus();
                    updateClientDisLikeClickStatus();
                }
            }
        }
    }, [showProfilePreview, auth])


    const [photoLoading, setPhotoLoading] = useState(true)

    return (
        <Modal show={showProfilePreview} onHide={() => {handleProfilePreviewClose();setPhotoLoading(true)}} centered>
            <Modal.Body>
                {profile ?
                    (<div>
                            <div className={"imgBoxProfile"}
                                 style={photoLoading ? {cursor: "pointer", minHeight: "450px"} : {cursor: "pointer", minHeight: "fit-content"}}
                                 onClick={(event) => {
                                     redirectOnProfilePageViaImg(event, profile._id)
                                 }
                                 }
                            >
                                <button type="button"
                                        className="btn-close btn-close-grey preview-profile-close"
                                        aria-label="Close"
                                        onClick={() => {handleProfilePreviewClose();setPhotoLoading(true)}}
                                >
                                    <i className="fas fa-times"/>
                                </button>
                                {photoLoading &&
                                <div className={"preview-smooth smooth-image-wrapper"}>
                                    <div className="smooth-preloader">
                                        <span className="loader"/>
                                    </div>
                                </div>}
                                {!loading &&
                                 <ImgPreloader
                                    src={profileMainPhoto.imgLink}
                                    alt={profileMainPhoto.imgAlt}
                                    onLoad={() => setPhotoLoading(false)}
                                    />
                                }

                                {profile.premium === true ?
                                    <ul className={"verify_main"}>
                                        <li className={"verify_wrapper"}
                                            data-toggle={"tooltip"}
                                            data-bs-original-title={"Trusted"}
                                        >
                                            <img src={"/assets/img/svg/ver1.svg"} alt={"verified"}/>
                                        </li>
                                    </ul> :
                                    <div/>
                                }

                            </div>
                            <hr/>
                            <div id={"about-me"}>
                                <div className="mb-1 wow fadeIn">
                                    <div id={'aboutMe'} className={'aboutMe'}
                                         dangerouslySetInnerHTML={{__html: profile.about}}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div id={"tags"} className={"tags-wrapper"}>
                                <div className="tags-profile-badges">
                                    {profile.gender}
                                </div>
                                <div className="tags-profile-badges">
                                    {profile.age}yo
                                </div>
                                <div className="tags-profile-badges">
                                    {profile.orientation}
                                </div>
                                <div className="tags-profile-badges">
                                    {profile.countryShortName}
                                </div>
                                <div className="tags-profile-badges">
                                    {profile.type}
                                </div>
                            </div>
                            <div id={"profile_actions"} style={{display: "flex"}}>
                                <ul className="my-action notification-badges"
                                    style={{position: "relative", padding: "3px", display: "inline-flex"}}>
                                    {profile.photos.length > 0 ?
                                        (<li data-badge={profile.photos.length > 99 ? "99+" : profile.photos.length}
                                             className={"see-always-icon"}
                                             onClick={() => redirectOnProfilePagePhoto(profile._id)}>
                                            <i className="fas fa-camera"/>
                                        </li>)
                                        :
                                        (<li className={"see-always-icon"}
                                             onClick={() => redirectOnProfilePagePhoto(profile._id)}>
                                            <i className="fas fa-camera"/>
                                        </li>)
                                    }
                                    {profile.commentNumber > 0 ?
                                        (<li data-badge={profile.commentNumber > 99 ? "99+" : profile.commentNumber}
                                             className={"see-always-icon"}
                                             onClick={() => redirectOnProfilePageComments(profile._id)}>
                                            <i className="fas fas fa-comment"/>
                                        </li>)
                                        :
                                        (<li className={"see-always-icon"}
                                             onClick={() => redirectOnProfilePageComments(profile._id)}>
                                            <i className="fas fas fa-comment"/>
                                        </li>)
                                    }
                                </ul>
                                {auth.userId !== profile.owner && auth.isAuthenticated ?
                                    <div className="container"
                                         style={{padding: "inherit", display: "inline-flex", alignItems: "center"}}>
                                        <div key={profile.id}
                                             className={loading ? "custom-disabled wrapper" : "wrapper"}>
                                            <OverlayTrigger
                                                show={overlayDisShow}
                                                placement="top"
                                                rootClose
                                                overlay={
                                                    overlayDisShow ?
                                                        <UpdatingPopover
                                                            id="popover-contained">{disLikeLimitTooltip.value}
                                                        </UpdatingPopover>
                                                        :
                                                        <div/>
                                                }
                                            >
                                                <div className={
                                                    disLikeBlockedStatus ? "dis-like-button mr-2 is-active" : "dis-like-button mr-2"
                                                }
                                                     style={{borderRadius: "6px", width: "40px", height: "40px"}}
                                                     onClick={disLikeHandler}>
                                                    <i style={{padding: "unset"}}
                                                       className="material-icons-round not-dis-liked bouncy"
                                                    >heart_broken</i>
                                                    <i style={{padding: "unset"}}
                                                       className="material-icons-round is-dis-liked bouncy">heart_broken</i>
                                                    <span className="dis-like-overlay"
                                                          style={{borderRadius: "6px", width: "40px", height: "40px"}}
                                                    />
                                                </div>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                show={overlayLikeShow}
                                                placement="top"
                                                rootClose
                                                overlay={
                                                    overlayLikeShow ?
                                                        <UpdatingPopover
                                                            id="popover-contained">{likeLimitTooltip.value}</UpdatingPopover>
                                                        :
                                                        <div/>
                                                }
                                            >
                                                <div className={
                                                    likeBlockedStatus ? "like-button is-active" : "like-button"
                                                }
                                                     style={{borderRadius: "6px", width: "40px", height: "40px"}}
                                                     onClick={likeHandler}>
                                                    <i style={{padding: "unset"}}
                                                       className="material-icons not-liked bouncy"
                                                    >favorite_border</i>
                                                    <i style={{padding: "unset"}}
                                                       className="material-icons is-liked bouncy">favorite</i>
                                                    <span className="like-overlay"
                                                          style={{borderRadius: "6px", width: "40px", height: "40px"}}
                                                    />
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    :
                                    <div/>
                                }
                            </div>
                            <div className="card compact bg-base-200">
                                <div className="card-body flex space-y-1">
                                    <h2 className="font-bold text-lg">Add On {profile.appName}</h2>
                                    <p style={{textOverflow: "ellipsis", overflow: "hidden"}}>{profile.nickName}</p>
                                    <p><a
                                        href={profile.appName === "snapchat" ? "snapchat://add//" + profile.nickName : "kik://users//" + profile.nickName}
                                        target="_blank" className="btn btn-primary">Add</a>
                                        <CopyToClipboard text={copyNickName.value}
                                                         onCopy={() => setCopyNickName({
                                                             ...copyNickName,
                                                             copied: true
                                                         })}>
                                            <button onClick={() => clickOnCopyIcon()}
                                                    id={"copyIcon"}
                                                    data-toggle={"tooltip"}
                                                    data-bs-original-title={copyNickName.value + " Copied!"}
                                                    className="btn btn-secondary ml-2">Copy
                                            </button>
                                        </CopyToClipboard>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : <Loader/>}
            </Modal.Body>
        </Modal>

    )
}