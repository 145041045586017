import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import FileUploadComponent from "../FileUploadComponent";
import {useMessage} from "../../hooks/message.hook";
import {useHttp} from "../../hooks/http.hook";
import FormData from "form-data";
import {AuthContext} from "../../context/auth.context";

const Reporter = ({
   showReportModal,
   setShowReportModal,
   reporterContent
}) => {
    const auth = useContext(AuthContext)
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()

    const [formData, setFormData] = useState({})
    const [filesData, setFilesData] = useState([])
    const handleClose = () => {
        setFormData({})
        setFilesData([])
        setShowReportModal(!showReportModal)
    };

    const changeHandlerForFormData = event => {
        setFormData({...formData, [event.target.name]: event.target.value})
    }

    useEffect(() => {
        let content = window.document.getElementById('html')
        if (showReportModal) {
            content.style.overflow = "hidden"
        } else {
            content.style.overflow = "unset"
        }

    }, [showReportModal])

    useEffect(() => {
        setFormData({...formData, id: reporterContent.id})
        setFormData({...formData, type: reporterContent.type})
    }, [])

    const uploadScreenshots =  async (file) => {
        const formDataForUploadFile = new FormData();

        formDataForUploadFile.append(
            "file",
            file
        );

        const data = await request(
            '/api/file/uploadIntoFs',
            'POST',
            formDataForUploadFile,
            {},
            true
        );

        return data
    }

    const sendReportTicket = async () => {

        let screenshotData = []
        for(let data of filesData){
            let response =  await uploadScreenshots(data.file)
            screenshotData.push({filename: response.imgName})
        }


        let requestBody = {
            fromEmail: reporterContent.fromEmail,
            fromId: reporterContent.fromId,
            id: reporterContent.id,
            type: reporterContent.type,
            reason_subject: formData.reason_subject,
            reason_description: formData.reason_description,
            screenshots_list: screenshotData
        }

        try {
            const reportRequest = await request(
                `/api/mailer/sendReportEmail`,
                'POST',
                requestBody,
                {Authorization: `Bearer ${auth.token}`}
            )
            message(reportRequest.message,'success')
            handleClose()
        } catch (e) {

        }
    }

    useEffect(() => {
        message(error,'error')
        clearError();
    },[error,message,clearError])

    return (
        <Modal show={showReportModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{reporterContent.title}</Modal.Title>
                <button type="button" className="btn-close btn-close-grey"
                        aria-label="Close" onClick={handleClose}>
                    <i className="fas fa-times"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <p>{reporterContent.body}</p>
                <form>
                    <select className="form-select mb-4" aria-label="Default select example" name={"reason_subject"} onChange={changeHandlerForFormData}>
                        <option defaultValue>Please select a reason.</option>
                        {reporterContent.reportReasons.map((el,index) => <option key={index} value={el.text}>{el.text}</option>)}
                    </select>

                    <div className="mb-3">
                        <label htmlFor="issue" className="form-label">Describe the issue</label>
                        <textarea className="form-control" id="issue" rows="10" name={"reason_description"} onChange={changeHandlerForFormData}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">If you have some screenshots, please attach them below</label>
                        <FileUploadComponent
                            filesData={filesData}
                            setFilesData={setFilesData}
                            maxFiles={3}
                            buttonText={'Drag \'n\' drop some screenshot here, or click to select files.'}
                            buttonStyle={{margin: "unset", fontSize: "small"}}
                            maxFilesText={'Screenshots slots left '}
                            dropZonesStyle={{
                                dropzone: {height: 150, overflow: "auto", borderStyle: "dashed"},
                                preview: {padding: "8px 3%", minHeight: "fit-content"},
                                previewImage: {maxHeight: "150px"},
                                dropzoneActive: {borderColor: 'green'},
                            }}
                        />
                    </div>
                    <button type="button" className="btn btn-primary" onClick={sendReportTicket} disabled={loading}>
                        {loading ?
                            <div className="spinner-grow spinner-grow-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <div>
                                Report
                            </div>
                        }
                    </button>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
};
export default Reporter