import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/auth.context";
import {redirectToPage, timeSince} from "../scripts/Utils";
import useOnScreen from "../hooks/is_on_screen";
import {useMessage} from "../hooks/message.hook";


export const ProfileComments = ({currentProfile}) => {
    const auth = useContext(AuthContext)
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const commentTextArea = useRef()
    const isCommentTextAreaVisible = useOnScreen(commentTextArea)

    const [profiles, setProfiles] = useState([])
    const [commentProfileSelectOptions, setCommentProfileSelectOptions] = useState([])
    const [commentProfilePhoto, setCommentProfilePhoto] = useState(null)
    const [commentProfileName, setCommentProfileName] = useState(null)
    const [currentSelectedProfileCommentator, setCurrentProfileCommentator] = useState({
        name: "",
        id: "",
        photoUrl: ""
    })
    const [commentsList, setCommentsList] = useState([])

    const redirectOnSignIn = event => {
        if (typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage('/login')
    }

    const redirectOnPostProfile = event => {
        if (typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage('/post-profile')
    }

    const redirectOnProfilePage = (profileId) => {
        redirectToPage('/profile/' + profileId+"#profile_info")
    }

    const getScEditorValue = () => {
        return window.getScEditor().instance(window.document.getElementById('textarea-input')).val();
    }

    const clearScEditorValue = () => {
        window.getScEditor().instance(window.document.getElementById('textarea-input')).val('');
    }

    useEffect(() => {
        let el = window.document.getElementById('textarea-input')
        if(el &&  isCommentTextAreaVisible){
            window.initScEditorTextArea(el)
        }

    }, [isCommentTextAreaVisible])

    useEffect(() => {
        message(error,'error')
        clearError();
    },[error,message,clearError])

    const getProfilesByOwner = useCallback(async (owner) => {
        const profilesList = await request(
            `/api/profile/get_profiles/${owner}`,
            'GET',
            null,
            {Authorization: `Bearer ${auth.token}`}
        );
        if (profilesList) {
            setProfiles(profilesList.profiles)
            if (profilesList.profiles.length > 0) {
                setCommentProfilePhoto(profilesList.profiles[0].photos.length > 0 ? profilesList.profiles[0].photos[0].imgLink : "/assets/img/no-photo.svg")
                setCommentProfileName(profilesList.profiles[0].nickName)
                setCurrentProfileCommentator({
                    name: profilesList.profiles[0].nickName,
                    id: profilesList.profiles[0]._id,
                    photoUrl: profilesList.profiles[0].photos.length > 0 ? profilesList.profiles[0].photos[0].imgLink : "/assets/img/no-photo.svg"
                })
            }
        }

    }, [request,auth]);

    //get comments
    const getProfileComments = useCallback(async () => {

        const commentsListResponse = await request(
            `/api/profile/get_profiles_comments/?profileId=${currentProfile.id}`,
            'GET',
            null,
            {Authorization: `Bearer ${auth.token}`}
        );

        let listOfComments = commentsListResponse.commentList;

        for(let comment of listOfComments){
            comment.commentTime = timeSince(comment.commentTime);
        }

        setCommentsList(listOfComments)
    }, [request, currentProfile.id, auth]);

    //post comments
    const postProfileComment = useCallback(async (event) => {
        event.preventDefault();
        let today = new Date();
        // let date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
        // let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        // let currentCommentPostTime = date + ' ' + time
        let commentContent = getScEditorValue();
        const postedComments = await request(
            `/api/profile/post_comment/`,
            'POST',
            {
                profileId: currentProfile.id,
                authorName: currentSelectedProfileCommentator.name,
                authorId: currentSelectedProfileCommentator.id,
                commentContent: commentContent,
                commentTime: today,
                authorPhotoUrl: currentSelectedProfileCommentator.photoUrl
            },
            {Authorization: `Bearer ${auth.token}`}
        );
        clearScEditorValue()
        if(postedComments) {
            message(postedComments.message,"success")
            await getProfileComments()
        }
    }, [request,currentSelectedProfileCommentator, currentProfile]);


    const buildCommentSelectProfileOptions = async (profiles) => {
        let tempArr = [];
        for (let profile of profiles) {
            tempArr.push({value: profile._id, label: profile.nickName})
        }
        setCommentProfileSelectOptions(tempArr)
    }

    const deleteComment = async (commentId) => {
        //event.preventDefault()
        const deleteComment = await request(
            `/api/profile/delete_comment/`,
            'POST',
            {commentId: commentId},
            {Authorization: `Bearer ${auth.token}`}
        );
        message(deleteComment.message,"success")
        await getProfileComments()
    }

    useEffect(() => {
        if (auth.isAuthenticated) {
            getProfilesByOwner(auth.userId)
        }
    }, [auth.isAuthenticated])

    useEffect(() => {
        buildCommentSelectProfileOptions(profiles)
    }, [profiles])

    useEffect(() => {
        if (currentProfile.id !== "") {
            getProfileComments()
        }
    }, [currentProfile.id])


    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-center row">
                <div className="col">
                    <div className="d-flex flex-column comment-section">
                        {commentsList.length !== 0 ?
                            commentsList.map((item, index) =>
                                <div key={index} className="bg-white p-2 mb-1">
                                    <div
                                        className="d-flex flex-row">
                                        <div className='c-avatar user-info'
                                             onClick={() =>
                                                 redirectOnProfilePage(item.authorId)
                                             }
                                        >
                                            <img
                                                className="rounded-circle"
                                                src={item.authorPhotoUrl}
                                                height="60" width="60" alt={"photo"}
                                                style={{objectFit: "cover"}}
                                            />
                                            <span
                                                className={item.isOnline ?
                                                    "c-avatar__status c-avatar_online" :
                                                    "c-avatar__status c-avatar_offline"}
                                            />
                                        </div>
                                        <div
                                            className="d-flex flex-column justify-content-start ml-2 user-info"
                                            onClick={() =>
                                                redirectOnProfilePage(item.authorId)
                                            }
                                        >
                                                        <span
                                                            className="d-block font-weight-bold name">{item.authorName}</span>
                                            <span
                                                className="date text-black-50">{item.commentTime} ago</span>
                                        </div>
                                        {auth.userId === (typeof currentProfile !== "undefined" ? currentProfile.owner : "")
                                        && profiles.length > 0 && currentProfile.premium ?
                                        <div
                                            className={loading ? "custom-disabled d-flex flex-column user-info" : "d-flex flex-column user-info"}
                                            style={{marginLeft: "auto", marginRight: "6px", marginTop: "6px"}}
                                            onClick={() =>
                                                deleteComment(item._id)
                                            }
                                        >
                                            <i className="fas fa-trash"/>
                                        </div>
                                        :
                                        <div/>}
                                    </div>
                                    <div className="mt-2">
                                        <div className="comment-text"
                                             dangerouslySetInnerHTML={{__html: item.commentContent}}>
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            <div className={"container mt-4"}>
                                <div className={""}
                                     style={{
                                         textAlign: "center",
                                         height: "100px",
                                         justifyContent: "center"
                                     }}>
                                    <h4>
                                       <span style={{ color: "white"}}>No comments yet</span>
                                    </h4>
                                </div>
                            </div>
                        }


                        {auth.isAuthenticated ?
                            (profiles.length > 0 ?
                                (auth.userId !== (typeof currentProfile !== "undefined" ? currentProfile.owner : "") ?
                                        <div className="bg-light p-2">
                                            <div className="d-flex flex-row align-items-start commentator_block">
                                                <div style={{display: "flex"}}>
                                                <img
                                                    className="img-author rounded-circle"
                                                    src={commentProfilePhoto}
                                                    alt={"profPhoto"}
                                                    height="60" width="60"/>
                                                    <span style={{marginLeft: "0.5rem"}}>{commentProfileName}</span>
                                                </div>
                                                <textarea
                                                    ref={commentTextArea}
                                                    id={"textarea-input"}
                                                    className="form-control shadow-none textarea"
                                                />
                                            </div>
                                            <div className="mt-2 text-right d-flex"
                                                 style={{justifyContent: "flex-end"}}>
                                                <Select
                                                    onChange={
                                                        e => {

                                                            let profilesPhotos = profiles.find(el => {
                                                                return el._id === e.value
                                                            }).photos

                                                            let url = profilesPhotos.length > 0 ? profilesPhotos[0].imgLink : "/assets/img/no-photo.svg";

                                                            setCommentProfilePhoto(url)
                                                            setCommentProfileName(e.label)
                                                            setCurrentProfileCommentator({
                                                                name: e.label,
                                                                id: e.value,
                                                                photoUrl: url
                                                            })
                                                        }}
                                                    style={{width: "100%"}}
                                                    defaultValue={{
                                                        label: profiles[0].nickName,
                                                        value: profiles[0]._id,
                                                    }}
                                                    options={commentProfileSelectOptions}
                                                    id="prof_name"
                                                    name="prof_name"/>
                                                <button className="btn btn-primary btn-sm shadow-none ml-1"
                                                        type="button"
                                                        onClick={postProfileComment}
                                                        disabled={loading}
                                                >
                                                    {loading ?
                                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        :
                                                        <div>
                                                            Post
                                                        </div>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        : <div/>
                                ) :
                                <div className={"container mt-4"}>
                                    <div className={"card"}
                                         style={{
                                             textAlign: "center",
                                             height: "300px",
                                             justifyContent: "center"
                                         }}>
                                        <h3>
                                            For leave a comment you should have at least one
                                            <p style={{color: "#913688", cursor: "pointer"}}
                                               onClick={redirectOnPostProfile}>
                                                posted profile
                                            </p>
                                        </h3>
                                    </div>
                                </div>)
                            :
                            <div className={"container mt-4"}>
                                <div className={"card"}
                                     style={{
                                         textAlign: "center",
                                         height: "300px",
                                         justifyContent: "center"
                                     }}>
                                    <h3><p style={{color: "#913688", cursor: "pointer"}}
                                           onClick={redirectOnSignIn}>Sign
                                        In</p> into your account for like or for comment profiles
                                    </h3>
                                </div>
                            </div>}

                    </div>
                </div>
            </div>
        </div>

    )
}