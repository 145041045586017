import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {RegistrationPage} from "./pages/RegistrationPage";
import {MainPage} from "./pages/MainPage";
import {AuthorizationPage} from "./pages/AthorizationPage";
import {AccountEmailConfirmation} from "./pages/account/AccountEmailConfirmation";
import {AccountPasswordRecovery} from "./pages/account/AccountPasswordRecovery";
import {AccountForgotPassword} from "./pages/account/AccountForgotPassword";
import {AccountSettings} from "./pages/account/AccountSettings";
import {AccountPostProfile} from "./pages/account/AccountPostProfile";
import {AccountProfile} from "./pages/account/AccountProfile";
import {AccountProfileList} from "./pages/account/AccountProfileList";
import {AccountProfileEdit} from "./pages/account/AccountProfileEdit";
import {DocumentsPage} from "./pages/documents/DocumentsPage";
import {ContactUsPage} from "./pages/ContactUsPage";
import {BlogPage} from "./pages/blogs/BlogPage";
import {BlogPageList} from "./pages/blogs/BlogPageList";
import {LeakedNudes} from "./pages/LeakedNudesPage";

export const useRoutes = (isAuthenticated) => {
    if (isAuthenticated) {
        return (
            <Switch>
                <Route path="/account-settings" exact>
                    <AccountSettings/>
                </Route>
                <Route path="/post-profile" exact>
                    <AccountPostProfile/>
                </Route>
                <Route path="/my-profile-list" exact>
                    <AccountProfileList/>
                </Route>
                <Route path="/profile/:profileId" component={AccountProfile}/>
                <Route path="/edit_profile/:profileId" component={AccountProfileEdit}/>
                <Route path="/documents/:documentAlias" component={DocumentsPage}/>
                <Route path="/blog/:blogAlias" component={BlogPage}/>
                <Route path="/blog" component={BlogPageList}/>
                <Route path="/contact_us" component={ContactUsPage}/>
                <Route path="/leaked-nudes" component={LeakedNudes} exact/>
                <Route path="/local-usernames" component={MainPage} exact/>
                <Route path="/" component={MainPage} exact/>
                <Redirect to="/"/>
            </Switch>
        )
    }
    return (
        <Switch>
            <Route path="/register" exact>
                <RegistrationPage/>
            </Route>
            <Route path="/login" exact>
                <AuthorizationPage/>
            </Route>
            <Route path="/confirm/:confirmationEmailCode" component={AccountEmailConfirmation}/>
            <Route path="/documents/:documentAlias" component={DocumentsPage}/>
            <Route path="/blog/:blogAlias" component={BlogPage}/>
            <Route path="/blog" component={BlogPageList}/>
            <Route path="/recovery-password/:forgotPasswordCode" component={AccountPasswordRecovery}/>
            <Route path="/forgot-password" component={AccountForgotPassword}/>
            <Route path="/profile/:profileId" component={AccountProfile}/>
            <Route path="/contact_us" component={ContactUsPage}/>
            <Route path="/post-profile" exact>
                <AuthorizationPage/>
            </Route>
            <Route path="/leaked-nudes" component={LeakedNudes} exact/>
            <Route path="/local-usernames" component={MainPage} exact/>
            <Route path="/*" component={MainPage}/>
        </Switch>
    )
}
