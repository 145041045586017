import React, {createRef, useEffect, useRef, useState} from 'react'
import 'materialize-css';
import '../../node_modules/hover.css/css/hover.css'
import {useHttp} from "../hooks/http.hook";
import mySocket from "../../src/SocketUtils";
import 'render-smooth-image-react/build/style.css';
import useWindowDimensions from "../hooks/window_dimensions.hook";
import {PreviewProfileModal} from "../components/PreviewProfileModal";
import {useMessage} from "../hooks/message.hook";
import {FiltersComponents, NavFilter} from "../components/FiltersComponents";
import {filterData, SearchType} from 'filter-data';
import {MainPageGrid} from "../components/MainPageGrid";
import {MainPageMap} from "../components/MainPageMap";
import AdBlockDetector from "../components/AdBlockDetector";
import {useDetectAdBlock} from "adblock-detect-react";
import BannerAdTop from "../components/ad/BannerAdTop";


const OPTIONS_GENDER = ['Any gender', 'girl', 'guy', 'trans'];
const OPTIONS_ORIENTATION = ['Any orientation', 'lesbian', 'gay', 'bi', 'straight', 'other'];
const OPTIONS_PROFILE_TYPE = ['Any profile type', 'clean', 'dirty'];

const defaultFilterState = {
    search: '',
    minAge: 18,
    maxAge: 80,
    country: {code: 'xn', placeHolder: 'Select country'},
    gender: OPTIONS_GENDER[0],
    orientation: OPTIONS_ORIENTATION[0],
    profType: OPTIONS_PROFILE_TYPE[0]
}

export const MainPage = (props) => {
    //const adBlockDetected = useDetectAdBlock();

    const {width} = useWindowDimensions();
    /**
     * OFFSETS
     * @type {number}
     */
    const chunkSize = 20;

    /**
     * Profiles data -> for main grid only, also tabs - controls
     */
    const [profileData, setProfileData] = useState([
        {
            id: 'snapchat',
            title: "Snapchat",
            offset: 0,
            total_profiles: 0,
            profileData: []
        },
        {
            id: 'kik',
            title: "Kik",
            offset: 0,
            total_profiles: 0,
            profileData: []
        }
    ])
    const displayOffsetFirstPart = 8;

    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()

    const [showMainPageGrid, setShowMainPageGrid] = useState(true)
    const [showMainPageMap, setShowMainPageMap] = useState(false)

    const [currentTargetProfile, setCurrentTargetProfile] = useState(null)
    const [showProfilePreview, setShowProfilePreview] = useState(false)

    const handleProfilePreviewClose = () => setShowProfilePreview(false);

    const [gridContentMobileTwoColumn, setGridContentMobileTwoColumn] = useState(false)
    const [noProfilesVisibility, setNoProfilesVisibility] = useState(false)

    //filters start
    const [filterConditions, setFilterConditions] = useState(null);
    const [activeCategoriesIndex, setCategoriesActiveIndex] = useState("snapchat");

    const [countryIso, setCountryIso] = useState(defaultFilterState.country.code);
    const [search, setSearch] = useState(defaultFilterState.search);
    const [{minAge, maxAge}, setRangeValues] = useState({
        minAge: defaultFilterState.minAge,
        maxAge: defaultFilterState.maxAge
    });

    const [optionGender, setOptionGender] = useState(defaultFilterState.gender);
    const [optionOrientation, setOptionOrientation] = useState(defaultFilterState.orientation);
    const [optionProfType, setOptionProfType] = useState(defaultFilterState.profType);
    //filters end

    /**
     * Profiles data start profilesInfo,profilesMap  -> for map grid only
     */
    const [profilesInfo, setProfilesInfo] = useState([])
    const [profilesMap, setProfilesMap] = useState([])
    const [profilesInfoSplicePartOne, setProfilesInfoSplicePartOne] = useState([])
    const [profilesInfoSplicePartTwo, setProfilesInfoSplicePartTwo] = useState([])

    const handleSetGridOneColumn = () => setGridContentMobileTwoColumn(false);
    const handleSetGridTwoColumns = () => setGridContentMobileTwoColumn(true);

    const handleGetProfilesAndFilterV2 = async (offset) => {
        try {
            if(props.match.path === '/local-usernames') {

                const profileDataLocalUserNames = await request(
                    `/api/profile/get_profiles`,
                    'POST',
                    {
                        app: activeCategoriesIndex
                    },
                );

                if (profileDataLocalUserNames.profiles.length > 0){
                    setProfilesMap(profileDataLocalUserNames.profiles)
                }

            }else{
                const profileDataResponse = await request(
                    `/api/profile/get_profiles/v2`,
                    'POST',
                    {
                        app: activeCategoriesIndex,
                        offset: offset,
                        chunkSize: chunkSize
                    },
                );

                if (profileDataResponse.profiles.length > 0) {
                    let profileDatUpdated = profileData.map(el => {
                        if (el.id === activeCategoriesIndex) {
                            el.profileData = [...el.profileData, ...profileDataResponse.profiles]
                            el.total_profiles = profileDataResponse.total_profiles
                        }
                        return el
                    });
                    setProfileData(profileDatUpdated)
                }
            }
        } catch (e) {

        }

    }


    useEffect(() => {

        let profiles = profileData.find(el => {return el.id === activeCategoriesIndex}).profileData;
        if(props.match.path === '/local-usernames'){
            profiles = profilesMap;
        }

        const onlyPrem = profiles.filter(prof => {
            return prof.premium
        })

        const notPrem = profiles.filter(prof => {
            return !prof.premium
        })

        const filterConditions = [
            {
                key: 'nickName',
                value: search,
                type: SearchType.LK,
            },
            {
                key: 'age',
                value: minAge,
                type: SearchType.GTE,
            },
            {
                key: 'age',
                value: maxAge,
                type: SearchType.LTE,
            },
            {
                key: 'gender',
                value: optionGender === defaultFilterState.gender ? '' : optionGender,
                type: SearchType.EQ,
            },
            {
                key: 'orientation',
                value: optionOrientation === defaultFilterState.orientation ? '' : optionOrientation,
                type: SearchType.EQ,
            },
            {
                key: 'type',
                value: optionProfType === defaultFilterState.profType ? '' : optionProfType,
                type: SearchType.EQ,
            },
            {
                key: 'countryShortName',
                value: countryIso === defaultFilterState.country.code ? '' : countryIso,
                type: SearchType.EQ,
            },

        ];
        setFilterConditions(filterConditions)

        const notPremFiltered = filterData(notPrem, filterConditions, {caseSensitive: false});

        const mergedProfData = [...onlyPrem, ...notPremFiltered]

        if(props.match.path === '/local-usernames'){
            setProfilesInfo(mergedProfData);
        }else {
            mergedProfData.map(profile => profile.nodeRef = createRef())

            if (mergedProfData.length > displayOffsetFirstPart) {
                setProfilesInfoSplicePartOne(mergedProfData.splice(0, displayOffsetFirstPart));
                setProfilesInfoSplicePartTwo(mergedProfData);
            } else {
                setProfilesInfoSplicePartOne(mergedProfData);
                setProfilesInfoSplicePartTwo([]);
            }

            if (mergedProfData.length > 0) {
                setTimeout(() => {
                    window.updateProgress();
                    window.initToolTip()
                }, 630);
            }
        }

    }, [
        profileData.find(el => {return el.id === activeCategoriesIndex}).profileData,
        profilesMap,
        countryIso,
        search,
        minAge,
        maxAge,
        optionGender,
        optionOrientation,
        optionProfType
    ])

    useEffect(() => {
        setProfileData(profileData.map(el => {if(el.id !== activeCategoriesIndex){el.profileData = []; el.offset = 0} return el;}))
    }, [activeCategoriesIndex])

    useEffect(() => {
        let currentOffset = profileData.find(el => {return el.id === activeCategoriesIndex}).offset
        handleGetProfilesAndFilterV2(currentOffset)
    }, [profileData.find(el => {return el.id === activeCategoriesIndex}).offset, activeCategoriesIndex])

    useEffect(() => {
        message(error, 'error')
        clearError();
    }, [error, message, clearError])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (props.match.path === '/local-usernames') {
            setShowMainPageGrid(false)
            setShowMainPageMap(true)
        }
    }, [])

    useEffect(() => {
        window.initMainPageMobileGridNavigation()
    }, [])

    useEffect(() => {
        window.initToolTip()
        window.initMainPageMobileGridNavigation()
    }, [])

    useEffect(() => {
        if (mySocket.connected) {
            mySocket.on("message", function (message) {
                if (message.online) {
                    //handleGetProfilesAndFilter()
                }
            })
        }
    }, [mySocket.connected])


    useEffect(() => {

        if (360 < width < 420) {
            setGridContentMobileTwoColumn(true)
        }

        if (width > 420) {
            setGridContentMobileTwoColumn(false)
        }

        if (width < 360) {
            setGridContentMobileTwoColumn(false)
        }

    }, [width])

    return (
        <section className="py-5" style={{minHeight: '100%', minWidth: '100%'}}>
                <div>
                    <BannerAdTop width={width}/>
                    <FiltersComponents obj={{
                        OPTIONS_GENDER,
                        OPTIONS_ORIENTATION,
                        OPTIONS_PROFILE_TYPE,
                        defaultFilterState,
                        countryIso,
                        setCountryIso,
                        search,
                        setSearch,
                        minAge,
                        maxAge,
                        setRangeValues,
                        optionGender,
                        setOptionGender,
                        optionOrientation,
                        setOptionOrientation,
                        optionProfType,
                        setOptionProfType,
                    }}/>
                    <div className="section">
                        <div>
                            <NavFilter
                                showColumnSizer={showMainPageGrid}
                                defaultFilterState={defaultFilterState}
                                filterConditions={filterConditions}
                                handleSetGridOneColumn={handleSetGridOneColumn}
                                handleSetGridTwoColumns={handleSetGridTwoColumns}
                                activeCategoriesIndex={activeCategoriesIndex}
                                setCategoriesActiveIndex={setCategoriesActiveIndex}
                                gridActive={showMainPageGrid}
                                mapActive={showMainPageMap}
                                profileData={profileData}
                            />

                            {showMainPageGrid &&
                            <MainPageGrid
                                showMainPageGrid={showMainPageGrid}
                                loading={loading}
                                gridContentMobileTwoColumn={gridContentMobileTwoColumn}
                                noProfilesVisibility={noProfilesVisibility}
                                setCurrentTargetProfile={setCurrentTargetProfile}
                                setShowProfilePreview={setShowProfilePreview}
                                activeCategoriesIndex={activeCategoriesIndex}
                                profilesInfoSplicePartOne={profilesInfoSplicePartOne}
                                profilesInfoSplicePartTwo={profilesInfoSplicePartTwo}
                                width={width}
                                profileData={profileData}
                                setProfileData={setProfileData}
                                chunkSize={chunkSize}
                            />}

                            {showMainPageMap &&
                            <MainPageMap
                                loading={loading}
                                profilesInfo={profilesInfo}
                                setCurrentTargetProfile={setCurrentTargetProfile}
                                setShowProfilePreview={setShowProfilePreview}
                            />
                            }
                        </div>
                    </div>
                    <PreviewProfileModal currentProfile={currentTargetProfile}
                                         showProfilePreviewModal={showProfilePreview}
                                         handleProfilePreviewClose={handleProfilePreviewClose}/>
                </div>
        </section>
    )
}