import React, {useEffect, useState} from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Loader} from "./Loader";
import {MainGridMockProfiles} from "./MaingGridMockProfiles";
import {MainPageProfileCardContent} from "./MainPageProfileCardContent";
import BannerAdMiddle from "./ad/BanerAdMiddle";
import InfiniteScroll from "./infiniteScroll/index";

export const MainPageGrid = (
    {
      loading,
      gridContentMobileTwoColumn,
      noProfilesVisibility,
      setCurrentTargetProfile,
      setShowProfilePreview,
      activeCategoriesIndex,
      profilesInfoSplicePartOne,
      profilesInfoSplicePartTwo,
      width,
      profileData,
      setProfileData,
      chunkSize
    }) => {

    const animationTimeOut = 600;

    const [currentProfileData, setCurrentProfileData] = useState(profileData.find(el => {return el.id === activeCategoriesIndex}))

    useEffect(() => {
        setCurrentProfileData(profileData.find(el => {return el.id === activeCategoriesIndex}))
    },[activeCategoriesIndex])

    const fetchProfiles = () => {
        let profileDatUpdated = profileData.map(el => {if(el.id === activeCategoriesIndex){el.offset+=chunkSize} return el});
        setProfileData(profileDatUpdated)
    }

    return (
        <div>
            <InfiniteScroll
                inverse={false}
                style={{ overflow: 'hidden'}}
                dataLength={currentProfileData.profileData.length}
                next={fetchProfiles}
                hasMore={currentProfileData.profileData.length < currentProfileData.total_profiles && currentProfileData.profileData.length % (chunkSize*3) !== 0}
                loader={loading && <div className={"mb-2"}><Loader/></div>}
            >
            <div id={"firstPart"} className="my-grid">
                <div id="grid" className={!gridContentMobileTwoColumn ? "my-container" : "my-container-mobile-two-column"}>
                    {profilesInfoSplicePartOne.length > 0 &&
                    profilesInfoSplicePartOne.map((profile, index) =>
                        <TransitionGroup appear={true} key={index} component={null}>
                        <CSSTransition
                            nodeRef={profile.nodeRef}
                            key={index}
                            timeout={{enter: animationTimeOut,exit: animationTimeOut}}
                            classNames="fade"
                        >
                            <div key={index} ref={profile.nodeRef} className="filtr-item my-card">
                                    <MainPageProfileCardContent
                                        setCurrentTargetProfile={setCurrentTargetProfile}
                                        gridContentMobileTwoColumn={gridContentMobileTwoColumn}
                                        setShowProfilePreview={setShowProfilePreview}
                                        profile={profile}
                                    />
                            </div>
                        </CSSTransition>
                        </TransitionGroup>
                       )
                    }
                    {!noProfilesVisibility && <MainGridMockProfiles profilesInfo={profilesInfoSplicePartOne}/>}
                    <div style={noProfilesVisibility ? {
                        display: "block",
                        color: "#aeaeae"
                    } : {display: "none"}}>{"NO PROFILES"}</div>
                </div>
            </div>
            <BannerAdMiddle width={width}/>
            <div id={"secondPart"} className="my-grid" style={{padding: "unset"}}>
                <div id="grid" className={!gridContentMobileTwoColumn ? "my-container" : "my-container-mobile-two-column"}>
                    {profilesInfoSplicePartTwo.length > 0 &&
                    profilesInfoSplicePartTwo.map((profile, index) =>
                        <TransitionGroup appear={true} key={index} component={null}>
                            <CSSTransition
                                nodeRef={profile.nodeRef}
                                key={index}
                                timeout={{enter: animationTimeOut,exit: animationTimeOut}}
                                classNames="fade"
                            >
                                <div key={index} ref={profile.nodeRef} className="filtr-item my-card">
                                    <MainPageProfileCardContent
                                        setCurrentTargetProfile={setCurrentTargetProfile}
                                        gridContentMobileTwoColumn={gridContentMobileTwoColumn}
                                        setShowProfilePreview={setShowProfilePreview}
                                        profile={profile}
                                    />
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    )
                    }
                    <MainGridMockProfiles profilesInfo={profilesInfoSplicePartTwo}/>
                </div>
            </div>
            </InfiniteScroll>
            {currentProfileData.profileData.length < currentProfileData.total_profiles && currentProfileData.profileData.length % (chunkSize*3) === 0 &&
                <div>
                    {loading ? <div className={"mb-2"}><Loader/></div> : <div/>}
                    <div style={{justifyContent: "center", display: "flex"}}>
                        <button
                            onClick={
                                () => {
                                    fetchProfiles()
                                }
                            }
                            className={loading ? "btn btn-primary hide-toast-loader" : "btn btn-primary"}
                        >
                            Show More
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}