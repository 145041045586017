import React, {useContext, useEffect, useState} from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Loader} from "./Loader";
import {MainGridMockProfiles} from "./MaingGridMockProfiles";
import BannerAdMiddle from "./ad/BanerAdMiddle";
import Lightbox from "react-image-lightbox";
import {AuthContext} from "../context/auth.context";
import {LeakedNudesPagePhotoContent} from "./LeakedNudesPagePhotoContent";
import NudesPhotoHotPoints from "./photoLightBoxButtons/NudesPhotoHotPoints";
import NudesPhotoReport from "./photoLightBoxButtons/NudesPhotoReport";
import NudesPhotoComments from "./photoLightBoxButtons/NudesPhotoComments";
import InfiniteScroll from "./infiniteScroll/index";

export const LeakedNudesPageGrid = (
    {
        leakedNudesData,
        setLeakedNudesData,
        loading,
        gridContentMobileTwoColumn,
        chunkSize,
        nudesDataInfoSplicePartOne,
        nudesDataInfoSplicePartTwo,
        width,
    }) => {

    const auth = useContext(AuthContext)
    const [photoIndex, setPhotoIndex] = useState(0)
    const [currentTargetNudePhoto, setCurrentTargetNudePhoto] = useState(null)
    const [openPhotoLightbox, setOpenPhotoLightbox] = useState(false)
    const [leaked, setLeaked] = useState([])
    const [lightBoxEndList, setLightBoxEndList] = useState(false)

    const animationTimeOut = 600;

    const fetchLeakedNudes = () => {
        setLeakedNudesData({
            offset: leakedNudesData.offset+=chunkSize,
            leaked_total: leakedNudesData.leaked_total,
            data: leakedNudesData.data
        })
    }

    const setOverflowHidden = () => {
        window.document.getElementById('html').style.overflow = "hidden"
    }

    const getPhotoIndex = (currentTargetNudePhoto) => {
        for(let i = 0;i < leaked.length; i++){
            if(leaked[i]._id === currentTargetNudePhoto._id){
                return i
            }
        }
        return 0
    }

    useEffect(() => {
        if(currentTargetNudePhoto) {
            setPhotoIndex(getPhotoIndex(currentTargetNudePhoto))
        }
    }, [currentTargetNudePhoto])

    useEffect(() => {
        if(leakedNudesData.data?.length > 0) {
            setLeaked(leakedNudesData.data)
        }
    }, [leakedNudesData.data])

    useEffect(() => {
        let content = window.document.getElementById('html')

        if (!openPhotoLightbox) {
            content.style.overflow = "unset"
        }

    }, [openPhotoLightbox])

    useEffect(() => {
        if(loading && openPhotoLightbox && lightBoxEndList){
            window.showLoader()
        }

        if(!loading && openPhotoLightbox && lightBoxEndList){
            if(leaked.length > photoIndex+1) {
                setTimeout(window.hideLoader(), 1000)
                setPhotoIndex(photoIndex + 1)
                setLightBoxEndList(false)
            }
        }


    },[loading, openPhotoLightbox, lightBoxEndList, leaked])

    return (
        <div>
            {openPhotoLightbox && (
                <Lightbox
                    mainSrc={leaked[photoIndex]?.imgLink}
                    nextSrc={leaked[(photoIndex + 1) % leaked.length]?.imgLink}
                    prevSrc={leaked[(photoIndex + leaked.length - 1) % leaked.length]?.imgLink}
                    enableZoom={false}
                    onAfterOpen={setOverflowHidden}
                    onCloseRequest={() => {
                        setOpenPhotoLightbox(false)
                        setCurrentTargetNudePhoto(null)
                    }}
                    onMovePrevRequest={() => {
                        setPhotoIndex((photoIndex + leaked.length - 1) % leaked.length)
                    }
                    }
                    onMoveNextRequest={() => {
                        if(photoIndex+1 === leaked.length && leaked.length < leakedNudesData.leaked_total){
                            setLightBoxEndList(true)
                            fetchLeakedNudes()
                        }else {
                            setPhotoIndex((photoIndex + 1) % leaked.length)
                        }
                    }
                    }
                    toolbarButtons={[
                        (<div style={{marginRight: "10px", color: "#9a9a9b"}}><div>{leaked[photoIndex]?.uploadDate.split(",")[0]}</div></div>),
                        <NudesPhotoReport setPhotoDataList={setLeaked} photoDataList={leaked} index={photoIndex} />,
                        <NudesPhotoComments setPhotoDataList={setLeaked} photoDataList={leaked} index={photoIndex} />,
                        <NudesPhotoHotPoints setPhotoDataList={setLeaked} photoDataList={leaked} index={photoIndex} />
                        ]
                    }
                />
            )}
            <InfiniteScroll
                inverse={false}
                dataLength={leaked.length}
                next={fetchLeakedNudes}
                hasMore={true}
                loader={loading && <div className={"mb-2"}><Loader/></div>}
            >
            <div id={"firstPart"} className="my-grid" style={{padding: "unset"}}>
                <div id="gridNudeLeaked" className={!gridContentMobileTwoColumn ? "my-container" : "my-container-mobile-two-column"}>
                    {nudesDataInfoSplicePartOne.length > 0 &&
                    nudesDataInfoSplicePartOne.map((photo, index) =>
                        <TransitionGroup appear={true} key={index} component={null}>
                        <CSSTransition
                            nodeRef={photo.nodeRef}
                            key={index}
                            timeout={{enter: animationTimeOut,exit: animationTimeOut}}
                            classNames="fade"
                        >
                            <div key={index} ref={photo.nodeRef} className="filtr-item my-card" style={{backgroundColor: "transparent"}}>
                                    <LeakedNudesPagePhotoContent
                                        setCurrentTargetNudePhoto={setCurrentTargetNudePhoto}
                                        gridContentMobileTwoColumn={gridContentMobileTwoColumn}
                                        setOpenPhotoLightbox={setOpenPhotoLightbox}
                                        photo={photo}
                                    />
                            </div>
                        </CSSTransition>
                        </TransitionGroup>
                       )
                    }
                    <MainGridMockProfiles profilesInfo={nudesDataInfoSplicePartOne}/>
                    <div style={leaked.length === 0 && !loading ? {
                        display: "block",
                        color: "#aeaeae"
                    } : {display: "none"}}>{"NO NUDES PHOTO"}</div>
                </div>
            </div>
            <BannerAdMiddle width={width}/>
            <div id={"secondPart"} className="my-grid" style={{padding: "unset"}}>
                <div id="gridNudeLeaked" className={!gridContentMobileTwoColumn ? "my-container" : "my-container-mobile-two-column"}>
                    {nudesDataInfoSplicePartTwo.length > 0 &&
                    nudesDataInfoSplicePartTwo.map((photo, index) =>
                        <TransitionGroup appear={true} key={index} component={null}>
                            <CSSTransition
                                nodeRef={photo.nodeRef}
                                key={index}
                                timeout={{enter: animationTimeOut,exit: animationTimeOut}}
                                classNames="fade"
                            >
                                <div key={index} ref={photo.nodeRef} className="filtr-item my-card" style={{backgroundColor: "transparent"}}>
                                    <LeakedNudesPagePhotoContent
                                        setCurrentTargetNudePhoto={setCurrentTargetNudePhoto}
                                        gridContentMobileTwoColumn={gridContentMobileTwoColumn}
                                        setOpenPhotoLightbox={setOpenPhotoLightbox}
                                        photo={photo}
                                    />
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    )
                    }
                    <MainGridMockProfiles profilesInfo={nudesDataInfoSplicePartTwo}/>
                </div>
            </div>
            </InfiniteScroll>
        </div>
    )
}