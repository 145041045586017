import React, {useCallback, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import fetch from "node-fetch";


export const useHttp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const request = useCallback(async (
        url,
        method = 'GET',
        body = null,
        headers = {},
        isBodyFile = false
        ) => {

            setLoading(true)

            try {
                if (body) {
                    if (!isBodyFile) {
                        body = JSON.stringify(body)
                        headers['Content-Type'] = 'application/json'
                        headers['Accept'] = 'application/json'
                    } else {
                        //headers['Content-Type'] = 'multipart/form-data'
                        headers['Accept'] = 'application/json'
                    }
                }

                const response = await fetch(url, {
                    method, body, headers
                })

                const data = await response.json()

                if (!response.ok) {
                    if (typeof data.error === 'undefined') {
                        throw new Error(data.message)
                    } else {
                        throw new Error(data.error.message)
                    }
                }

                setLoading(false)
                return data;
            } catch (e) {
                setError(e.message)
                setLoading(false)
            }
        }
    ,
        []
    )

    const clearError = useCallback(() => setError(false), [])

    return {loading, request, error, clearError}
}