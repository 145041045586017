import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {Loader} from "../../components/Loader";
import {appendHtmlContent, redirectToPage} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";
import sortArray from "sort-array";
import {AlertCardComponent} from "../../components/AlertCardComponent";


export const BlogPageList = (props) => {

    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const [blogs, setBlogs] = useState([])

    const redirectOnBlog = (blogAlias) => {
        //event.preventDefault()
        redirectToPage('/blog/' + blogAlias)
    }

    const getBlogs = useCallback(async () => {
        try {
            const blogs = await request(
                `/api/blog/list/all`,
                'GET',
                null
            )

            let sortedBlogsPined = sortArray(blogs.blogs.filter(blog => {
                return blog.pinned
            }), {
                by: 'publicationDate',
                order: 'desc'
            })

            let sortedBlogs = sortArray(blogs.blogs.filter(blog => {
                return !blog.pinned
            }), {
                by: 'publicationDate',
                order: 'desc'
            })

            setBlogs([...sortedBlogsPined,...sortedBlogs])
            window.scrollTo(0, 0)
        } catch (e) {

        }
    }, [request])

    useEffect(() => {
        getBlogs()
    }, [])

    useEffect(() => {
        if (blogs.length > 0) {
            blogs.map((blog, index) => {
                appendHtmlContent("#blogMainContent" + index, blog.blogPreviewDescription)
            })
        }
    }, [blogs])

    useEffect(() => {
        message(error, 'error',"/")
        clearError();
    }, [error, message, clearError])

    return (
        <section className="py-5" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container" style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                {blogs.length > 0 ? blogs.map((blog, index) =>
                    <div className={"card"} style={{
                        padding: "20px", display: "flex", height: "100%", color: "black",
                        backgroundColor: "white",
                        width: '100%',
                        marginBottom: "20px",
                        borderRadius: "12px"
                    }}>
                        <div style={{display: blog.pinned ? "block" : "none"}}>
                            <div style={{
                                transform: "rotate(45deg)",
                                display: "flex",
                                justifyContent: "center",
                                position: "absolute",
                                right: "20px",
                                color: "#773571",
                            }}>
                                <i className="fas fa-thumbtack"/>
                            </div>
                        </div>
                        <div style={{width: '100%'}}>
                            <div>
                                <h2>{blog.blogPreviewTitle}</h2>
                            </div>
                            <div style={{color: "#0000008f"}} className="mb-3">
                                {new Date(blog.publicationDate).toLocaleDateString("en-US", {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </div>
                            <div id={"blogMainContent" + index} className=""/>
                            <div style={{
                                display: "flex",
                                justifyContent: "end"
                            }}>
                                <button
                                    onClick={
                                        () => {
                                            redirectOnBlog(blog.blogAlias)
                                        }
                                    }
                                    className={"btn btn-primary"}
                                >
                                    Read More
                                </button>
                            </div>
                        </div>
                    </div>)
                    :
                   <AlertCardComponent textAlert={"No publication yet"}/>
                }
            </div>
        </section>
    )
}