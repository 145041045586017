import React, {useEffect} from 'react';

export const MainPageMap = (
    {
        loading,
        profilesInfo,
        setCurrentTargetProfile,
        setShowProfilePreview,
    }) => {

    useEffect(() => {
        window.initMap()
    }, [])

    useEffect(() => {
        if (profilesInfo.length > 0) {
            profilesInfo = profilesInfo.filter(prof => {return prof.city !== null && prof.latitude !== null});
            window.clearMarkers()
            window.addMarkers(profilesInfo, setCurrentTargetProfile, setShowProfilePreview)
        }else if(profilesInfo.length === 0){
            window.clearMarkers()
        }
    }, [profilesInfo])

    return (
        <div className={"main-page-container-map"}>
            <div style={{color: "white", marginTop: "15px"}}>
                <span>This map displays the most recently posted Kik & Snapchat usernames. If you want your profile to be included on the map, make sure to <a style={{color: "#0d6efd"}} href="https://kikflirt.com/post-profile">post</a> it and be sure to include your location. Don't miss out on the chance to be featured on the map!</span>
            </div>
            <div id="map">
                <div style={loading ? {position: "inherit", height: "600px", zIndex: "999", backgroundColor: "rgb(223 224 229 / 53%)"} : {display: "none"}}>
                    <div className="d-flex justify-content-center" style={{alignItems: "center", height: "inherit"}}>
                    <div className="spinner-border" style={{width: "3rem", height: "3rem", color: "#ae419f"}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}