import {useEffect, useState} from 'react';
import {ClientJS} from 'clientjs';

export default function useClientDeviceInfo() {
    const [clientDeviceInfo, setClientDeviceInfo] = useState({});

    useEffect( () => {
        const client = new ClientJS();
        setClientDeviceInfo(
            {
                fingerprint: client.getFingerprint(),
                browserData: client.getBrowserData(),
                userAgent: client.getUserAgent(),
                timezone: client.getTimeZone(),
                language: client.getLanguage(),
                systemLanguage: client.getSystemLanguage()
            })
    }, []);

    return clientDeviceInfo;
}
