import React, {useEffect} from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {getDroppedOrSelectedFiles} from 'html5-file-selector'

const FileUploadComponent = ({
                setFilesData,
                filesData,
                maxFiles=30,
                buttonText='Drag \'n\' drop some photos here, or click to select files.',
                buttonStyle ={margin: "10px"},
                maxFilesText ='Photos slots left ',
                acceptedFormats = ".jpg, .jpeg, .png",
                dropZoneInputText = "Drop A File",
                minValidationImgWidth = 250,
                minValidationImgHeight = 250,
                dropZonesStyle = {
                    dropzone: {height: 400, overflow: "auto", borderStyle: "dashed"},
                    preview: {padding: "8px 3%", minHeight: "fit-content"},
                    previewImage: {maxHeight: "150px"},
                    dropzoneActive: {borderColor: 'green'},
                }
}) => {

    const validationFile = (fileWithMeta, status) => {
        const {meta, file} = fileWithMeta
        let width = meta.width
        let height = meta.height
        if (width < minValidationImgWidth || height < minValidationImgHeight) {
            return "Invalid size"
        }
        return false
    }

    const filesSelectHandlerV2 = ({meta, file}, status) => {
        let id = meta.id
        if (status === "done") {
            setFilesData(filesData => [...filesData, {id: id, file: file}])
        }
        if (status === "removed") {
            let fileDataWithRemoved = filesData.filter(el => {
                return el.id !== id
            })
            setFilesData(fileDataWithRemoved)
        }
    }

    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }

    useEffect(() => {
        setFilesData([])
    }, [])


    const selectFileInput = ({accept, onFiles, files, getFilesFromEvent}) => {
        const textMsg = files.length > 0 ? 'Add more' : buttonText
        return (
            <div style={{textAlign: "center", display: "contents"}}>
                <span>{maxFilesText}{maxFiles - filesData.length}</span>
                <label className="btn btn-primary mt-4" style={buttonStyle}>
                    {textMsg}
                    <input
                        id={"upload-photo-input"}
                        style={{display: 'none'}}
                        type="file"
                        accept={accept}
                        multiple
                        onChange={e => {
                            getFilesFromEvent(e).then(chosenFiles => {
                                onFiles(chosenFiles)
                            })
                        }}
                    />
                </label>
            </div>
        )
    }
    return (
        <Dropzone
            onChangeStatus={filesSelectHandlerV2}
            InputComponent={selectFileInput}
            getFilesFromEvent={getFilesFromEvent}
            accept={acceptedFormats}
            maxFiles={maxFiles}
            validate={validationFile}
            inputContent={dropZoneInputText}
            styles={dropZonesStyle}
        />
    );
};
export default FileUploadComponent;