import React, {createRef, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/auth.context";
import {Modal, Popover} from "react-bootstrap";
import 'material-icons/iconfont/material-icons.css';
import 'react-image-lightbox/style.css';
import {useMessage} from "../hooks/message.hook";
import FormData from "form-data";
import FileUploadComponent from "../components/FileUploadComponent";
import AdBlockDetector from "../components/AdBlockDetector";
import BannerAdTop from "../components/ad/BannerAdTop";
import useWindowDimensions from "../hooks/window_dimensions.hook";
import {useDetectAdBlock} from "adblock-detect-react";
import {LeakedNudesPageGrid} from "../components/LeakedNudesPageGrid";
import {filterData, SearchType} from "filter-data";

export const LeakedNudes = () => {
    //const adBlockDetected = useDetectAdBlock();
    const auth = useContext(AuthContext)
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const [showUploadNudeModal, setShowUploadNudeModal] = useState(false);
    const [nudesDataInfoSplicePartOne, setNudesDataInfoSplicePartOne] = useState([])
    const [nudesDataInfoSplicePartTwo, setNudesDataInfoSplicePartTwo] = useState([])
    const [gridContentMobileTwoColumn, setGridContentMobileTwoColumn] = useState(false)
    const chunkSize = 20;
    const displayOffsetFirstPart = 8;
    const {width} = useWindowDimensions();

    const [leakedNudesData, setLeakedNudesData] = useState({
        offset: 0,
        leaked_total: 0,
        data: []
    })

    const getLeakedNudesV2 = async (offset) => {
        try {

            const leakedNudesDataResponse = await request(
                `/api/nudes/get_leaked_nudes/v2`,
                'POST',
                {
                    offset: offset,
                    chunkSize: chunkSize
                }
            );

            if(leakedNudesDataResponse.leakedNudeList.length > 0) {
                setLeakedNudesData({
                    offset: offset,
                    leaked_total: leakedNudesDataResponse.leaked_total,
                    data: [...leakedNudesData.data, ...leakedNudesDataResponse.leakedNudeList]
                })
            }

        } catch (e) {

        }
    }

    const [filesData, setFilesData] = useState([])

    const uploadPhoto = async (event) => {
        if (event) {
            event.preventDefault()
        }

        let filesOnly = filesData.map(el => {
            return el.file
        });
        let filesDataWithFileKey = {files: filesOnly}

        try {
            const formDataForValidationFile = new FormData();

            const fileArray = [...filesDataWithFileKey.files];

            for (let fileIndex = 0; fileIndex < fileArray.length; fileIndex++) {
                formDataForValidationFile.append(
                    "files",
                    filesDataWithFileKey.files[fileIndex]
                );
            }

            const validationImg = await request(
                '/api/file/images_validation',
                'POST',
                formDataForValidationFile,
                {Authorization: `Bearer ${auth.token}`},
                true
            )

            if (!validationImg) {
                throw new Error("Ошибка валидации фотографии профиля.");
            }

            message(validationImg.message, "success")

            //upload img
            const imgNames = [];
            for (let fileIndex = 0; fileIndex < fileArray.length; fileIndex++) {
                const response = await fileUploadHandler(filesDataWithFileKey.files[fileIndex])
                    .then(response => {
                        return response;
                    })
                imgNames.push(response.imgName);
            }

            //update leaked photo
            const uploadLeaked = await request(
                '/api/nudes/upload_nudes',
                'POST',
                {imgNames: imgNames, owner: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )

            setFilesData([])
            message(uploadLeaked.message, "success","/leaked-nudes")
            handleClose()
        } catch (e) {
            console.log(e)
        }
    }

    const fileUploadHandler = async (file) => {

        const formDataForUploadFile = new FormData();

        // Update the formData object
        formDataForUploadFile.append(
            "file",
            file
        );

        return await request(
            '/api/file/upload',
            'POST',
            formDataForUploadFile,
            {Authorization: `Bearer ${auth.token}`},
            true
        )
    }

    useEffect(() => {
        message(error, 'error')
        clearError();
    }, [error, message, clearError])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getLeakedNudesV2(leakedNudesData.offset)
    }, [leakedNudesData.offset])

    useEffect(() => {
        let originalData = [...leakedNudesData.data]

        originalData.map(photo => photo.nodeRef = createRef())

        if(originalData.length > displayOffsetFirstPart){
            setNudesDataInfoSplicePartOne(originalData.splice(0, displayOffsetFirstPart));
            setNudesDataInfoSplicePartTwo(originalData);
        }else{
            setNudesDataInfoSplicePartOne(originalData);
            setNudesDataInfoSplicePartTwo([]);
        }
        if (originalData.length > 0) {
            setTimeout(() => {
                window.updateProgress();
            }, 630);
        }
    }, [leakedNudesData.data])

    const handleClose = () => setShowUploadNudeModal(false);

    useEffect(() => {
        let content = window.document.getElementById('html')
        if (showUploadNudeModal) {
            content.style.overflow = "hidden"
        } else {
            content.style.overflow = "unset"
        }

    }, [showUploadNudeModal])

    useEffect(() => {

        if (360 < width < 420) {
            setGridContentMobileTwoColumn(true)
        }

        if (width > 420) {
            setGridContentMobileTwoColumn(false)
        }

        if (width < 360) {
            setGridContentMobileTwoColumn(false)
        }

    }, [width])

    return (
        <section className="py-3" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className={'container'} style={{textAlign: "center" ,display: "flex",color: "white", marginTop: "15px", justifyContent: "center"}}>
                <h2 style={{color: "white"}}>
                    KikFlirt`s leaked Snapchat & Kik nudes
                </h2>
            </div>
            <div className={'container'} style={{textAlign: "justify", display: "flex",color: "white", marginTop: "15px", paddingBottom: "18px"}}>
                <span>Our platform is the best place to see the hottest nude leaks. Snapchat and Kik is a popular choices for sexting because users believe that their photos will disappear almost immediately after being sent. But is this really accurate? Here we collect the most dirty and naughty video and photo leaks. Don't forget that you can share your sluts kik photo and snapchat clips rignt now! Check back daily for new updates!</span>
            </div>
            <div style={{display: "flex", justifyContent: "center", paddingBottom: "18px"}}>
                <button
                    className="btn btn-primary"
                    onClick={(event) => {
                        setShowUploadNudeModal(true)
                    }}
                >
                    <i className="fas fa-camera"/> Upload leaked nudes
                </button>
            </div>
                <div>
                    <BannerAdTop width={width}/>
                    <div className="section">
                        <div>
                            <LeakedNudesPageGrid
                                leakedNudesData={leakedNudesData}
                                setLeakedNudesData={setLeakedNudesData}
                                loading={loading}
                                gridContentMobileTwoColumn={gridContentMobileTwoColumn}
                                chunkSize={chunkSize}
                                nudesDataInfoSplicePartOne={nudesDataInfoSplicePartOne}
                                nudesDataInfoSplicePartTwo={nudesDataInfoSplicePartTwo}
                                width={width}
                            />
                        </div>
                    </div>
                </div>
            <Modal show={showUploadNudeModal} onHide={handleClose}>
                <Modal.Header style={{paddingBottom: "unset"}}>
                    <Modal.Title>
                        {auth.isAuthenticated ?
                            <h5>Upload leaked photos (PNG, JPG) | Min. size
                                250x250</h5>
                            :
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div id={"alert"} style={{color: "#533f03", border: "unset", width: "fit-content"}}
                                     className="alert alert-warning alert-dismissible fade show" role="alert">
                                    To upload, like or comments photos, publish a minimum of <a
                                    style={{textDecoration: "underline", color: "#614e27"}} href={"/post-profile"}
                                    className="alert-link">one profile.</a>
                                </div>
                            </div>
                        }
                    </Modal.Title>

                    <button style={{paddingBottom: "5px"}} type="button" className="btn-close btn-close-grey"
                            aria-label="Close" onClick={handleClose}>
                        <i className="fas fa-times"/>
                    </button>
                </Modal.Header>
                <Modal.Body style={{paddingTop: "unset"}}>
                    {auth.isAuthenticated &&
                    <form className="bootstrap-form-with-validation" style={{maxWidth: "600px"}}>
                        <div className="form-group">
                            <FileUploadComponent filesData={filesData} setFilesData={setFilesData}/>
                        </div>
                        <div className="form-group">
                            <button
                                className="btn btn-primary btn-block"
                                id="submit_btn"
                                onClick={(event) => {
                                    uploadPhoto(event)
                                }}
                                disabled={loading}
                            >
                                {loading ?
                                    <div className="spinner-grow spinner-grow-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <div>
                                        Upload
                                    </div>
                                }
                            </button>
                        </div>
                    </form>
                    }
                </Modal.Body>
            </Modal>
        </section>
    )
}