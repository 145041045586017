import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ToastContainer} from "react-toastify";
import {CookieConsent} from "react-cookie-consent";
import BannerAdBottomHiding from "./components/ad/BannerAdBottomHiding";
import AdultModal from "./components/modal/adult";

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

ReactDOM.render(
    <React.StrictMode>
        <ToastContainer position={'bottom-left'} draggable={true} draggablePercent={32} autoClose={2600}/>
    </React.StrictMode>,
    document.getElementById('toast')
);

ReactDOM.render(
    <React.StrictMode>
        <CookieConsent buttonStyle={{borderRadius: "10px"}}>This website uses cookies to enhance the user experience.</CookieConsent>
    </React.StrictMode>,
    document.getElementById('cookieConsent')
);

ReactDOM.render(
    <React.StrictMode>
        <AdultModal />
    </React.StrictMode>,
    document.getElementById('adult-modal')
);

// ReactDOM.render(
//     <React.StrictMode>
//         <BannerAdBottomHiding />
//     </React.StrictMode>,
//     document.getElementById('bottom-ads')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
