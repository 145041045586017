import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/auth.context";
import {redirectToPage} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";
import useClientDeviceInfo from "../../hooks/client_device_info.hook";



export const AccountEmailConfirmation =  (props) => {
    const auth = useContext(AuthContext)
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [confirmationMessageStyle, setConfirmationMessageStyle] = useState({margin: "unset", width: '100%', textAlign: 'center'})
    const clientInfo = useClientDeviceInfo()

    useEffect(() => {
        message(error,'error',"/")
        clearError();
    },[error,message,clearError])


    const confirmEvent = useCallback(async () => {
        if (props.match.path === "/confirm/:confirmationEmailCode") {
            if(Object.keys(clientInfo).length > 0) {
                try {
                    const data = await request(
                        `/api/auth/confirm/${props.match.params.confirmationEmailCode}`,
                        'POST',
                        {devicesInfo: clientInfo}
                    )
                    message(data.message, 'success', "/login")
                    setConfirmationMessageStyle({...confirmationMessageStyle, color: 'green'})
                    setConfirmationMessage(data.message)
                } catch (e) {
                    setConfirmationMessageStyle({...confirmationMessageStyle, color: 'red'})
                    setConfirmationMessage(e.message)
                }
            }
        }
    }, [auth, request, clientInfo])

    useEffect(() => {
        confirmEvent()
    }, [confirmEvent])

    return (
        <section className="py-5" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <div className={'card'} style={{display: "flex", height: "100px", justifyContent: "center", alignItems: "center"}}>
                    {loading ?
                        <div>

                        <p style={{margin: "unset", width: '100%', textAlign: 'center'}}>Confirmation. Pls wait...</p>
                        <div className="lds-roller mb-2">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        </div>
                        </div>
                        :
                        <div>
                            <p style={confirmationMessageStyle}>{confirmationMessage}</p>
                        </div>
                    }

                </div>
            </div>
        </section>
    )
}