import {useEffect, useCallback} from 'react';

export default function useScrollIntoViewById(id) {
    const scrollIntoViewFunction = useCallback(() => {
        const { hash } = window.location;
        if (hash !== '') {
            // Push onto callback queue so it runs after the DOM is updated,
            // this is required when navigating from a different page so that
            // the element is rendered on the page before trying to getElementById.
            id = hash.replace('#', '');
        }
        setTimeout(() => {
            const element = window.document.getElementById(id);
            if (element){
                element.scrollIntoView(true)
            }
        }, 1000);

    },[])

    useEffect(() => {
        scrollIntoViewFunction()
    }, [])
}