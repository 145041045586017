import React, {useEffect} from 'react'
import {redirectToPage} from "../scripts/Utils";

export const NavBarUnAuth = () => {

    const redirectOnLogin = event => {
        event.preventDefault()
        redirectToPage('/login')
    }

    useEffect(() => {
        window.initNavBarManager()
        window.initNavBarLogoAnimation()
    })

    const redirectOnHome = event => {
        event.preventDefault()
        redirectToPage('/')
    }

    const redirectOnPostProfile = event => {
        event.preventDefault()
        redirectToPage('/post-profile')
    }

    const redirectOnLeakedNudes = () => {
        redirectToPage("/leaked-nudes", true)
    }

    const redirectOnLocalUsernames = () => {
        redirectToPage("/local-usernames", true)
    }

    const redirectOnBlog = () => {
        redirectToPage("/blog", true)
    }

    const redirectOnContactUs = (event) => {
        redirectToPage('/contact_us', true)
    }


    const redirectOnContactUsRemoveUserNames = (event) => {
        redirectToPage("/contact_us/?subject=remove_username", true)
    }

    const redirectOnFAQ = (event) => {
        redirectToPage("/documents/faq", true)
    }

    return (
        <div>
            <span className="screen-darken"/>
            <nav id="navbar_main"
                 className="bg-nav-bar nav-bar-display-no mobile-offcanvas navbar navbar-expand-sm navbar-dark">
                <div className="container-fluid" style={{marginTop: "10px"}}>
                    <div className="offcanvas-header">
                        <button className="btn-close float-end" style={{display: "contents"}}><i className="fas fa-times"/></button>
                    </div>
                    <ul className="mobile navbar-nav ml-2 notification-badges-main-menu">
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnHome}> <i className="fas fa-home menu-icon"/> <span>Home</span> </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnPostProfile}>
                                <i className="fas fa-user-plus menu-icon"/>
                                <span>Post Profile</span> </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnLeakedNudes}>
                                <img src={"/assets/img/svg/kiss.svg"} style={{width: "20px", marginRight: "4px"}} alt={"kiss"}/>
                                <span>Leaked Nudes</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnBlog}>
                                <i className="fas fa-blog"/>
                                <span> Blog</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={redirectOnLocalUsernames}>
                                <i className="fas fa-map-marked-alt"/>
                                <span> Local Usernames</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={redirectOnContactUs}>
                                <i className="far fa-address-book"/>
                                <span> Contact Us</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={redirectOnContactUsRemoveUserNames}>
                                <i className="fas fa-user-minus"/>
                                <span> Remove Username</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={redirectOnFAQ}>
                                <i className="far fa-question-circle"/>
                                <span> FAQ</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnLogin}> <i className="fas fa-sign-in-alt menu-icon"/> <span>Sign In</span>
                            </a>
                        </li>
                        <li style={{
                            borderBottom: "1px solid white",
                            display: "block",
                            overflow: "hidden",
                            paddingBottom: "6px"
                        }}/>
                    </ul>
                    <div style={{fontSize: "xx-small", color: "white", marginTop: "16px"}}>
                        <p className="text-center">KikFlirt is not affiliated with, or sponsored or endorsed by, Kik Interactive, Inc or Snap Inc.</p>
                        <p className="copyright text-center">© 2024 KikFlirt. All rights reserved.</p>
                    </div>
                </div>
            </nav>

            <nav className="navbar navbar-expand-sm navbar-dark">
                <div className="logo-and-brand-mobile" onClick={redirectOnHome}>
                    <img src={"/assets/img/example3.png"} alt="Main Logo" className="image-logo"/>
                    <a className="navbar-brand" >
                        <span>Kik</span>
                        <div className="textcontainer">
                            <span className="particletext hearts flirttext">
                            <b>Flirt</b>
                           </span>
                        </div>
                    </a>
                </div>
                <button className="navbar-toggler x collapsed" type="button" data-trigger="navbar_main" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"/>
                    <span className="icon-bar"/>
                    <span className="icon-bar"/>
                </button>
                <div className="collapse navbar-collapse">
                    <ul className="desktop-navbar navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnBlog}>
                                <i className="fas fa-blog"/>
                                <span> Blog</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnLocalUsernames}>
                                <i className="fas fa-map-marked-alt"/>
                                <span> Local Usernames</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnLeakedNudes}>
                                <img src={"/assets/img/svg/kiss.svg"} style={{width: "20px", marginRight: "4px"}} alt={"kiss"}/>
                                <span>Leaked Nudes</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnHome}> <i className="fas fa-home menu-icon"/> <span>Home</span> </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnPostProfile}> <i className="fas fa-user-plus menu-icon"/> <span>Post Profile</span> </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"  onClick={redirectOnLogin}> <i className="fas fa-sign-in-alt menu-icon"/> <span>Sign In</span> </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}