const {useEffect} = require("react");

function useOutsideClick(ref, handler = null, exceptionsIdComponents = []) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if(!exceptionsIdComponents.includes(event.target.id)) {
                    if (handler) {
                        handler()
                    }
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default useOutsideClick;