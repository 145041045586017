import React, {useCallback, useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {redirectToPage} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";
import {AlertCardComponent} from "../../components/AlertCardComponent";


export const AccountPasswordRecovery =  (props) => {

    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const [disabledRecoveryForm, setDisabledRecoveryForm] = useState(null)

    useEffect(() => {
        message(error,'error')
        clearError();
    },[error,message,clearError])

    const [recoveryForm, setRecoveryForm] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        recoveryEmailCode: '',
    })

    const changeHandlerForRecoveryForm = event => {
        setRecoveryForm({...recoveryForm, [event.target.name]: event.target.value})
    }

    const confirmEvent = useCallback(async () => {
        if (props.match.path === "/recovery-password/:forgotPasswordCode") {
            try {
                const data = await request(
                    `/api/auth/recovery-password/${props.match.params.forgotPasswordCode}`,
                    'GET');
                setDisabledRecoveryForm(false)
                setRecoveryForm({...recoveryForm, email: data.userEmail, recoveryEmailCode: props.match.params.forgotPasswordCode})
            } catch (e) {
                setDisabledRecoveryForm(true)
            }
        }
    },[request])

    useEffect(() => {
        confirmEvent()
    },[confirmEvent])


    const recoveryPassword = async (event) => {
        if(event) {
            event.preventDefault()
        }
        try {
            const data = await request(
                '/api/account_settings/change-password',
                'POST',
                {...recoveryForm},
                {})
            if (data.code === 200) {
                message(data.message,'success','/login')
            }
        } catch (e) {
        }
    }

    return (
        <section className="py-0" style={{minHeight: '100%', minWidth: '100%'}}>
            {disabledRecoveryForm === null && (<AlertCardComponent
                textAlert={""}
                loading={true}
            />)}
            {disabledRecoveryForm === false &&
            (<div className="container">
                <section className="clean-block clean-form dark h-100">
                    <div className="container">
                        <div className="block-heading" style={{paddingTop: '0px'}}>
                            <h2 className="text-primary" style={{marginTop: '40px'}}> Password recovery<br/></h2>
                        </div>
                        <form>
                            <div className="form__group field mt-3">
                                <input className="form__field" type="password" autoComplete="off" required
                                       placeholder="Password" id={'password'} name="password"
                                       onChange={changeHandlerForRecoveryForm} disabled={disabledRecoveryForm}/>
                                <label htmlFor="password" className="form__label">Password</label>
                            </div>
                            <div className="form__group field mt-3">
                                <input className="form__field" type="password" autoComplete="off" required
                                       placeholder="Password" id={'password_confirmation'} name="password_confirmation"
                                       onChange={changeHandlerForRecoveryForm} disabled={disabledRecoveryForm}/>
                                <label htmlFor="password_confirmation" className="form__label">Password
                                    Confirmation</label>
                            </div>
                            <hr className={'mt-4'} style={{marginTop: '0px', marginBottom: '10px'}}/>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" onClick={(event) => {recoveryPassword(event)}}
                                        disabled={loading || disabledRecoveryForm}>
                                    {loading ?
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <div>
                                            SAVE
                                        </div>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>)}
            {disabledRecoveryForm === true && (<AlertCardComponent
                textAlert={"Invalid recovery password code"}
                textColor={"red"}
            />)}
        </section>
    )
}