import React, {useState} from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import OutsideClickHandler from 'react-outside-click-handler';
import "../styles/dropdown.css"

const Dropdown = ({value, setValue, options }) => {
    const [visible, setVisible] = useState(false);

    const handleClick = (value) => {
        setValue(value);
        setVisible(false);
    };

    return (
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
                className={visible ? "dropdown active" : "dropdown"}
            >
                <div className={"head"} onClick={() => setVisible(!visible)}>
                    <div className={"selection"}>{value}</div>
                    <div className={"arrow"}>
                        <IoIosArrowDown name="arrow-bottom" size="10" />
                    </div>
                </div>
                <div className={"body"}>
                    {options.map((x, index) => (
                        <div
                            className={x === value ? "option selectioned" : "option"}
                            onClick={() => handleClick(x, index)}
                            key={index}
                        >
                            {x}
                        </div>
                    ))}
                </div>
            </div>
        </OutsideClickHandler>
    );
};

export default Dropdown;