import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../context/auth.context";
import {Overlay, Popover, Tooltip} from "react-bootstrap";
import {useMessage} from "../../hooks/message.hook";
import {useHttp} from "../../hooks/http.hook";


const NudesPhotoHotPoints = ({
    photoDataList,
    setPhotoDataList,
    index
}) => {

    const auth = useContext(AuthContext)
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()

    const targetHotPoint = useRef(null);
    const [hotPointLimitTooltip, setHotPointLimitTooltip] = useState({
        value: "+1"
    });
    const [overlayHotPointShow, setOverlayHotPointShow] = useState(false)

    const [targetPhoto, setTargetPhoto] = useState(photoDataList[index]);

    useEffect(() => {
        setTargetPhoto(photoDataList[index])

    },[index])

    const hotPointHandler = async (event, photoId) => {
        targetHotPoint.current = event.currentTarget;
        try {
            const hotPointEvent = await request(
                '/api/nudes/addHotPoint',
                'POST',
                {photoId: photoId, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )
            if (hotPointEvent) {
                setOverlayHotPointShow(true);
                setTimeout(() => {
                    setOverlayHotPointShow(false);
                }, 1000);
                window.addBouncyToHotPointBtn(targetHotPoint.current);
                if (typeof hotPointEvent.error !== "undefined") {
                    setHotPointLimitTooltip({
                            ...hotPointLimitTooltip, value: "Hot point limit left: " +
                            hotPointEvent.error.hotPointLimitLeftHours + "h " +
                            hotPointEvent.error.hotPointLimitLeftMinutes + "m " +
                            hotPointEvent.error.hotPointLimitLeftSeconds + "s"
                        }
                    )
                } else {
                    setHotPointLimitTooltip({...hotPointLimitTooltip, value: "+1"})
                }
            }
            await updateClientHotPointClickStatus(photoId)
        } catch (e) {
            console.log(e.message)
        }

    }

    const updateClientHotPointClickStatus = async (photoId) => {
        try {
            const hotPointClickStatus = await request(
                '/api/nudes/updateHotPointBlockStatus',
                'POST',
                {photoId: photoId, userId: auth.userId},
                {Authorization: `Bearer ${auth.token}`}
            )
            if (hotPointClickStatus) {
                if (hotPointClickStatus.photo) {
                    photoDataList[index] = hotPointClickStatus.photo
                    setPhotoDataList(photoDataList)
                    setTargetPhoto(photoDataList[index])
                }
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    return (
        targetPhoto &&
        <div data-toggle={"tooltip"}
             title={"For like or comments photos publish a minimum of one profile"}>
            <Overlay
                target={targetHotPoint.current}
                show={overlayHotPointShow}
                placement="bottom"
            >
                <Tooltip id="hot-btn-tooltip">{hotPointLimitTooltip.value}</Tooltip>
            </Overlay>
            <div id="hot-photo"
                 style={{cursor: "pointer"}}
                 className={
                     auth.isAuthenticated ?
                     targetPhoto.hotPointsHistory.find(el => {
                         return el.userId === auth.userId
                     }) ?
                     targetPhoto.hotPointsHistory.find(el => {
                         return el.userId === auth.userId
                     }).isBlocked ?
                         "rounded-3 fire-color" :
                         "rounded-3" :
                         "rounded-3" :
                         "rounded-3 custom-disabled"
                 }
                 onClick={(event) => {
                     hotPointHandler(event, targetPhoto._id)
                 }
                 }
            >
                <div style={{display: "flow-root"}}>
                <i className="fas fa-fire"/>
                <span> {targetPhoto.hotPoints}</span>
                </div>
            </div>
        </div>
    )
};
export default NudesPhotoHotPoints