import React, {useEffect, useState} from 'react'
import {useHttp} from "../hooks/http.hook";
import {redirectToPage} from "../scripts/Utils";
import {useMessage} from "../hooks/message.hook";
import useClientDeviceInfo from "../hooks/client_device_info.hook";

export const RegistrationPage = () => {

    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const clientInfo = useClientDeviceInfo()

    const [registerForm, setRegisterForm] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        terms: false
    })

    const [terms, setTerms] = useState(false)

    useEffect(() => {
        message(error,'error')
        clearError();
    },[error,message,clearError])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const redirectOnLogin = event => {
        if(typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage('/login')
    }

    const redirectOnTerms = event => {
        if(typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage("/documents/terms_of_use", true)
    }


    const changeHandlerForRegistration = event => {
        setRegisterForm({...registerForm, [event.target.name]: event.target.value})
    }

    const changeHandlerForTerms = event => {
        setTerms(!terms)
    }

    const registerHandler = async (event) => {
        if(event) {
            event.preventDefault()
        }

        try {
            const data = await request(
                '/api/auth/register',
                'POST',
                {...registerForm, ...{devicesInfo: clientInfo}}
                )
            if (data.code === 201) {
                message(data.message,'success',"/login")
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        setRegisterForm({...registerForm,...{terms: terms}});
    }, [terms])


    return (
        <section className="py-0" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <section className="clean-block clean-form dark h-100">
                    <div className="container">
                        <div className="block-heading" style={{paddingTop: '0px'}}>
                            <h2 className="text-primary" style={{marginTop: '40px'}}>Sign Up To KikFlirt<br/></h2>
                        </div>
                        <form>
                            <div className="form__group field">
                                <input className="form__field" type="email" autoComplete="off" required
                                       placeholder="Email" id={'email'} name="email"
                                       onChange={changeHandlerForRegistration}/>
                                <label htmlFor="email" className="form__label">Email</label>
                            </div>
                            <div className="form__group field mt-3">
                                <input className="form__field" type="password" autoComplete="off" required
                                       placeholder="Password" id={'password'} name="password"
                                       onChange={changeHandlerForRegistration}/>
                                <label htmlFor="password" className="form__label">Password</label>
                            </div>
                            <div className="form__group field mt-3">
                                <input className="form__field" type="password" autoComplete="off" required
                                       placeholder="Password" id={'password_confirmation'} name="password_confirmation"
                                       onChange={changeHandlerForRegistration}/>
                                <label htmlFor="password_confirmation" className="form__label">Password
                                    Confirmation</label>
                            </div>
                            <div className="form-group mt-5" style={{alignItems: "center"}}>
                                <label className="cursor-pointer label">
                                    <span className="label-text">I agree with the
                                        <a  style={{cursor: "pointer"}} className="text-sm ml-1" onClick={(event) => redirectOnTerms(event)}>Terms</a>
                                    </span>
                                    <div style={{display: 'inline'}}>
                                        <input className="form-check-input ml-2" name="terms" type="checkbox" required
                                               onChange={changeHandlerForTerms}/>
                                        <span className="checkbox-mark"/>
                                    </div>
                                </label></div>
                            <hr style={{marginTop: '0px', marginBottom: '10px'}}/>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" onClick={(event) => {registerHandler(event)}}
                                        disabled={loading}>
                                    {loading ?
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <div>
                                        <i className="fas fa-sign-in-alt"/>
                                            &nbsp;SIGN UP
                                        </div>
                                    }
                                </button>
                            </div>
                            <div className="form-group">
                                <p style={{textAlign: 'center'}}>Already have an account?<br/>
                                    <a className="text-sm" href="" onClick={redirectOnLogin}>Sign In</a>
                                </p>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </section>
    )
}