import React, {useCallback, useContext, useEffect, useState} from 'react'
import 'materialize-css';
import '../../../node_modules/hover.css/css/hover.css'
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/auth.context";
import FormData from "form-data";
import FileUploadComponent from "../../components/FileUploadComponent";
import "@lottiefiles/lottie-player";
import {redirectToPage} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";
import ReactSelect, {createFilter} from "react-select";
import ReactSelectCustomOptions from "../../components/ReactSelectCustomOptions";
import CustomMenuList from "../../components/CustomMenuList";


export const AccountPostProfile = (callback, deps) => {

    const auth = useContext(AuthContext)
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    let fullFormData = {};
    const [formData, setFormData] = useState({
        accountId: auth.userId,
        app: '',
        profile_name: '',
        age: '18',
        sex: 'girl',
        orientation: '',
        profile_type: '',
        acceptProfileTerms: '',
        files: [],
    })
    const [countryData, setCountryData] = useState({
        country_short_name: 'us',
        country_full_name: 'United States',
    })

    const [filesData, setFilesData] = useState([])
    const [reactSelectLoading, setReactSelectLoading] = useState(false)
    const [city, setCity] = useState({value: null, label: "Select..", latitude: null, longitude: null})
    const [cityList, setCityList] = useState([])
    const [haveClientPremiumProfilesData, setHaveClientPremiumProfilesData] = useState(null)

    const handleChangeCity = (city) => {
        setCity(city);
    }

    const redirectOnTermsOfUse = event => {
        if (typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage("/documents/terms_of_use", true)
    }

    const redirectOnPrivacyPolicy = event => {
        if (typeof event !== "undefined") {
            event.preventDefault()
        }
        redirectToPage("/documents/privacy_policy", true)
    }

    const changeHandlerForFormData = event => {
        setFormData({...formData, [event.target.name]: event.target.value})
    }

    const buildFullFormData = () => {
        let aboutMe = getScEditorValue();
        let filesOnly = filesData.map(el => {
            return el.file
        });
        fullFormData = {...formData, ...{files: filesOnly}, ...countryData, ...{about_me: aboutMe}, ...{city: city.value}, ...{latitude: city.latitude}, ...{longitude: city.longitude}}
    }

    const fileUploadHandler = async (file) => {

        const formDataForUploadFile = new FormData();

        // Update the formData object
        formDataForUploadFile.append(
            "file",
            file
        );

        const data = await request(
            '/api/file/upload',
            'POST',
            formDataForUploadFile,
            {Authorization: `Bearer ${auth.token}`},
            true
        );

        return data
    }

    const postProfileHandler = async (event) => {
        if (event) {
            event.preventDefault()
        }
        try {
            buildFullFormData()
            const validationFields = await request(
                '/api/profile/post_profile_validation',
                'POST',
                {...fullFormData},
                {Authorization: `Bearer ${auth.token}`}
            )

            if (!validationFields) {
                throw new Error("Ошибка валидации полей профиля.");
            }

            message(validationFields.message, "success")

            const formDataForValidationFile = new FormData();

            const fileArray = [...fullFormData.files];

            for (let fileIndex = 0; fileIndex < fileArray.length; fileIndex++) {
                formDataForValidationFile.append(
                    "files",
                    fullFormData.files[fileIndex]
                );
            }

            const validationImg = await request(
                '/api/file/images_validation',
                'POST',
                formDataForValidationFile,
                {Authorization: `Bearer ${auth.token}`},
                true
            )

            if (!validationImg) {
                throw new Error("Ошибка валидации фотографии профиля.");
            }

            message(validationImg.message, "success")

            //upload img
            const imgNames = [];
            for (let fileIndex = 0; fileIndex < fileArray.length; fileIndex++) {
                const response = await fileUploadHandler(fullFormData.files[fileIndex], true)
                    .then(response => {
                        return response;
                    })
                imgNames.push(response.imgName);
            }

            //post profile
            const postProfile = await request(
                '/api/profile/post_profile',
                'POST',
                {...fullFormData, ...{imgNames: imgNames}},
                {Authorization: `Bearer ${auth.token}`}
            )

            message(postProfile.message, "success", "/profile/" + postProfile.profileId)
        } catch (e) {

        }
    }

    const haveClientPremiumProfiles = async () => {

        try {
            const data = await request(
                `/api/profile/haveClientPremiumProfiles/?userId=${auth.userId}`,
                'GET',
                null,
                {Authorization: `Bearer ${auth.token}`}
            )
            if (data) {
                setHaveClientPremiumProfilesData(data.haveClientPremiumProfiles)
            }
        } catch (e) {

        }
    }


    const changeHandlerForCountrySelector = useCallback(() => {
        let short = window.getSelectedCountry().iso2
        let full = window.getSelectedCountry().name;
        if (short !== countryData.country_short_name && full !== countryData.country_full_name) {
            setCountryData({country_short_name: short, country_full_name: full})
        }
    })

    useEffect(() => {
        const fetchData = async () => {
            setReactSelectLoading(true)
            let cityList = await request('/api/cities/city_list/?country_name_short=' + countryData.country_short_name.toUpperCase(), 'GET')
            if (cityList && cityList.cityList) {
                let cityListMappedName = cityList.cityList.map(el => {
                    return {value: el.name, label: el.name, latitude: el.latitude, longitude: el.longitude}
                })
                setCityList(cityListMappedName)
                setCity({value: null, label: "Select..", latitude: null, longitude: null})
            } else {
                setCityList([])
                setCity({value: null, label: "Select..", latitude: null, longitude: null})
            }
            setReactSelectLoading(false)
        }
        fetchData()
    },[countryData])

    const changeHandlerForOrientation = useCallback(() => {
        let orientation = document.getElementById('orientation').value
        setFormData({...formData, orientation: orientation})
    })

    const changeHandlerForProfileTerms = useCallback(() => {
        let acceptProfileTerms = document.getElementById('acceptProfileTerms').value
        if (acceptProfileTerms === 'true') {
            setFormData({...formData, acceptProfileTerms: false})
        } else {
            setFormData({...formData, acceptProfileTerms: true})
        }
    })


    const changeHandlerForProfileType = useCallback(() => {
        let profileType = document.getElementById('profile-type').value
        setFormData({...formData, profile_type: profileType})
    })

    const getScEditorValue = () => {
        return window.getScEditor().instance(window.document.getElementById('textarea-input')).val();
    }

    useEffect(() => {
        message(error, 'error')
        clearError();
    }, [error, message, clearError])

    useEffect(() => {
        window.initPostProfileScript()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(auth.userId) {
            haveClientPremiumProfiles()
            setFormData({...formData, accountId: auth.userId})
        }
    }, [auth])

    useEffect(() => {
        if (haveClientPremiumProfilesData !== null) {
            window.initScEditorTextArea(window.document.getElementById('textarea-input'), haveClientPremiumProfilesData)
        }
    }, [haveClientPremiumProfilesData])

    return (
        <section className="py-3" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <div className="container" style={{maxWidth: '600px', marginTop: '20px'}}>
                    <div className="block-heading" style={{paddingTop: '0px', textAlign: 'center'}}>
                        <h2 className="text-center text-primary">Post Your Profile</h2>
                    </div>
                    <section className="clean-block clean-form dark h-100">
                        <div className="row">
                            <div className="col">
                                <form id="form" className="bootstrap-form-with-validation"
                                      style={{maxWidth: '570px', paddingLeft: '22px', paddingRight: '22px'}}>
                                    <div className="form-group"><label htmlFor="text-input"><h5>App</h5></label>
                                        <select className="form-control" defaultValue={"select"} id="kik-app" name="app"
                                                onChange={changeHandlerForFormData} required>
                                            <option defaultValue={"select"}>
                                                Select your app
                                            </option>
                                            <option defaultValue={"snapchat"}>
                                                snapchat
                                            </option>
                                            <option defaultValue={"kik"}>
                                                kik
                                            </option>
                                        </select>
                                    </div>
                                    <div className="form-group"><label htmlFor="text-input"><h5>Username</h5>
                                    </label><input type="text" className="form-control" id="kik-username"
                                                   name="profile_name" onChange={changeHandlerForFormData}
                                                   autoComplete="off"/></div>
                                    <div className="col-md-5 col-sm-4 col-xs-5" style={{margin: '0', padding: '0'}}>
                                        <div className="form-group field-profileform-age required">
                                            <label className="control-label" htmlFor="profileform-age"/>
                                            <label htmlFor="text-input"><h5>Age</h5></label>
                                            <div className="input-group">
                                                <input type="number" id="profileform-age" className="form-control"
                                                       name="age" defaultValue={"18"} min="18" max="80" required=""
                                                       aria-required="true" onChange={changeHandlerForFormData}/>
                                                <div className="input-group-addon">yo</div>
                                            </div>
                                            <p className="help-block help-block-error"/>
                                        </div>
                                    </div>
                                    <div className="form-group" style={{display: 'grid'}}>
                                        <label htmlFor="text-input"><h5>Country</h5></label>
                                        <input className="form-control" id="country_selector" type="text"
                                               onFocus={changeHandlerForCountrySelector} onChange={changeHandlerForCountrySelector}/>
                                    </div>

                                    <div className="form-group">
                                        <div className={"dropdown"}>
                                        <h5 className={"label"}>City</h5>
                                        <ReactSelect
                                            value={city}
                                            options={cityList}
                                            onChange={handleChangeCity}
                                            filterOption={createFilter({ ignoreAccents: false })}
                                            captureMenuScroll={false}
                                            classNamePrefix="custom-select"
                                            components={{ Option: ReactSelectCustomOptions, MenuList: CustomMenuList }}
                                            isLoading={reactSelectLoading}
                                        />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="text-input"><h5>Gender</h5></label>
                                        <div className="form-check"><input type="radio" className="form-check-input"
                                                                           name="sex" id="guy" defaultValue={"guy"}
                                                                           onChange={changeHandlerForFormData}/><label
                                            className="form-check-label" htmlFor="guy"><i className="fas fa-mars"/> Guy</label>
                                        </div>
                                        <div className="form-check"><input type="radio" className="form-check-input"
                                                                           name="sex" defaultChecked={true} id="girl"
                                                                           defaultValue={"girl"}
                                                                           onChange={changeHandlerForFormData}/><label
                                            className="form-check-label" htmlFor="girl"><i
                                            className="fas fas fa-venus"/> Girl</label></div>
                                        <div className="form-check"><input type="radio" className="form-check-input"
                                                                           name="sex" id="trans" defaultValue={"trans"}
                                                                           onChange={changeHandlerForFormData}/><label
                                            className="form-check-label" htmlFor="trans"><i
                                            className="fas fas fa-transgender"/> Trans</label></div>
                                    </div>
                                    <div id="orientation-btns" className="form-group" style={{display: 'grid'}}>
                                        <label><h5>Orientation</h5></label>
                                        <div className="btn-tooltip" role="group">
                                            <button id="gay" type="button" className="marg-top btn btn-false mr-1"
                                                    onClick={changeHandlerForOrientation}>GAY
                                            </button>
                                            <button id="lesbian" type="button"
                                                    className="marg-top btn btn-false mr-1"
                                                    onClick={changeHandlerForOrientation}>LESBIAN
                                            </button>
                                            <button id="straight" type="button"
                                                    className="marg-top btn btn-false mr-1"
                                                    onClick={changeHandlerForOrientation}>STRAIGHT
                                            </button>
                                            <button id="bi" type="button" className="marg-top btn btn-false mr-1"
                                                    onClick={changeHandlerForOrientation}>BI
                                            </button>
                                            <button id="other" type="button" className="marg-top btn btn-false mr-1"
                                                    onClick={changeHandlerForOrientation}>OTHER
                                            </button>
                                        </div>
                                        <input id="orientation" hidden={true} name="orientation"/>
                                        <p id="orientation-validation-error" className="help-block help-block-error"/>
                                    </div>

                                    <div id="profile-type-btns" className="form-group" style={{display: 'grid'}}>
                                        <label><h5>Profile type</h5></label>
                                        <div className="btn-tooltip" role="group">
                                            <button id="clean" type="button" className="marg-top btn btn-false mr-1"
                                                    onClick={changeHandlerForProfileType}>CLEAN
                                            </button>
                                            <button id="dirty" type="button" className="marg-top btn btn-false"
                                                    onClick={changeHandlerForProfileType}>DIRTY
                                            </button>
                                        </div>
                                        <input id="profile-type" hidden={true} name="profile_type"/>
                                        <p id="orientation-validation-error" className="help-block help-block-error"/>
                                    </div>

                                    <div className="form-group">
                                        <label><h5>Profile photos (PNG, JPG) | Min. size 250x250</h5></label>
                                        <FileUploadComponent filesData={filesData} setFilesData={setFilesData}/>
                                    </div>

                                    <div id="togStatus" className="hint-block">
                                    </div>
                                    <div className="form-group">
                                        {(!haveClientPremiumProfilesData && false) &&
                                        <div className="alert alert-warning" role="alert"
                                             style={{display: "flex", alignItems: "center"}}>
                                            <i style={{fontSize: "30px", paddingRight: "14px"}}
                                               className="fas fa-exclamation-triangle">
                                            </i>
                                            <span>Animated Stickers available only if you have at least one <b>trusted</b> profile.
                                                <a href="#" className="alert-link"> Contact Us</a> for more info.
                                                </span>
                                        </div>
                                        }
                                        <label htmlFor="textarea-input"><h5>About Me</h5>
                                        </label>
                                        <textarea className="form-control" id="textarea-input" name="about_me"
                                                  required/>
                                    </div>


                                    <div className="form-group">
                                        <div className="col">
                                            <div className="form-group field-profileform-acceptterms">
                                                <div className="checkbox">
                                                    <label htmlFor="profileform-acceptterms">
                                                        <input type="text" hidden={true} name="acceptProfileTerms"
                                                               id={'acceptProfileTerms'} defaultValue={false} required/>
                                                        <input className="form-check-input" type="checkbox"
                                                               id="profileform-acceptterms"
                                                               onClick={changeHandlerForProfileTerms}/>
                                                        I confirm that I will not post adult content on clean/dirty/nsfw
                                                        that I am 18 years of age or older,
                                                        and that I have read and accept the
                                                        <a className={"text-sm ml-1"} style={{cursor: "pointer"}}
                                                           onClick={(event) => redirectOnTermsOfUse(event)}>Terms of
                                                            Use</a> and
                                                        <a className={"text-sm ml-1"} style={{cursor: "pointer"}}
                                                           onClick={(event) => redirectOnPrivacyPolicy(event)}>Privacy
                                                            Policy</a>.
                                                        Failure to follow these rules will result in your account being
                                                        banned.
                                                    </label>
                                                    <p className="help-block help-block-error"/>
                                                    <p className="help-block"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-block" id={"submit_btn"}
                                                onClick={(event) => {
                                                    postProfileHandler(event)
                                                }} disabled={loading}>
                                            {loading ?
                                                <div className="spinner-grow spinner-grow-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                :
                                                <div>
                                                    Post Profile
                                                </div>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
}