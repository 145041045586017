// custom pageview with the location from react router
export const pageView = path => {
    window.gtag('send', 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname
    });
}

// custom event with label being an optional parameter
export const gaSendEvent = (category, action, label = '') => {
    window.gtag('event', action, {
        'groups': category,
        'label': label
    });
}