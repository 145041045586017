import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/auth.context";
import {useHistory} from "react-router-dom"
import {Button, CloseButton, Modal} from "react-bootstrap";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {ImgPreloader} from "../../components/ImgPreloader";
import {Loader} from "../../components/Loader";
import sortArray from "sort-array";
import {redirectToPage} from "../../scripts/Utils";
import {useMessage} from "../../hooks/message.hook";

export const AccountProfileList = () => {

    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()
    const auth = useContext(AuthContext)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [targetPremium, setTargetPremium] = useState(false);
    const [currentProfileNickName, setCurrentProfileNickName] = useState("");
    const [currentProfileId, setCurrentProfileId] = useState(null);
    const [currentUserInfo, setCurrentUserInfo] = useState(null);
    const [pageTitle, setPageTitle] = useState("My Profiles");

    const [profilesInfo, setProfilesInfo] = useState({
        profiles: []
    })

    const handleDeleteClose = () => setShowDeleteModal(false);
    const handleDeleteShow = () => setShowDeleteModal(true);

    useEffect(() => {
        let content = window.document.getElementById('html')

        if (showDeleteModal) {
            content.style.overflow = "hidden"
        } else {
            content.style.overflow = "unset"
        }

    }, [showDeleteModal])

    useEffect(() => {
        message(error,'error')
        clearError();
    },[error,message,clearError])

    const redirectOnPostProfile = event => {
        event.preventDefault()
        redirectToPage("/post-profile")
    }

    const redirectOnEditProfilePage = (profileId) => {
        redirectToPage('/edit_profile/' + profileId)
    }

    const redirectOnProfilePage = (event, profileId) => {
        if (event.target.tagName.toLowerCase() === 'img') {
            redirectToPage('/profile/' + profileId)
        }
    }


    const getProfilesInfo = useCallback(async () => {
        const profilesList = await request(
            `/api/profile/get_profiles/${auth.userId}`,
            'GET',
            null,
            {Authorization: `Bearer ${auth.token}`},
            false
        );
        if (profilesList) {
            if (profilesList.profiles.length > 0) {
                let sortedProfs = sortArray(profilesList.profiles, {
                    by: 'profilePostDate',
                    order: 'desc'
                })
                setProfilesInfo({...profilesInfo, profiles: sortedProfs})
            }else{
                setProfilesInfo({profiles: []})
            }
        }

    }, [request, auth]);


    const getUserInfo = useCallback(async () => {
        const userInfo = await request(
            `/api/account_settings/get_account_info/${auth.userId}`,
            'GET',
            null,
            {Authorization: `Bearer ${auth.token}`},
            false
        );
        if (userInfo) {
            setCurrentUserInfo(userInfo.user)
        }

    }, [request, auth]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        window.initToolTip()
    }, [profilesInfo])

    useEffect(() => {
        getProfilesInfo()
    }, [getProfilesInfo])

    useEffect(() => {
        getUserInfo()
    }, [getUserInfo])

    useEffect(() => {
       if(profilesInfo.profiles.length >= 0 && currentUserInfo) {
           let totalSlots = currentUserInfo.profileSlots;
           setPageTitle(`My Profiles ${profilesInfo.profiles.length} / ${totalSlots}`)
       }
    }, [currentUserInfo,profilesInfo])

    const deleteProfile = async (profileId) => {
        try {
            const data = await request(
                '/api/profile_settings/delete_profile',
                'POST',
                {profile_id: profileId},
                {Authorization: `Bearer ${auth.token}`}
            )
            message(data.message,'success')
            await getProfilesInfo()
        } catch (e) {

        }
    }

    return (
        <section className="py-5" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <h2 className="text-center mb-1 text-primary">{pageTitle}</h2>
                <div className="my-grid">
                    <TransitionGroup id="grid" className="my-container" component="div">
                        <div id="verified" className="add-new-profile-container clicked"
                             onClick={redirectOnPostProfile}>
                            <div className="my-content add-new-profile-card">
                                <i className="fas fa-user-plus"/>
                                <p>Add New Profile</p>
                            </div>
                        </div>
                        {profilesInfo.profiles.length > 0 ?
                            profilesInfo.profiles.map((profile, index) =>
                                <CSSTransition
                                    key={index}
                                    unmountOnExit
                                    timeout={1000}
                                    classNames="my-node"
                                >
                                    <div key={index}
                                         className="filtr-item my-card"
                                         data-groups={
                                             '[\"all\",\"'
                                             + profile.gender + '\",\"'
                                             + profile.orientation + '\",\"'
                                             + profile.type + '\",\"'
                                             + (profile.premium ? "prem" : "") + '\"]'
                                         }>
                                        <div className="imgBx"
                                             style={{cursor: "pointer"}}
                                             onClick={(event) => redirectOnProfilePage(event, profile._id)}
                                        >
                                            {profile.photos.length !== 0 ?
                                                <ImgPreloader
                                                    src={
                                                        profile.photos.find(el => {
                                                            return el.mainPhoto === true
                                                        }).imgLink
                                                    }
                                                    alt={
                                                        profile.photos.find(el => {
                                                            return el.mainPhoto === true
                                                        }).imgAlt
                                                    }
                                                />
                                                :
                                                <ImgPreloader
                                                    src={"/assets/img/no-photo.svg"}
                                                    alt={"no-photo"}
                                                />}
                                            <ul className="my-action">
                                                <li className="see-always-icon"
                                                    onClick={() => redirectOnEditProfilePage(profile._id)}>
                                                    <i className="fas fa-user-edit"/>
                                                    <span>Edit profile</span>
                                                </li>
                                                <li className="see-always-icon" onClick={() => {
                                                    setTargetPremium(profile.premium)
                                                    setCurrentProfileNickName(profile.nickName)
                                                    setCurrentProfileId(profile._id)
                                                    handleDeleteShow()
                                                }}>
                                                    <i className="fas fa-trash-alt"/>
                                                    <span>Delete Profile</span>
                                                </li>
                                            </ul>
                                            {profile.premium === true ?
                                                <ul className="verify_main">
                                                    <li className={"verify_wrapper"}
                                                        data-toggle={"tooltip"}
                                                        data-bs-original-title={"Trusted"}
                                                    >
                                                        <img src={"/assets/img/svg/ver1.svg"} alt={"verified"}/>
                                                    </li>
                                                </ul> :
                                                <div/>
                                            }
                                        </div>
                                        <div className="my-content">
                                            <div className="my-product-name" style={{display: "none"}}>
                                                <h3>{profile.nickName}</h3>
                                            </div>
                                            <div className="mb-0 mt-0"
                                                 style={{
                                                     gridTemplateColumns: "2fr 12fr 2fr",
                                                     display: "grid",
                                                     gridTemplateAreas: `'mock nickName copyIcon'`,
                                                     wordWrap: "break-word",
                                                     wordBreak: "break-all"
                                                 }}>
                                                <div id="onlineIndicator"
                                                     style={{
                                                         gridArea: "mock",
                                                         alignSelf: "center",
                                                         justifySelf: "start",
                                                         fontSize: "10px",
                                                         paddingLeft: "4px",
                                                     }}
                                                >
                                                    <div className={"indicatorContainer"}>
                                                        <div data-toggle="tooltip"
                                                             title={profile.isOnline ? "Online" : "Offline"}
                                                             className={profile.isOnline ? "online-indicator" : "online-indicator-offline"}>
                                                            <span className={profile.isOnline ? "blink" : "noblink"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3 className="h5 mb-0"
                                                    style={{
                                                        gridArea: "nickName",
                                                        alignSelf: "center",
                                                        justifySelf: "center",
                                                        textAlign: "center",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={(event) => redirectOnProfilePage(event, profile._id)}
                                                >
                                                    {profile.nickName}
                                                </h3>
                                            </div>
                                            <div className="app">
                                        <span className="fas fa-mobile-alt">
						                    <h6 className={'pl-1'}>{profile.appName}</h6>
				                        </span>
                                            </div>
                                            <div className="my-rating">
                                                <div data-toggle="tooltip" className="rating" title="HOT points">
                                                    <i className="fire-color fas fa-fire"/>
                                                    <span>{profile.hotPoints}</span>
                                                </div>
                                                <div data-toggle="tooltip" className="rating" title="">
                                                    <i className="eye-color fas fa-eye"/> <span>{profile.views}</span>
                                                </div>
                                                <div data-toggle="tooltip" className="rating" title="">
                                                    <i className="heart-beat fas fa-heart"/>
                                                    <span>{profile.likes}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>
                            )
                            :
                            (loading ?
                                    <div style={{marginTop: "-30px"}}><Loader/></div> :
                                    <div/>
                            )
                        }
                    </TransitionGroup>
                </div>
            </div>
            <Modal show={showDeleteModal} onHide={handleDeleteClose}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to delete
                        your profile {currentProfileNickName}?</Modal.Title>
                    <button type="button" className="btn-close btn-close-grey"
                            aria-label="Close" onClick={handleDeleteClose}>
                        <i className="fas fa-times"/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {targetPremium ?
                        <h4>Deleting your profile is <b style={{color: 'red'}}>permanent</b> and will remove all content
                            including
                            photos, avatars and all profiles information.
                            <b style={{color: 'red'}}> Also your PREMIUM status will be lost. </b>
                            Are you sure you want to delete your profile?
                        </h4>
                        :
                        <h4>Deleting your profile is <b style={{color: 'red'}}>permanent</b> and will remove all content
                            including
                            photos, avatars and all profiles information. Are you sure you want to delete your profile?
                        </h4>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button id="delete_account" type="button" className="btn btn-primary"
                            onClick={() => {
                                deleteProfile(currentProfileId);
                                handleDeleteClose();
                            }
                            }>Delete
                    </button>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}