import React, {useEffect, useState} from "react";

export const MainGridMockProfiles = (
    {
        profilesInfo
    }) => {

    const [profileMock, setProfileMock] = useState([]);

    useEffect(() => {
        if(profilesInfo.length < 4 && profilesInfo.length !== 0){
            let tempArr = [];
            for(let i = 0; i < 4-profilesInfo.length;i++){
                tempArr[i] = profilesInfo[0];
            }
            setProfileMock(tempArr);
        }else{
            setProfileMock([])
        }
    }, [profilesInfo])

    useEffect(() => {
    }, [profileMock])

    return (profileMock.map((profile, index) => <div key={index}/>))
}