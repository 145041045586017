import React, {useEffect, useState} from 'react'
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";
import FileUploadComponent from "../components/FileUploadComponent";
import FormData from "form-data";


export const ContactUsPage = (props) => {

    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()

    const [filesData, setFilesData] = useState([])
    const [formData, setFormData] = useState({})
    const [subject, setSubject] = useState("")

    const subjectMap = {
        'remove_username': 'I cannot remember my username/password. Can you delete my username from this site?'
    }


    const uploadScreenshots =  async (file) => {
        const formDataForUploadFile = new FormData();

        formDataForUploadFile.append(
            "file",
            file
        );

        const data = await request(
            '/api/file/uploadIntoFs',
            'POST',
            formDataForUploadFile,
            {},
            true
        );

        return data
    }

    const sendContactUsTicket = async () => {

        let screenshotData = []
        for(let data of filesData){
            let response =  await uploadScreenshots(data.file)
            screenshotData.push({filename: response.imgName})
        }

        let requestBody = {...formData, ...{screenshots_list: screenshotData}}

        try {
            const contactUsRequest = await request(
                `/api/mailer/sendContactUsEmail`,
                'POST',
                requestBody
            )
            message(contactUsRequest.message,'success',"/")
        } catch (e) {

        }
    }

    const changeHandlerForFormData = event => {
        setFormData({...formData, [event.target.name]: event.target.value})
    }


    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    useEffect(() => {
        if(props.location.search){
            let subject = getParameterByName('subject', props.location.search)
            if(subject){
                setSubject(subjectMap[subject])
            }
        }
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        message(error,'error')
        clearError();
    },[error,message,clearError])

    return (
        <section className="py-5" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <div className={"card"} style={{
                    padding: "40px", display: "flex", height: "100%", color: "black",
                    backgroundColor: "white"
                }}>
                    <form>
                        <h1>Contact Us</h1>
                        <hr/>
                        <p>Fill out the following form to contact us. We'll be glad to assist you with any
                            inquiries.</p>
                        <div className="form-group">
                            <label>Name</label>
                            <input className="form-control" id="name" type="text" name="name"
                                   onChange={changeHandlerForFormData}/>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input className="form-control" id="email" type="text" name="email"
                                   onChange={changeHandlerForFormData}/>
                        </div>

                        <div className="form-group">
                            <label>Subject</label>
                            <input className="form-control" id="subject" type="text" name="subject" value={subject}
                                   onChange={(event) => {changeHandlerForFormData(event); setSubject(event.target.value);}}/>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="issue" className="form-label">Body</label>
                            <textarea className="form-control" id="body" name={"body"} rows="3"
                                      onChange={changeHandlerForFormData}/>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">If you have some screenshots, please attach them below</label>
                            <FileUploadComponent
                                filesData={filesData}
                                setFilesData={setFilesData}
                                maxFiles={3}
                                buttonText={'Drag \'n\' drop some screenshot here, or click to select files.'}
                                buttonStyle={{margin: "unset", fontSize: "small"}}
                                maxFilesText={'Screenshots slots left '}
                                dropZonesStyle={{
                                    dropzone: {height: 150, overflow: "auto", borderStyle: "dashed"},
                                    preview: {padding: "8px 3%", minHeight: "fit-content"},
                                    previewImage: {maxHeight: "150px"},
                                    dropzoneActive: {borderColor: 'green'},
                                }}
                            />
                        </div>

                        <button type="button" className="btn btn-primary" onClick={sendContactUsTicket} disabled={loading}>
                            {loading ?
                                <div className="spinner-grow spinner-grow-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <div>
                                    Send
                                </div>
                            }
                        </button>
                    </form>
                </div>
            </div>
        </section>
    )
}