import React, {useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook";
import {useMessage} from "../../hooks/message.hook";



export const AccountForgotPassword =  (props) => {
    const message = useMessage()
    const {loading, error, clearError, request} = useHttp()

    useEffect(() => {
        message(error,'error')
        clearError();
    },[error,message,clearError])


    const [emailForm, setEmailForm] = useState({
        email: '',
    })

    const changeHandlerForEmail = event => {
        setEmailForm({...emailForm, [event.target.name]: event.target.value})
    }

    const recoveryPasswordEmail = async (event) => {
        if(event) {
            event.preventDefault()
        }
            try {
                const data = await request(
                    `/api/auth/forgot-password`,
                    'POST',
                    {...emailForm},
                )
                message(data.message,'success', "/")
            } catch (e) {
            }
    }

    return (
        <section className="py-0" style={{minHeight: '100%', minWidth: '100%'}}>
            <div className="container">
                <section className="clean-block clean-form dark h-100">
                    <div className="container">
                        <div className="block-heading" style={{paddingTop: '0px'}}>
                            <h2 className="text-primary" style={{marginTop: '40px'}}>Forgot your password?<br/></h2>
                        </div>
                        <form>
                            <p>We'll send you an email with a link where you will be able to change your password.</p>
                            <div className="form__group field">
                                <input className="form__field" type="email" autoComplete="off" required
                                       placeholder="Email" id={'email'} name="email" onChange={changeHandlerForEmail}/>
                                <label htmlFor="email" className="form__label">Email</label>
                            </div>
                            <hr style={{marginTop: '30px', marginBottom: '10px'}}/>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" onClick={(event) => {recoveryPasswordEmail(event)}} disabled={loading}>
                                    {loading ?
                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <div>
                                            SEND
                                        </div>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </section>
    )
}